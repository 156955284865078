import { createContext, useContext, useState, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { flatten } from 'flat';

import { huLocale } from 'moment/locale/hu';
import { enLocale } from 'moment/locale/en-gb';

import Hungarian from '../../assets/locale/hu.json';
import English from '../../assets/locale/en.json';

const LocaleContext = createContext();

export const useLocale = () => useContext(LocaleContext);

const locales = {
  hu: {
    messages: flatten(Hungarian),
    dates: huLocale,
  },
  en: {
    messages: flatten(English),
    dates: enLocale,
  },
};

const LocaleProvider = ({ children }) => {
  const [locale, setLocale] = useState('hu');

  const changeLocale = newlocale => {
    if (Object.keys(locales).includes(newlocale)) {
      setLocale(newlocale);
    }
  };

  const contextValue = useMemo(() => ({ locale, changeLocale }), [locale]);

  return (
    <LocaleContext.Provider value={contextValue}>
      <IntlProvider locale={locale} messages={locales[locale].messages}>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locales[locale].dates}>
          {children}
        </LocalizationProvider>
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

export default LocaleProvider;
