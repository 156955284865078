import { FormattedMessage } from 'react-intl';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';

import './style.scss';

const RadioButtonGroup = ({ value, onChange, labelTranslationKey, name, radioItems }) => (
  <FormControl className="radio-group-container">
    <FormLabel>
      <FormattedMessage id={labelTranslationKey} />
    </FormLabel>
    <RadioGroup name={name} value={value} onChange={onChange} className="radio-group">
      {radioItems.map(radioButton => (
        <FormControlLabel
          key={radioButton.value}
          value={radioButton.value}
          control={<Radio />}
          label={radioButton.label}
        />
      ))}
    </RadioGroup>
  </FormControl>
);

export default RadioButtonGroup;
