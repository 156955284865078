import { useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  Settings as SettingsIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Refresh as RefreshIcon,
} from '@mui/icons-material';

import { infrastructureElementResource } from '../../../rest';

import Notification from '../../../components/Notification';
import PageContainer from '../../../components/PageContainer';
import PageHeader from '../../../components/PageHeader';
import Table from '../../../components/Table';
import Modal from '../../../components/Modal';

const headCells = [
  {
    id: 'name',
    translationKey: 'infrastructure-element.list.name',
    sorting: true,
  },
  {
    id: 'elementType',
    translationKey: 'infrastructure-element.list.element-type',
    sorting: true,
  },
  {
    id: 'gpsCoordinate',
    translationKey: 'infrastructure-element.list.gps-coordinate',
  },
  {
    id: 'locations',
    translationKey: 'infrastructure-element.list.locations',
    sorting: true,
  },
];

const getLocations = async id => {
  try {
    const locations = await infrastructureElementResource.getLocationsForInfrastructureElement(id);
    return locations;
  } catch (error) {
    return [];
  }
};

const mapInfrastructureElementsToTableRows = async infrastructureElements => {
  const mappedInfrastructureElements = await Promise.all(
    infrastructureElements.map(async infrastructureElement => {
      const locations = await getLocations(infrastructureElement.infrastructureElementId);

      return {
        id: infrastructureElement.infrastructureElementId,
        name: infrastructureElement.name,
        elementType: infrastructureElement.elementType.name,
        gpsCoordinate: `${infrastructureElement.latitude}, ${infrastructureElement.longitude}`,
        locations: locations.map(location => location.name).join(', '),
      };
    })
  );

  return mappedInfrastructureElements;
};

const fetchInfrastructureElements = async () => {
  try {
    const infrastructureElements = await infrastructureElementResource.getInfrastructureElements();
    const mappedInfrastructureElements = await mapInfrastructureElementsToTableRows(
      infrastructureElements
    );

    return mappedInfrastructureElements;
  } catch (error) {
    throw Error('Failed to get infrastructure elements!');
  }
};

const InfrastructureElementList = () => {
  const loaderData = useLoaderData();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const [infrastructureElements, setInfrastructureElements] = useState(loaderData);
  const [filteredInfrastructureElements, setFilteredInfrastructureElements] =
    useState(infrastructureElements);
  const [searchValue, setSearchValue] = useState('');
  const [notification, setNotification] = useState({});

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedInfrastructureElementId, setSelectedInfrastructureElementId] = useState(null);

  const handleSearchValueChange = event => setSearchValue(event.target.value);

  const handleSearch = () => {
    const searchTerm = searchValue.toLowerCase();
    const filteredElements = infrastructureElements.filter(
      infrastructureElement =>
        infrastructureElement.name.toLowerCase().includes(searchTerm) ||
        infrastructureElement.elementType.toLowerCase().includes(searchTerm) ||
        infrastructureElement.locations.toLowerCase().includes(searchTerm)
    );

    setFilteredInfrastructureElements(filteredElements);
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleRefresh = async () => {
    try {
      const fetchedInfrastructureElements = await fetchInfrastructureElements();

      setSearchValue('');
      setInfrastructureElements(fetchedInfrastructureElements);
      setFilteredInfrastructureElements(fetchedInfrastructureElements);

      setNotification({
        open: true,
        severity: 'success',
        message: formatMessage({ id: 'infrastructure-element.list.refresh.success' }),
      });
    } catch (error) {
      setNotification({
        open: true,
        severity: 'error',
        message: formatMessage({ id: 'infrastructure-element.list.refresh.error' }),
      });
    }
  };

  const handleDeleteClicked = ({ id }) => {
    setIsDeleteModalOpen(true);
    setSelectedInfrastructureElementId(id);
  };

  const handleDeleteModalClose = () => {
    setSelectedInfrastructureElementId(null);
    setIsDeleteModalOpen(false);
  };

  const handleDeleteModalSubmit = async () => {
    try {
      await infrastructureElementResource.deleteInfrastructureElement(
        selectedInfrastructureElementId
      );
      const updatedInfrastructureElements = infrastructureElements.filter(
        infrastructureElement => infrastructureElement.id !== selectedInfrastructureElementId
      );
      const updatedFilteredInfrastructureElements = filteredInfrastructureElements.filter(
        infrastructureElement => infrastructureElement.id !== selectedInfrastructureElementId
      );

      setInfrastructureElements(updatedInfrastructureElements);
      setFilteredInfrastructureElements(updatedFilteredInfrastructureElements);

      setNotification({
        open: true,
        severity: 'success',
        message: formatMessage({ id: 'infrastructure-element.list.delete.success' }),
      });
    } catch (error) {
      setNotification({
        open: true,
        severity: 'error',
        message: formatMessage({ id: 'infrastructure-element.list.delete.error' }),
      });
    } finally {
      handleDeleteModalClose();
    }
  };

  const handleGoToCreatePage = () => navigate('/infrastructure-element/create');

  const handleGoToEditPage = ({ id }) => navigate(`/infrastructure-element/edit/${id}/element`);

  const tableRowActions = [
    {
      icon: <SettingsIcon />,
      onClick: handleGoToEditPage,
      label: formatMessage({ id: 'common.button.settings' }),
    },
    {
      icon: <DeleteIcon />,
      onClick: handleDeleteClicked,
      label: formatMessage({ id: 'common.button.delete' }),
    },
  ];

  const headerActions = [
    {
      isIconButton: true,
      icon: <RefreshIcon />,
      label: formatMessage({ id: 'common.button.refresh' }),
      onClick: handleRefresh,
    },
    {
      icon: <AddIcon />,
      label: formatMessage({ id: 'common.button.create' }),
      onClick: handleGoToCreatePage,
    },
  ];

  const searchBar = {
    inputProps: {
      name: 'infrastructure-element-search',
      label: formatMessage({ id: 'common.button.search' }),
      value: searchValue,
      onChange: handleSearchValueChange,
      onKeyDown: handleKeyPress,
    },
    adornmentProps: {
      onClick: handleSearch,
    },
  };

  return (
    <>
      <PageContainer>
        <PageHeader
          title={formatMessage({ id: 'infrastructure-element.list.title' })}
          searchBar={searchBar}
          actions={headerActions}
        />

        <Table
          rows={filteredInfrastructureElements}
          headCells={headCells}
          actions={tableRowActions}
          noResultMessage={formatMessage({
            id: `infrastructure-element.list.${
              infrastructureElements.length ? 'no-result' : 'empty'
            }`,
          })}
        />
      </PageContainer>

      <Modal
        open={isDeleteModalOpen}
        message={formatMessage({ id: 'infrastructure-element.list.delete.title' })}
        handleSubmit={handleDeleteModalSubmit}
        handleClose={handleDeleteModalClose}
      />
      <Notification
        open={notification.open}
        severity={notification.severity}
        message={notification.message}
        onClose={() => setNotification(prev => ({ ...prev, open: false }))}
      />
    </>
  );
};

export const infrastructureElementsLoader = async () => {
  try {
    const fetchedInfrastructureElements = await fetchInfrastructureElements();

    return fetchedInfrastructureElements;
  } catch (error) {
    throw Error('Failed to get infrastructure elements!');
  }
};

export default InfrastructureElementList;
