import { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Check as CheckIcon } from '@mui/icons-material';
import log from 'loglevel';

import gatewayResource from '../../../../rest/resources/gatewayResource';

import { useSensor } from '../../../../context/SensorContext';

import PageSection from '../../../../components/PageSection';
import Table from '../../../../components/Table';
import Notification from '../../../../components/Notification';
import SelectInput from '../../../../components/SelectInput';

const LOGGER = log.getLogger('GatewaySampling');

const GatewaySampling = () => {
  const { id: currentGatewayId } = useParams();
  const { formatMessage, locale } = useIntl();
  const { sensors } = useSensor();

  const [notification, setNotification] = useState({});
  const [samplingRates, setSamplingRates] = useState({});
  const [inputError, setInputError] = useState({});

  const assembleMenuItems = availableSamplingRates => {
    const items = availableSamplingRates.map(freq => ({
      value: freq,
      label: formatMessage({ id: `device.management.sampling.frequencies.${freq}` }),
    }));

    items.unshift({
      label: formatMessage({ id: 'common.placeholder.not-selected' }),
      value: '',
      className: 'placeholder-menu-item',
      disabled: true,
    });

    return items;
  };

  const handleSamplingRateChange = (event, sensor) => {
    setSamplingRates(prev => ({
      ...prev,
      [sensor.name]: event.target.value,
    }));
  };

  const getSamplingRateInput = sensor => (
    <SelectInput
      value={samplingRates[sensor.name] || ''}
      menuItems={assembleMenuItems(sensor.availableSamplingRates)}
      onChange={event => handleSamplingRateChange(event, sensor)}
      error={inputError[sensor.name]}
    />
  );

  const mapSensorsToTableRows = elements =>
    elements.map((sensor, index) => ({
      sensorName: sensor.name,
      samplingRate: getSamplingRateInput(sensor),
      index,
    }));

  const sensorsWithSamplingRate = useMemo(
    () => mapSensorsToTableRows(sensors),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sensors, samplingRates, locale, inputError]
  );

  const headCells = [
    {
      id: 'sensorName',
      translationKey: 'gateway.management.sampling.sensor',
    },
    {
      id: 'samplingRate',
      translationKey: 'gateway.management.sampling.frequency',
    },
  ];

  const handleUpdateSamplingRate = async ({ sensorName }) => {
    if (!samplingRates[sensorName]) {
      setInputError({ ...inputError, [sensorName]: true });
      setNotification({
        open: true,
        severity: 'error',
        message: formatMessage({ id: 'common.required-field' }),
      });
      return;
    }

    try {
      await gatewayResource.setGatewaySensorSamplingRate(currentGatewayId, {
        sensor: sensorName,
        samplingRate: samplingRates[sensorName],
      });

      setInputError({ ...inputError, [sensorName]: false });

      setNotification({
        open: true,
        severity: 'success',
        message: formatMessage({ id: 'gateway.management.sampling.save.success' }),
      });
    } catch (error) {
      LOGGER.error('Failed to save sampling frequency!', error);
      setNotification({
        open: true,
        severity: 'error',
        message: formatMessage({ id: 'gateway.management.sampling.save.error' }),
      });
    }
  };

  const tableRowActions = [
    {
      icon: <CheckIcon />,
      onClick: handleUpdateSamplingRate,
      label: formatMessage({ id: 'common.button.save' }),
    },
  ];

  return (
    <>
      <PageSection>
        <Table
          disablePagination
          rows={sensorsWithSamplingRate}
          headCells={headCells}
          actions={tableRowActions}
          defaultSort={{ orderBy: 'index' }}
        />
      </PageSection>

      <Notification
        open={notification.open}
        severity={notification.severity}
        message={notification.message}
        onClose={() => setNotification(prev => ({ ...prev, open: false }))}
      />
    </>
  );
};

export default GatewaySampling;
