import { useIntl } from 'react-intl';
import { DatePicker } from '@mui/x-date-pickers';
import classNames from 'classnames';

const DatePickerInput = ({ labelKey, value, error, onChange }) => {
  const { formatMessage } = useIntl();

  return (
    <DatePicker
      disableFuture
      label={formatMessage({ id: labelKey })}
      value={value}
      format="YYYY/MM/DD"
      slotProps={{
        actionBar: {
          actions: ['clear'],
        },
        textField: {
          readOnly: true,
        },
      }}
      localeText={{ clearButtonLabel: formatMessage({ id: 'common.button.clear' }) }}
      onChange={onChange}
      className={classNames({ error })}
    />
  );
};

export default DatePickerInput;
