export const toHexString = serial => {
  const hex = parseInt(serial, 10).toString(16);
  const full = '00000000'.substring(0, 8 - hex.length) + hex;

  return full
    .match(/[0-9a-fA-F]{2}/g)
    .reverse()
    .join(' ');
};

const HexSerial = ({ serial }) => toHexString(serial);

export default HexSerial;
