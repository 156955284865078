import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import './style.scss';

const DeviceMode = ({ mode }) => (
  <span className="operation-mode">
    <span className={classnames('dot', [mode.toLowerCase()])}></span>
    <FormattedMessage id={`device.list.mode.${mode.toLowerCase()}`} />
  </span>
);

export default DeviceMode;
