import { useIntl } from 'react-intl';
import { useLoaderData, useLocation } from 'react-router-dom';

import { gatewayResource } from '../../../rest';

import PageContainer from '../../../components/PageContainer';
import PageHeader from '../../../components/PageHeader';
import TabLayout from '../../../components/Tab/Layout';
import GatewayName from '../GatewayName';

const GatewayManagement = () => {
  const { formatMessage } = useIntl();
  const gateway = useLoaderData();
  const location = useLocation();

  const tabs = [
    {
      id: 'info',
      label: formatMessage({ id: 'gateway.management.tabs.info' }),
      path: 'info',
    },
    {
      id: 'devices',
      label: formatMessage({ id: 'gateway.management.tabs.devices' }),
      path: 'devices',
    },
    {
      id: 'sampling',
      label: formatMessage({ id: 'gateway.management.tabs.sampling' }),
      path: 'sampling',
    },
  ];

  return (
    <PageContainer>
      <PageHeader
        title={<GatewayName name={gateway.name} serialNumber={gateway.serial} formatted />}
        backButton={{ route: '/gateway' }}
      />
      <TabLayout tabs={tabs} currentTabId={location.pathname.split('/').pop()} />
    </PageContainer>
  );
};

export const gatewayLoader = async ({ params: { id } }) => {
  try {
    const gateway = await gatewayResource.getGatewayById(id);
    return gateway;
  } catch (error) {
    throw Error('Failed to get gateway!');
  }
};

export default GatewayManagement;
