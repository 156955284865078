import { api } from '../rest';

const getInfrastructureElements = async () => {
  const { data } = await api.get('/infrastructure-element');
  return data;
};

const deleteInfrastructureElement = id => api.delete(`/infrastructure-element/${id}`);

const createInfrastructureElement = name => api.post('/infrastructure-element', name);

const getInfrastructureElementById = async id => {
  const { data } = await api.get(`/infrastructure-element/${id}`);
  return data;
};

const updateInfrastructureElement = (id, name) => api.put(`/infrastructure-element/${id}`, name);

const getLocationsForInfrastructureElement = async id => {
  const { data } = await api.get(`/infrastructure-element/${id}/locations`);
  return data;
};

const deleteLocationFromInfrastructureElement = (infrastructureElementId, locationId) =>
  api.delete(`/infrastructure-element/${infrastructureElementId}/locations/${locationId}`);

const createLocationForInfrastructureElement = async (id, name) => {
  const { data } = await api.post(`/infrastructure-element/${id}/locations`, name);
  return data;
};

const updateLocationOfInfrastructureElement = async (infrastructureElementId, locationId, name) => {
  const { data } = await api.put(
    `/infrastructure-element/${infrastructureElementId}/locations/${locationId}`,
    name
  );
  return data;
};

export default {
  getInfrastructureElements,
  deleteInfrastructureElement,
  createInfrastructureElement,
  getInfrastructureElementById,
  updateInfrastructureElement,
  getLocationsForInfrastructureElement,
  deleteLocationFromInfrastructureElement,
  createLocationForInfrastructureElement,
  updateLocationOfInfrastructureElement,
};
