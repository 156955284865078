import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLoaderData } from 'react-router-dom';
import {
  Refresh as RefreshIcon,
  GraphicEq as GraphicEqIcon,
  Download as DownloadIcon,
} from '@mui/icons-material';
import log from 'loglevel';

import Table from '../../components/Table';
import PageContainer from '../../components/PageContainer';
import PageHeader from '../../components/PageHeader';
import Notification from '../../components/Notification';
import alertResource from '../../rest/resources/alertResource';
import EdaGenerationModal from '../DataStore/EdaGenerationModal';
import DeviceName from '../Device/DeviceName';
import GatewayName from '../Gateway/GatewayName';
import { toHexString } from '../../components/HexSerial';

const LOGGER = log.getLogger('AlertList');

const headCells = [
  {
    id: 'alertTime',
    translationKey: 'alert.list.alertTime',
    sorting: true,
    type: 'date-time',
  },
  {
    id: 'gateway',
    translationKey: 'alert.list.gateway',
    sorting: true,
  },
  {
    id: 'device',
    translationKey: 'alert.list.device',
    sorting: true,
  },
  {
    id: 'sensor',
    translationKey: 'alert.list.sensor',
    sorting: true,
  },
  {
    id: 'field',
    translationKey: 'alert.list.field',
    sorting: true,
  },
  {
    id: 'value',
    translationKey: 'alert.list.value',
    sorting: true,
  },
  {
    id: 'measurementStatus',
    translationKey: 'alert.list.measurement-status',
    sorting: true,
  },
  {
    id: 'infrastructureElement',
    translationKey: 'alert.list.infrastructure-element',
    sorting: true,
  },
  {
    id: 'location',
    translationKey: 'alert.list.location',
    sorting: true,
  },
];

const AlertList = () => {
  const loaderData = useLoaderData();
  const { formatMessage, locale } = useIntl();

  const _mapAlertsToAlertTableRows = alerts =>
    alerts.map(alert => ({
      id: alert.id,
      alertTime: alert.alertTime,
      field: alert.field,
      value: alert.value,
      measurement: alert.measurement,
      measurementStatus: formatMessage({
        id: `data-store.list.status.${alert.measurement.status.toLowerCase()}`,
      }),
      sensor: alert.sensor,
      device: (
        <DeviceName
          name={alert.measurement.device.name}
          serialNumber={alert.measurement.device.serialNumber}
          formatted
        />
      ),
      deviceName:
        alert.measurement.device.name || formatMessage({ id: 'data-store.list.unnamed-device' }),
      deviceSerial: toHexString(alert.measurement.device.serialNumber),
      gateway: (
        <GatewayName
          name={alert.measurement.gateway.name}
          serialNumber={alert.measurement.gateway.serial}
          formatted
        />
      ),
      gatewayName:
        alert.measurement.gateway.name || formatMessage({ id: 'data-store.list.unnamed-gateway' }),
      gatewaySerial: toHexString(alert.measurement.gateway.serial),
      location: alert.measurement.location?.name || '',
      infrastructureElement: alert.measurement.infrastructureElement?.name || '',
    }));

  const [alerts, setAlerts] = useState(_mapAlertsToAlertTableRows(loaderData));
  const [filteredAlerts, setFilteredAlerts] = useState(alerts);
  const [notification, setNotification] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [EDAGenerationModal, setEDAGenerationModal] = useState({ open: false });

  const handleSearch = translatedAlerts => {
    const searchTerm = searchValue.toLowerCase();
    const filteredResults = translatedAlerts.filter(
      alert =>
        alert.field.toLowerCase().includes(searchTerm) ||
        alert.measurementStatus.toLowerCase().includes(searchTerm) ||
        alert.sensor.toLowerCase().includes(searchTerm) ||
        alert.deviceName.toLowerCase().includes(searchTerm) ||
        alert.deviceSerial.includes(searchTerm) ||
        alert.gatewayName.toLowerCase().includes(searchTerm) ||
        alert.gatewaySerial.includes(searchTerm) ||
        alert.location.toLowerCase().includes(searchTerm) ||
        alert.infrastructureElement.toLowerCase().includes(searchTerm)
    );

    setFilteredAlerts(filteredResults);
  };

  useEffect(() => {
    const translatedAlerts = alerts.map(alert => ({
      ...alert,
      measurementStatus: formatMessage({
        id: `data-store.list.status.${alert.measurement.status.toLowerCase()}`,
      }),
      deviceName:
        alert.measurement.device?.name || formatMessage({ id: 'data-store.list.unnamed-device' }),
      gatewayName:
        alert.measurement.gateway?.name || formatMessage({ id: 'data-store.list.unnamed-gateway' }),
    }));

    setAlerts(translatedAlerts);
    handleSearch(translatedAlerts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  const handleSearchValueChange = event => setSearchValue(event.target.value);

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleSearch(alerts);
    }
  };

  const searchBar = {
    inputProps: {
      name: 'alert-search',
      label: formatMessage({ id: 'common.button.search' }),
      value: searchValue,
      onChange: handleSearchValueChange,
      onKeyDown: handleKeyPress,
    },
    adornmentProps: {
      onClick: handleSearch,
    },
  };

  const handleRefresh = async () => {
    try {
      const fetchedAlerts = await alertResource.listAlerts();

      setSearchValue('');
      const mappedAlerts = _mapAlertsToAlertTableRows(fetchedAlerts.elements);
      setAlerts(mappedAlerts);
      setFilteredAlerts(mappedAlerts);

      setNotification({
        open: true,
        severity: 'success',
        message: formatMessage({ id: 'alert.list.refresh.success' }),
      });
    } catch (error) {
      LOGGER.error('Failed to refresh alerts!', error);
      setNotification({
        open: true,
        severity: 'error',
        message: formatMessage({ id: 'alert.list.refresh.error' }),
      });
    }
  };

  const handleEDAGenerationClicked = alert => {
    setEDAGenerationModal({
      open: true,
      selectedMeasurement: alert.measurement,
    });
  };

  const handleEdaGenerationCancel = () => {
    setEDAGenerationModal({
      open: false,
      selectedMeasurement: null,
    });
  };

  const handleEdaGenerationError = () => {
    setNotification({
      open: true,
      severity: 'error',
      message: formatMessage({ id: 'data-store.list.eda-generation.error' }),
    });
  };

  const handleMeasurementDownload = row => {
    window.open(
      `/api/measurement/${row.measurement.measurementId}/download`,
      '_blank',
      'noopener noreferrer'
    );
  };

  const headerActions = [
    {
      isIconButton: true,
      icon: <RefreshIcon />,
      label: formatMessage({ id: 'common.button.refresh' }),
      onClick: handleRefresh,
    },
  ];

  const tableRowActions = [
    {
      icon: <GraphicEqIcon />,
      onClick: handleEDAGenerationClicked,
      label: formatMessage({ id: 'common.button.eda-generation' }),
      disabled: row => row.measurement.status !== 'DONE',
    },
    {
      icon: <DownloadIcon />,
      onClick: handleMeasurementDownload,
      label: formatMessage({ id: 'common.button.download' }),
      disabled: row => row.measurement.status !== 'DONE',
    },
  ];

  return (
    <>
      <PageContainer>
        <PageHeader
          title={formatMessage({ id: 'alert.list.title' })}
          searchBar={searchBar}
          actions={headerActions}
        />

        <Table
          rows={filteredAlerts}
          headCells={headCells}
          actions={tableRowActions}
          defaultSort={{ order: 'desc' }}
          noResultMessage={formatMessage({
            id: `alert.list.${filteredAlerts.length ? 'no-result' : 'empty'}`,
          })}
        />
      </PageContainer>

      {EDAGenerationModal.open && (
        <EdaGenerationModal
          measurement={EDAGenerationModal.selectedMeasurement}
          onCancel={handleEdaGenerationCancel}
          onError={handleEdaGenerationError}
        />
      )}

      <Notification
        open={notification.open}
        severity={notification.severity}
        message={notification.message}
        onClose={() => setNotification(prev => ({ ...prev, open: false }))}
      />
    </>
  );
};

export const alertsLoader = async () => {
  try {
    const fetchedAlerts = await alertResource.listAlerts();
    return fetchedAlerts.elements;
  } catch (error) {
    LOGGER.error('Failed to get alerts!', error);
    throw Error('Failed to get alerts!');
  }
};

export default AlertList;
