import { createContext, useContext, useState, useMemo } from 'react';
import SockJsClient from 'react-stomp';
import log from 'loglevel';

const DeviceStatusContext = createContext();

export const useDeviceStatus = () => useContext(DeviceStatusContext);

const LOGGER = log.getLogger('DeviceStatusProvider');

const extractScheduledState = (scheduled, operationMode) => {
  if (operationMode.startsWith('SCHEDULED_')) {
    return operationMode;
  }

  return scheduled ? 'SCHEDULED' : 'NOT_SCHEDULED';
};

const DeviceStatusProvider = ({ device, children }) => {
  const [subscriptions, setSubscriptions] = useState([`/topic/${device.deviceId}/state`]);
  const [isOffline, setIsOffline] = useState(!device.online);
  const [isScheduled, setIsScheduled] = useState(device.scheduled);
  const [operationMode, setOperationMode] = useState(device.operationMode);
  const [streamingSensors, setStreamingSensors] = useState(device.streamingSensors || []);
  const [messageHandlers, setMessageHandlers] = useState({});

  const defaultMessageHandlers = {
    GATEWAY_ONLINE_STATUS: msg => {
      setIsOffline(!msg.isOnline);
    },
    SCHEDULING_STATUS: msg => {
      setIsScheduled(msg.scheduled);
    },
    STATE_CHANGE: msg => {
      setOperationMode(msg.operationMode);
      setStreamingSensors(msg.sensors);
    },
  };

  const addSubscription = topic => {
    setSubscriptions(prev => {
      if (!prev.includes(topic)) {
        return [...prev, topic];
      }
      return prev;
    });
  };

  const removeSubscription = topic => {
    setSubscriptions(prev => prev.filter(item => item !== topic));
  };

  const addMessageHandlers = handlers => {
    setMessageHandlers(prev => {
      const updatedHandlers = { ...prev };
      handlers.forEach(({ type, callback }) => {
        updatedHandlers[type] = callback;
      });
      return updatedHandlers;
    });
  };

  const removeMessageHandlers = types => {
    setMessageHandlers(prev => {
      const updatedHandlers = { ...prev };
      types.forEach(type => {
        delete updatedHandlers[type];
      });
      return updatedHandlers;
    });
  };

  const handleConnected = () => {};

  const handleDisconnected = () => {};

  const handleMessageReceived = msg => {
    const currentHandlers = [defaultMessageHandlers[msg.type], messageHandlers[msg.type]].filter(
      Boolean
    );

    if (!currentHandlers.length) {
      LOGGER.error('Unhandled message type: ', msg.type);
      return;
    }

    currentHandlers.forEach(callback => callback(msg));
  };

  const contextValue = useMemo(
    () => ({
      addSubscription,
      removeSubscription,
      addMessageHandlers,
      removeMessageHandlers,
      isOffline,
      operationMode,
      streamingSensors,
      scheduledState: extractScheduledState(isScheduled, operationMode),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOffline, isScheduled, operationMode, streamingSensors]
  );

  return (
    <DeviceStatusContext.Provider value={contextValue}>
      {children}
      <SockJsClient
        url="/api/ws"
        topics={subscriptions}
        onConnect={handleConnected}
        onDisconnect={handleDisconnected}
        onMessage={handleMessageReceived}
      />
    </DeviceStatusContext.Provider>
  );
};

export default DeviceStatusProvider;
