import { Box } from '@mui/material';
import { useDeviceStatus } from './DeviceStatusContext';
import DeviceMode from '../List/DeviceMode';

const DeviceStatusInfo = () => {
  const { isOffline, operationMode } = useDeviceStatus();

  return (
    <Box className="device-status-info">
      <DeviceMode mode={isOffline ? 'offline' : 'online'} />
      <DeviceMode mode={operationMode.toLowerCase()} />
    </Box>
  );
};

export default DeviceStatusInfo;
