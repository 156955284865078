import { useEffect, useRef, useState } from 'react';
import { Form, useActionData, useRouteLoaderData } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { Avatar, Box, Button, Divider } from '@mui/material';
import { PersonOutline as ProfilePictureIcon } from '@mui/icons-material';

import PageContainer from '../../components/PageContainer';
import PageHeader from '../../components/PageHeader';
import PageSection from '../../components/PageSection';
import Input from '../../components/Input';
import InputPassword from '../../components/InputPassword';
import InputErrorMessage from '../../components/InputErrorMessage';
import Notification from '../../components/Notification';
import SubmitButton from '../../components/SubmitButton';

import { authenticationResource } from '../../rest';
import { convertFileToBase64 } from '../../utils/convertFileToBase64';

import './style.scss';

const Profile = () => {
  const { formatMessage } = useIntl();

  const user = useRouteLoaderData('private-root');
  const data = useActionData();

  const changePasswordFormRef = useRef(null);

  const profilePictureInputRef = useRef(null);

  const [profilePicture, setProfilePicture] = useState(user.profilePicture);
  const [profilePictureError, setProfilePictureError] = useState({});
  const [notification, setNotification] = useState({});

  useEffect(() => {
    if (data) {
      let severity;
      let message;

      if (data.updateProfileAction?.ok) {
        severity = 'success';
        message = formatMessage({ id: 'profile.details.success' });
        changePasswordFormRef.current.reset();
      } else if (data.updateProfileAction?.error?.server) {
        message = formatMessage({ id: 'profile.details.error.update-fail' });
        severity = 'error';
      } else if (data.changePasswordAction?.ok) {
        severity = 'success';
        message = formatMessage({ id: 'profile.change-password.success' });
        changePasswordFormRef.current.reset();
      } else if (data.changePasswordAction?.error?.oldPasswordConflict) {
        severity = 'error';
        message = formatMessage({ id: 'profile.change-password.error.invalid-old-password' });
      } else if (data.changePasswordAction?.error?.server) {
        severity = 'error';
        message = formatMessage({ id: 'profile.change-password.error.password-change-fail' });
      } else {
        // Important, because there can be error, when we do not want to display notification (required field for example)
        return;
      }

      setNotification({
        open: true,
        severity,
        message,
        onClose: () => {
          setNotification(prev => ({ ...prev, open: false }));
          // Need to delete, because if it set, the notification will display in case of language change
          delete data.updateProfileAction;
          delete data.changePasswordAction;
        },
      });
    }
  }, [data, formatMessage]);

  const handleProfilePictureChange = async event => {
    const file = event.target.files[0];
    setProfilePictureError({});

    if (file.type.split('/')[0] !== 'image') {
      setProfilePictureError({ format: true });
      return;
    }

    // Size limit: 1 MB
    if (file.size > 1048576) {
      setProfilePictureError({ size: true });
      return;
    }

    const base64 = await convertFileToBase64(file);
    setProfilePicture(base64);
  };

  const handleDeleteProfilePicture = () => {
    profilePictureInputRef.current.value = '';
    setProfilePicture(null);
  };

  return (
    <>
      <PageContainer>
        <PageHeader title={formatMessage({ id: 'profile.title' })} />

        {/* Update Profile Details */}
        <PageSection title={formatMessage({ id: 'profile.details.title' })}>
          <Form className="profile-details-form" method="put" action="/profile" noValidate>
            <Box className="profile-details-form-container">
              <Box className="profile-picture-container">
                <Avatar className="profile-picture" src={profilePicture}>
                  <ProfilePictureIcon className="profile-picture-fallback" />
                </Avatar>

                <Box className="profile-picture-error-container">
                  {profilePictureError.format && (
                    <InputErrorMessage
                      message={formatMessage({
                        id: 'profile.details.error.invalid-format',
                      })}
                    />
                  )}
                  {profilePictureError.size && (
                    <InputErrorMessage
                      message={formatMessage({
                        id: 'profile.details.error.invalid-size',
                      })}
                    />
                  )}
                </Box>

                <Box className="profile-picture-button-container">
                  <Button className="profile-picture-button" component="label" variant="contained">
                    <FormattedMessage id="common.button.upload" />
                    <input
                      ref={profilePictureInputRef}
                      hidden
                      type="file"
                      accept="image/*"
                      onChange={handleProfilePictureChange}
                    />
                    {profilePicture && (
                      <input
                        name="profilePicture"
                        hidden
                        type="text"
                        defaultValue={profilePicture}
                      />
                    )}
                  </Button>

                  <Button
                    className="profile-picture-button"
                    type="button"
                    variant="outlined"
                    onClick={handleDeleteProfilePicture}
                    disabled={!profilePicture}
                  >
                    <FormattedMessage id="common.button.delete" />
                  </Button>
                </Box>
              </Box>

              <Divider className="divider" flexItem orientation="vertical" />

              <Box className="profile-details-container">
                {/* Name */}
                <Input
                  label={formatMessage({ id: 'profile.details.name' })}
                  name="name"
                  defaultValue={user.name}
                  required
                  error={data?.updateProfileForm?.error?.name?.required}
                />
                {data?.updateProfileForm?.error?.name?.required && (
                  <InputErrorMessage message={formatMessage({ id: 'common.required-field' })} />
                )}

                {/* Email */}
                <Input
                  label={formatMessage({ id: 'profile.details.email' })}
                  name="email"
                  value={user.email}
                  disabled
                />

                {/* Hidden input for action type */}
                <input type="hidden" name="action-type" value="update-profile" />
              </Box>
            </Box>

            <SubmitButton />
          </Form>
        </PageSection>

        {/* Change Password */}
        <PageSection title={formatMessage({ id: 'profile.change-password.title' })}>
          <Form
            className="password-change-form"
            method="put"
            action="/profile"
            ref={changePasswordFormRef}
            noValidate
          >
            {/* Old Password */}
            <InputPassword
              label={formatMessage({ id: 'profile.change-password.old-password' })}
              name="old-password"
              autoComplete="current-password"
              required
              error={data?.changePasswordForm?.error?.oldPassword?.required}
              tooltip={formatMessage({ id: 'common.show-password' })}
              inputProps={{ tabIndex: 1 }}
            />
            {data?.changePasswordForm?.error?.oldPassword?.required && (
              <InputErrorMessage message={formatMessage({ id: 'common.required-field' })} />
            )}

            {/* New Password */}
            <InputPassword
              label={formatMessage({ id: 'profile.change-password.new-password' })}
              name="new-password"
              autoComplete="new-password"
              required
              error={
                data?.changePasswordForm?.error?.newPassword?.required ||
                data?.changePasswordForm?.error?.newPassword?.invalid
              }
              tooltip={formatMessage({ id: 'common.show-password' })}
              inputProps={{ tabIndex: 2 }}
            />
            {data?.changePasswordForm?.error?.newPassword?.required && (
              <InputErrorMessage message={formatMessage({ id: 'common.required-field' })} />
            )}
            {data?.changePasswordForm?.error?.newPassword?.invalid && (
              <InputErrorMessage
                message={formatMessage({
                  id: 'profile.change-password.error.password-requirements',
                })}
              />
            )}

            {/* Confirm New Password */}
            <InputPassword
              label={formatMessage({ id: 'profile.change-password.confirm-password' })}
              name="new-password-confirmation"
              required
              error={
                data?.changePasswordForm?.error?.newPasswordConfirmation?.required ||
                data?.changePasswordForm?.error?.newPasswordConfirmation?.notMatch
              }
              tooltip={formatMessage({ id: 'common.show-password' })}
              inputProps={{ tabIndex: 3 }}
            />
            {data?.changePasswordForm?.error?.newPasswordConfirmation?.required && (
              <InputErrorMessage message={formatMessage({ id: 'common.required-field' })} />
            )}
            {data?.changePasswordForm?.error?.newPasswordConfirmation?.notMatch && (
              <InputErrorMessage
                message={formatMessage({ id: 'profile.change-password.error.passwords-not-match' })}
              />
            )}

            {/* Hidden input for action type */}
            <input type="hidden" name="action-type" value="change-password" />

            <SubmitButton />
          </Form>
        </PageSection>
      </PageContainer>

      <Notification
        open={notification.open}
        severity={notification.severity}
        message={notification.message}
        onClose={notification.onClose}
      />
    </>
  );
};

const updateProfile = async data => {
  const submission = {
    name: data.get('name'),
    profilePicture: data.get('profilePicture'),
  };

  if (!submission.name) {
    return {
      updateProfileForm: {
        error: {
          name: {
            required: !submission.name,
          },
        },
      },
    };
  }

  try {
    await authenticationResource.updateUser({
      name: submission.name,
      profilePicture: submission.profilePicture,
    });

    return {
      updateProfileAction: { ok: true },
    };
  } catch (error) {
    return { updateProfileAction: { error: { server: true } } };
  }
};

const changePassword = async data => {
  const submission = {
    oldPassword: data.get('old-password'),
    newPassword: data.get('new-password'),
    newPasswordConfirmation: data.get('new-password-confirmation'),
  };

  if (
    !submission.oldPassword ||
    !submission.newPassword ||
    !submission.newPasswordConfirmation ||
    submission.newPassword.length < 8 ||
    submission.newPassword !== submission.newPasswordConfirmation
  ) {
    return {
      changePasswordForm: {
        error: {
          oldPassword: {
            required: !submission.oldPassword,
          },
          newPassword: {
            required: !submission.newPassword,
            invalid: !!submission.newPassword && submission.newPassword.length < 8,
          },
          newPasswordConfirmation: {
            required: !submission.newPasswordConfirmation,
            notMatch:
              !!submission.newPasswordConfirmation &&
              submission.newPassword !== submission.newPasswordConfirmation,
          },
        },
      },
    };
  }

  try {
    await authenticationResource.changePassword({
      oldPassword: submission.oldPassword,
      newPassword: submission.newPassword,
    });

    return {
      changePasswordAction: { ok: true },
    };
  } catch (error) {
    const response = { changePasswordAction: { error: {} } };

    if (error.response.status === 400) {
      response.changePasswordAction.error.oldPasswordConflict = true;
    } else {
      response.changePasswordAction.error.server = true;
    }

    return response;
  }
};

export const updateProfileAndChangePasswordAction = async ({ request }) => {
  const data = await request.formData();

  const actionType = data.get('action-type');

  if (actionType === 'update-profile') {
    return updateProfile(data);
  }

  if (actionType === 'change-password') {
    return changePassword(data);
  }

  return null;
};

export default Profile;
