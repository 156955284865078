import { useState } from 'react';
import { Navigate, useLoaderData, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  Avatar,
  Box,
  Button,
  Container,
  InputAdornment,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { LockOutlined, Visibility, VisibilityOff } from '@mui/icons-material';

import InputErrorMessage from '../../components/InputErrorMessage';

import { authenticationResource } from '../../rest';

import './style.scss';

const Login = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const user = useLoaderData();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState({ error: false });

  const handleShowPassword = () => setShowPassword(!showPassword);

  const handleChangeEmail = event => setEmail(event.target.value);

  const handleChangePassword = event => setPassword(event.target.value);

  const handleLogin = async event => {
    event.preventDefault();
    setLoginError({ error: false });

    try {
      await authenticationResource.login(email, password);
      navigate('/', { replace: true });
    } catch (error) {
      setLoginError({
        error: true,
        status: error?.response?.status === 401 ? 'bad-credentials' : 'internal-server-error',
      });
    }
  };

  if (user) {
    return <Navigate to="/" />;
  }

  return (
    <Container component="div" maxWidth="xs" className="login">
      <Box className="header">
        <Avatar className="icon">
          <LockOutlined />
        </Avatar>

        <Typography component="h1" variant="h4" className="title">
          {formatMessage({ id: 'login.title' })}
        </Typography>
      </Box>

      <Box component="form" onSubmit={handleLogin} noValidate className="login-form">
        <TextField
          margin="normal"
          fullWidth
          id="email"
          name="email"
          label={formatMessage({ id: 'login.form.email' })}
          type="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={handleChangeEmail}
          error={loginError.error}
        />
        <TextField
          margin="normal"
          fullWidth
          id="password"
          name="password"
          label={formatMessage({ id: 'login.form.password' })}
          type={showPassword ? 'text' : 'password'}
          autoComplete="current-password"
          value={password}
          onChange={handleChangePassword}
          error={loginError.error}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title={formatMessage({ id: 'common.show-password' })}>
                  <IconButton
                    edge="end"
                    onClick={handleShowPassword}
                    onMouseDown={event => event.preventDefault()}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />

        {loginError.error && (
          <InputErrorMessage
            classes={['login-error']}
            message={formatMessage({ id: `login.error.${loginError.status}` })}
          />
        )}

        <Button fullWidth type="submit" variant="contained" className="submit-btn">
          {formatMessage({ id: 'login.form.submit' })}
        </Button>
      </Box>
    </Container>
  );
};

export default Login;
