import { Box, Button, IconButton, Paper, Tooltip, Typography } from '@mui/material';

import './style.scss';

const PageSection = ({ title, actions, children }) => (
  <Paper className="page-section">
    <Box className="page-section-header">
      {title && (
        <Typography className="page-section-title" variant="h6">
          {title}
        </Typography>
      )}

      {actions && (
        <Box className="actions-container">
          {actions.map((action, index) => {
            const { isIconButton, label, icon, onClick, ...remaining } = action;

            return isIconButton ? (
              <Tooltip key={index} title={label}>
                {/* MUI: You are providing a disabled `button` child to the Tooltip component. A
                  disabled element does not fire events. Tooltip needs to listen to the child
                  element's events to display the title. Add a simple wrapper element, such as a
                  `span`. */}
                <span>
                  <IconButton onClick={onClick} {...remaining}>
                    {icon}
                  </IconButton>
                </span>
              </Tooltip>
            ) : (
              <Button
                key={index}
                variant="contained"
                type="button"
                startIcon={icon}
                onClick={onClick}
                {...remaining}
              >
                {label}
              </Button>
            );
          })}
        </Box>
      )}
    </Box>

    {children}
  </Paper>
);

export default PageSection;
