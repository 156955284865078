import { api } from '../rest';

const listEDAs = async () => {
  const { data } = await api.get('/eda');
  return data;
};

const getEDAById = async id => {
  const { data } = await api.get(`/eda/${id}`);
  return data;
};

const generateEDA = async generateEDAData => {
  const { data } = await api.post('/eda/generate', generateEDAData);
  return data;
};

export default {
  listEDAs,
  getEDAById,
  generateEDA,
};
