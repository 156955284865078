import { useState, useMemo } from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Settings as SettingsIcon } from '@mui/icons-material';

import { gatewayResource } from '../../../../rest';

import { toHexString } from '../../../../components/HexSerial';

import Table from '../../../../components/Table';
import Notification from '../../../../components/Notification';
import PageSection from '../../../../components/PageSection';
import DeviceName from '../../../Device/DeviceName';

const headCells = [
  {
    id: 'displayedName',
    translationKey: 'gateway.management.devices.name',
    sorting: true,
  },
  {
    id: 'serialNumber',
    translationKey: 'gateway.management.devices.serial-number',
    sorting: true,
  },
  {
    id: 'infrastructureElementName',
    translationKey: 'gateway.management.devices.infrastructure-element',
    sorting: true,
  },
  {
    id: 'locationName',
    translationKey: 'gateway.management.devices.location',
    sorting: true,
  },
];

const GatewayDevices = () => {
  const loaderData = useLoaderData();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { id: currentGatewayId } = useParams();

  const [notification, setNotification] = useState({});

  const devices = useMemo(
    () =>
      loaderData.map(device => ({
        id: device.deviceId,
        name: device.name,
        displayedName: <DeviceName name={device.name} formatted />,
        serialNumber: toHexString(device.gateway.serial),
        gatewaySerialNumber: device.gatewaySerialNumber,
        locationName: device.location?.name,
        infrastructureElementName: device.infrastructureElement?.name,
      })),
    [loaderData]
  );

  const handleGoToEditPage = ({ id }) =>
    navigate(`/device-edit/${id}/info`, {
      state: {
        navigateBackTo: `/gateway/edit/${currentGatewayId}/devices`,
      },
    });

  const tableRowActions = [
    {
      icon: <SettingsIcon />,
      onClick: handleGoToEditPage,
      label: formatMessage({ id: 'common.button.settings' }),
    },
  ];

  return (
    <>
      <PageSection>
        <Table
          rows={devices}
          headCells={headCells}
          actions={tableRowActions}
          noResultMessage={formatMessage({ id: 'gateway.management.devices.empty' })}
        />
      </PageSection>

      <Notification
        open={notification.open}
        severity={notification.severity}
        message={notification.message}
        onClose={() => setNotification(prev => ({ ...prev, open: false }))}
      />
    </>
  );
};

export const gatewayDeviceLoader = async ({ params: { id } }) => {
  try {
    const devices = await gatewayResource.getDevicesByGateway(id);
    return devices;
  } catch (error) {
    throw Error('Failed to get devices!');
  }
};

export default GatewayDevices;
