import { FormattedMessage } from 'react-intl';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import classNames from 'classnames';

import './style.scss';

const Modal = ({
  open,
  title,
  message,
  children,
  optionalActionButton,
  closeLabelKey,
  submitLabelKey,
  handleSubmit,
  handleClose,
  className,
  submitButtonDisabled,
  noCancelButton,
}) => (
  <Dialog open={open} onClose={handleClose} className={classNames('modal', className)}>
    {title && <DialogTitle>{title}</DialogTitle>}
    {message && <DialogContent>{message}</DialogContent>}
    {children && <DialogContent>{children}</DialogContent>}
    <DialogActions className="modal-actions-container">
      {optionalActionButton && (
        <Button onClick={optionalActionButton.onClick}>
          <FormattedMessage id={optionalActionButton.label} />
        </Button>
      )}
      <Box>
        {!noCancelButton && (
          <Button onClick={handleClose}>
            <FormattedMessage id={closeLabelKey || 'common.button.no'} />
          </Button>
        )}
        <Button onClick={handleSubmit} disabled={submitButtonDisabled}>
          <FormattedMessage id={submitLabelKey || 'common.button.yes'} />
        </Button>
      </Box>
    </DialogActions>
  </Dialog>
);

export default Modal;
