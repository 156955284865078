import { useState } from 'react';
import { useLoaderData, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  Settings as SettingsIcon,
  Delete as DeleteIcon,
  AddCircleOutline as AddCircleOutlineIcon,
} from '@mui/icons-material';

import { infrastructureElementResource } from '../../../../rest';

import PageSection from '../../../../components/PageSection';
import Notification from '../../../../components/Notification';
import Table from '../../../../components/Table';
import Modal from '../../../../components/Modal';

const headCells = [
  {
    id: 'name',
    translationKey: 'infrastructure-element.management.location.name',
    sorting: true,
  },
];

const mapLocationsToTableRows = locations =>
  locations.map(location => ({
    id: location.locationId,
    name: location.name,
  }));

const InfrastructureElementLocation = () => {
  const { id: currentInfrastructureElementId } = useParams();
  const loaderData = useLoaderData();
  const { formatMessage } = useIntl();

  const [locations, setLocations] = useState(mapLocationsToTableRows(loaderData || []));

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedLocationId, setSelectedLocationId] = useState(null);

  const [notification, setNotification] = useState({});

  const handleDeleteClicked = ({ id }) => {
    setIsDeleteModalOpen(true);
    setSelectedLocationId(id);
  };

  const handleDeleteModalClose = () => {
    setSelectedLocationId(null);
    setIsDeleteModalOpen(false);
  };

  const handleDeleteModalSubmit = async () => {
    try {
      await infrastructureElementResource.deleteLocationFromInfrastructureElement(
        currentInfrastructureElementId,
        selectedLocationId
      );
      const updatedInfrastructureElements = locations.filter(
        location => location.id !== selectedLocationId
      );

      setLocations(updatedInfrastructureElements);

      setNotification({
        open: true,
        severity: 'success',
        message: formatMessage({ id: 'infrastructure-element.management.location.delete.success' }),
      });
    } catch (error) {
      setNotification({
        open: true,
        severity: 'error',
        message: formatMessage({
          id: 'infrastructure-element.management.location.delete.error',
        }),
      });
    } finally {
      handleDeleteModalClose();
    }
  };

  const handleAddSubmit = async row => {
    if (!row.name) {
      const error = new Error();

      Object.assign(error, {
        inputError: {
          name: true,
        },
      });

      setNotification({
        open: true,
        severity: 'error',
        message: formatMessage({ id: 'common.required-field' }),
      });

      throw error;
    }

    try {
      const newLocation =
        await infrastructureElementResource.createLocationForInfrastructureElement(
          currentInfrastructureElementId,
          {
            name: row.name,
          }
        );

      setLocations([...locations, { id: newLocation.locationId, name: newLocation.name }]);

      setNotification({
        open: true,
        severity: 'success',
        message: formatMessage({ id: 'infrastructure-element.management.location.add.success' }),
      });
    } catch (error) {
      setNotification({
        open: true,
        severity: 'error',
        message: formatMessage({
          id: 'infrastructure-element.management.location.add.error',
        }),
      });
    }
  };

  const handleAddCancel = () => {
    const updatedLocations = locations.filter(location => location.id);
    setLocations(updatedLocations);
  };

  const handleAddRow = () =>
    setLocations([
      ...locations,
      { name: '', isEdit: true, onConfirm: handleAddSubmit, onCancel: handleAddCancel },
    ]);

  const updateLocationProperties = (id, newValues) => {
    const newLocations = locations.map(location => {
      if (location.id === id) {
        return {
          ...location,
          ...newValues,
        };
      }

      return location;
    });

    setLocations(newLocations);
  };

  const handleEditSubmit = async row => {
    if (!row.name) {
      const error = new Error();

      Object.assign(error, {
        inputError: {
          name: true,
        },
      });

      setNotification({
        open: true,
        severity: 'error',
        message: formatMessage({ id: 'common.required-field' }),
      });

      throw error;
    }

    try {
      const updatedLocation =
        await infrastructureElementResource.updateLocationOfInfrastructureElement(
          currentInfrastructureElementId,
          row.id,
          { name: row.name }
        );

      updateLocationProperties(updatedLocation.locationId, {
        name: updatedLocation.name,
        isEdit: false,
      });

      setNotification({
        open: true,
        severity: 'success',
        message: formatMessage({ id: 'infrastructure-element.management.location.edit.success' }),
      });
    } catch (error) {
      setNotification({
        open: true,
        severity: 'error',
        message: formatMessage({
          id: 'infrastructure-element.management.location.edit.error',
        }),
      });
    }
  };

  const handleEditCancel = id => updateLocationProperties(id, { isEdit: false });

  const handleEditRow = ({ id }) =>
    updateLocationProperties(id, {
      isEdit: true,
      onConfirm: handleEditSubmit,
      onCancel: handleEditCancel,
    });

  const tableRowActions = [
    {
      icon: <SettingsIcon />,
      onClick: handleEditRow,
      label: formatMessage({ id: 'common.button.settings' }),
    },
    {
      icon: <DeleteIcon />,
      onClick: handleDeleteClicked,
      label: formatMessage({ id: 'common.button.delete' }),
    },
  ];

  const headActions = [
    {
      icon: <AddCircleOutlineIcon />,
      label: formatMessage({ id: 'common.button.add' }),
      className: 'add-button',
      onClick: handleAddRow,
    },
  ];

  return (
    <>
      <PageSection>
        <Table
          rows={locations}
          headCells={headCells}
          actions={tableRowActions}
          headActions={headActions}
          noResultMessage={formatMessage({
            id: 'infrastructure-element.management.location.empty',
          })}
        />
      </PageSection>

      <Notification
        open={notification.open}
        severity={notification.severity}
        message={notification.message}
        onClose={() => setNotification(prev => ({ ...prev, open: false }))}
      />
      <Modal
        open={isDeleteModalOpen}
        message={formatMessage({ id: 'infrastructure-element.management.location.delete.title' })}
        handleSubmit={handleDeleteModalSubmit}
        handleClose={handleDeleteModalClose}
      />
    </>
  );
};

export const locationsLoader = async ({ params }) => {
  const { id } = params;

  try {
    const locations = await infrastructureElementResource.getLocationsForInfrastructureElement(id);
    return locations;
  } catch (error) {
    throw Error('Failed to get element type!');
  }
};

export default InfrastructureElementLocation;
