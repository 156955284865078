import { useState, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Form, useActionData, useRouteLoaderData } from 'react-router-dom';
import { Edit, Undo } from '@mui/icons-material';

import Input from '../../../../components/Input';
import PageSection from '../../../../components/PageSection';
import Notification from '../../../../components/Notification';
import SubmitButton from '../../../../components/SubmitButton';

import { deviceResource } from '../../../../rest';

import './style.scss';

const parseCalibration = calibration => ({
  transpositions: {
    IIS2DH: {
      x: calibration.sensorTranspositions?.IIS2DH?.x || '',
      y: calibration.sensorTranspositions?.IIS2DH?.y || '',
      z: calibration.sensorTranspositions?.IIS2DH?.z || '',
    },
    IIS3DWB: {
      x: calibration.sensorTranspositions?.IIS3DWB?.x || '',
      y: calibration.sensorTranspositions?.IIS3DWB?.y || '',
      z: calibration.sensorTranspositions?.IIS3DWB?.z || '',
    },
  },
});

const DeviceCalibration = () => {
  const device = useRouteLoaderData('device-root');
  const { locale, formatMessage } = useIntl();

  const actionData = useActionData();

  const [calibration, setCalibration] = useState(parseCalibration(device.calibration));
  const [transpositionsCalculated, setTranspositionsCalculated] = useState(
    device.calibration.transpositionsCalculated
  );
  const [transpositionsLocked, setTranspositionsLocked] = useState(
    device.calibration.transpositionsCalculated
  );

  const [notification, setNotification] = useState({});

  const handleUpdateCalibration = ({ target: { value } }, type, sensor, field) => {
    setCalibration(prev => ({
      ...prev,
      [type]: {
        ...prev[type],
        [sensor]: {
          ...prev[type][sensor],
          [field]: value,
        },
      },
    }));
  };

  const handleRevertChange = () => {
    setCalibration(parseCalibration(device.calibration));
    setTranspositionsLocked(true);
  };

  useEffect(() => {
    if (actionData) {
      let severity;
      let message;

      if (actionData.updateCalibrationAction?.ok) {
        severity = 'success';
        message = formatMessage({ id: 'device.management.calibration.update.success' });
        setTranspositionsCalculated(false);
      } else if (actionData.updateCalibrationAction?.error?.server) {
        message = formatMessage({ id: 'device.management.calibration.update.error' });
        severity = 'error';
      } else {
        return;
      }

      setNotification({
        open: true,
        severity,
        message,
        onClose: () => {
          setNotification(prev => ({ ...prev, open: false }));
          // Need to delete, because if it set, the notification will display in case of language change
          delete actionData.updateCalibrationAction;
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionData, locale]);

  const transpositionActions = useMemo(
    () => {
      if (transpositionsCalculated && transpositionsLocked) {
        return [
          { icon: <Edit />, isIconButton: true, onClick: () => setTranspositionsLocked(false) },
        ];
      }

      if (transpositionsCalculated && !transpositionsLocked) {
        return [{ icon: <Undo />, isIconButton: true, onClick: handleRevertChange }];
      }

      return [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transpositionsCalculated, transpositionsLocked]
  );

  return (
    <>
      {Object.entries(calibration).map(([type, values]) => (
        <PageSection
          title={formatMessage({ id: `device.management.calibration.${type}.title` })}
          actions={transpositionActions}
        >
          <Form method="put" action={`/device-edit/${device.deviceId}/calibration`} noValidate>
            {Object.entries(values).map(([sensor, sensorFields]) => (
              <div key={sensor} className="sensor">
                <span className="sensor-name">{sensor}</span>
                <div className="sensor-fields">
                  {Object.keys(sensorFields).map(field => (
                    <Input
                      key={field}
                      type="number"
                      name={`${sensor}.${field}`}
                      label={field}
                      disabled={transpositionsLocked}
                      value={calibration.transpositions[sensor][field]}
                      onChange={event => handleUpdateCalibration(event, type, sensor, field)}
                    />
                  ))}
                </div>
              </div>
            ))}
            {!transpositionsLocked && <SubmitButton />}
          </Form>
        </PageSection>
      ))}

      <Notification
        open={notification.open}
        severity={notification.severity}
        message={notification.message}
        onClose={() => setNotification(prev => ({ ...prev, open: false }))}
      />
    </>
  );
};

export const updateCalibrationAction = async ({ request, params }) => {
  const { id } = params;
  const data = await request.formData();

  const calibration = {
    sensorTranspositions: {
      IIS2DH: {
        x: data.get('IIS2DH.x'),
        y: data.get('IIS2DH.y'),
        z: data.get('IIS2DH.z'),
      },
      IIS3DWB: {
        x: data.get('IIS3DWB.x'),
        y: data.get('IIS3DWB.y'),
        z: data.get('IIS3DWB.z'),
      },
    },
  };

  try {
    await deviceResource.setDeviceSensorTranspositions(id, calibration);

    return { updateCalibrationAction: { ok: true } };
  } catch (error) {
    return {
      updateCalibrationAction: {
        error: {
          server: true,
        },
      },
    };
  }
};

export default DeviceCalibration;
