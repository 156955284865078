import { FormattedMessage } from 'react-intl';
import { Box, Button } from '@mui/material';

import './style.scss';

const SubmitButton = ({ onClick, ...remaining }) => (
  <Box className="form-submit-container">
    <Button
      type={onClick ? 'button' : 'submit'}
      variant="contained"
      className="submit-button"
      onClick={onClick}
      {...remaining}
    >
      <FormattedMessage id="common.button.save" />
    </Button>
  </Box>
);

export default SubmitButton;
