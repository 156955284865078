import { useEffect, useState } from 'react';
import { Form, redirect, useActionData, useLoaderData } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { elementTypeResource } from '../../../rest';

import Notification from '../../../components/Notification';
import PageContainer from '../../../components/PageContainer';
import PageHeader from '../../../components/PageHeader';
import PageSection from '../../../components/PageSection';
import InputErrorMessage from '../../../components/InputErrorMessage';
import Input from '../../../components/Input';
import SubmitButton from '../../../components/SubmitButton';

const ElementTypeManagement = () => {
  const elementType = useLoaderData();
  const actionData = useActionData();
  const { formatMessage } = useIntl();

  const [serverError, setServerError] = useState();

  useEffect(() => {
    if (actionData?.serverError) {
      setServerError(actionData.serverError);
    }
  }, [actionData]);

  return (
    <>
      <PageContainer>
        <PageHeader
          backButton
          title={
            elementType?.name ||
            formatMessage({
              id: 'element-type.management.create',
            })
          }
        />

        <PageSection>
          <Form
            noValidate
            method={elementType ? 'put' : 'post'}
            action={
              elementType
                ? `/element-type/edit/${elementType.elementTypeId}`
                : '/element-type/create'
            }
            className="element-type-management-form"
          >
            <Input
              required
              name="element-type-name"
              type="text"
              label={formatMessage({ id: 'element-type.management.name' })}
              defaultValue={elementType?.name || ''}
              error={actionData?.nameError}
            />
            {actionData?.nameError && (
              <InputErrorMessage message={formatMessage({ id: 'common.required-field' })} />
            )}

            <SubmitButton />
          </Form>
        </PageSection>
      </PageContainer>

      <Notification
        open={serverError}
        severity="error"
        message={formatMessage({
          id: `element-type.management.error.${elementType ? 'edit' : 'create'}-fail`,
        })}
        onClose={() => setServerError(false)}
      />
    </>
  );
};

export const elementTypeLoader = async ({ params }) => {
  const { id } = params;

  try {
    const elementType = await elementTypeResource.getElementTypeById(id);
    return elementType;
  } catch (error) {
    throw Error('Failed to get element type!');
  }
};

export const elementTypeManagementAction = async ({ params, request }) => {
  const { id } = params;
  const data = await request.formData();

  const elementTypeData = {
    name: data.get('element-type-name'),
  };

  if (!elementTypeData.name) {
    return { nameError: true };
  }

  const promise = id
    ? elementTypeResource.updateElementType(id, elementTypeData)
    : elementTypeResource.createElementType(elementTypeData);

  try {
    await promise;
  } catch (error) {
    return { serverError: true };
  }

  return redirect('/element-type');
};

export default ElementTypeManagement;
