import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';

const MultiSelectInput = ({
  name,
  label,
  value,
  onChange,
  renderValue,
  menuItems,
  required,
  error,
  disabled,
  className,
}) => (
  <FormControl
    className={`multiselect-container ${className}`}
    fullWidth
    required={required}
    error={error}
  >
    <InputLabel>{label}</InputLabel>
    <Select
      name={name}
      className="multiselect"
      fullWidth
      multiple
      input={<OutlinedInput label={label} />}
      value={value}
      onChange={onChange}
      renderValue={renderValue}
      disabled={disabled}
    >
      {menuItems.map(({ label: menuItemLabel, checked, ...remaining }) => (
        <MenuItem key={menuItemLabel} {...remaining}>
          <Checkbox checked={checked} />
          <ListItemText primary={menuItemLabel} />
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default MultiSelectInput;
