import { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { edaResource } from '../../../rest';

import { useSensor } from '../../../context/SensorContext';
import MultiSelectInput from '../../../components/MultiSelectInput';
import Modal from '../../../components/Modal';

const EdaGenerationModal = ({ measurement, onCancel, onError }) => {
  const { formatMessage } = useIntl();
  const { sensors } = useSensor();

  const [selectedMethods, setSelectedMethods] = useState({});
  const [generatedEDAId, setGeneratedEDAId] = useState();

  const availableSensors = useMemo(
    () => sensors.filter(sensor => measurement.sensors.includes(sensor.name)),
    [measurement, sensors]
  );

  const analysisMethodsOrder = useMemo(
    () =>
      availableSensors.reduce((sensorResult, sensor) => {
        sensorResult[sensor.name] = sensor.availableEDATypes.reduce(
          (methodResult, method, index) => {
            methodResult[method] = index;
            return methodResult;
          },
          {}
        );
        return sensorResult;
      }, {}),
    [availableSensors]
  );

  const handleEdaSettingsChange = (sensor, { target: { value } }) => {
    setSelectedMethods({ ...selectedMethods, [sensor.name]: value });
  };

  const handleRenderSelectedEdaSettingsValue = selected =>
    selected
      .map(selectedMethod =>
        formatMessage({
          id: `data-store.list.eda-generation.${selectedMethod}`,
        })
      )
      .join(', ');

  const handleEdaGenerationSubmit = async () => {
    if (!availableSensors.length) {
      onCancel();
      return;
    }

    const mappedMethods = Object.entries(selectedMethods).reduce((result, [sensor, methods]) => {
      result[sensor] = [...methods].sort(
        (method1, method2) =>
          analysisMethodsOrder[sensor][method1] - analysisMethodsOrder[sensor][method2]
      );
      return result;
    }, {});

    const edaGenerationData = {
      measurementId: measurement.measurementId,
      requests: mappedMethods,
    };

    try {
      const eda = await edaResource.generateEDA(edaGenerationData);
      setGeneratedEDAId(eda.id);
    } catch (error) {
      onError();
    }
  };

  return (
    <Modal
      open
      title={formatMessage({ id: 'data-store.list.eda-generation.title' })}
      submitLabelKey="common.button.ok"
      closeLabelKey="common.button.cancel"
      className="eda-generation-modal"
      handleSubmit={handleEdaGenerationSubmit}
      handleClose={onCancel}
      submitButtonDisabled={
        !!availableSensors.length &&
        (!Object.keys(selectedMethods).length ||
          Object.values(selectedMethods).every(selectedAnalysis => !selectedAnalysis.length))
      }
    >
      {availableSensors.length ? (
        availableSensors.map(sensor => (
          <MultiSelectInput
            key={sensor.name}
            label={sensor.name}
            value={selectedMethods[sensor.name] || []}
            onChange={event => handleEdaSettingsChange(sensor, event)}
            renderValue={handleRenderSelectedEdaSettingsValue}
            menuItems={sensor.availableEDATypes.map(method => ({
              value: method,
              label: formatMessage({ id: `data-store.list.eda-generation.${method}` }),
              checked: !!selectedMethods[sensor.name]?.includes(method),
            }))}
          />
        ))
      ) : (
        <div className="eda-generation-message">
          {formatMessage({ id: 'data-store.list.eda-generation.no-available-sensor' })}
        </div>
      )}

      {generatedEDAId && (
        <div className="eda-generation-message">
          <Link to={`/eda/${generatedEDAId}/view`} className="view-eda-link">
            {formatMessage({ id: 'data-store.list.eda-generation.view-result' })}
          </Link>
        </div>
      )}
    </Modal>
  );
};

export default EdaGenerationModal;
