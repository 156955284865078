import { api } from '../rest';

const login = (email, password) =>
  api.post(
    '/login',
    { email, password },
    { headers: { 'content-type': 'application/x-www-form-urlencoded' } }
  );

const logout = () => api.post('/logout');

const getCurrentUser = async () => {
  const { data } = await api.get('/current-user');
  return data;
};

const updateUser = async user => {
  const { data } = await api.put('/current-user', user);
  return data;
};

const changePassword = async passwords => {
  const { data } = await api.put('/current-user/password', passwords);
  return data;
};

export default { login, logout, getCurrentUser, updateUser, changePassword };
