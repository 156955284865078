import { Box, Typography } from '@mui/material';
import classNames from 'classnames';

import './style.scss';

const InputErrorMessage = ({ classes = [], message }) => (
  <Box className={classNames('input-error-message', ...classes)}>
    <Typography variant="body2">{message}</Typography>
  </Box>
);

export default InputErrorMessage;
