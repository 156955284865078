import { useEffect, useState } from 'react';
import { useActionData, useLoaderData, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { infrastructureElementResource } from '../../../rest';

import Notification from '../../../components/Notification';
import PageContainer from '../../../components/PageContainer';
import PageHeader from '../../../components/PageHeader';
import TabLayout from '../../../components/Tab/Layout';

const InfrastructureElementManagement = () => {
  const infrastructureElement = useLoaderData();
  const actionData = useActionData();
  const { formatMessage } = useIntl();
  const location = useLocation();

  const [serverError, setServerError] = useState();

  useEffect(() => {
    if (actionData?.serverError) {
      setServerError(actionData.serverError);
    }
  }, [actionData]);

  const tabs = [
    {
      id: 'element',
      label: formatMessage({ id: 'infrastructure-element.management.element.title' }),
      path: 'element',
    },
    {
      id: 'location',
      label: formatMessage({ id: 'infrastructure-element.management.location.title' }),
      path: 'location',
    },
  ];

  return (
    <>
      <PageContainer>
        <PageHeader
          backButton={{ route: '/infrastructure-element' }}
          title={
            infrastructureElement?.name ||
            formatMessage({
              id: 'infrastructure-element.management.create',
            })
          }
        />

        <TabLayout tabs={tabs} currentTabId={location.pathname.split('/').pop()} />
      </PageContainer>

      <Notification
        open={serverError}
        severity="error"
        message={formatMessage({
          id: `infrastructure-element.management.error.${
            infrastructureElement ? 'edit' : 'create'
          }-fail`,
        })}
        onClose={() => setServerError(false)}
      />
    </>
  );
};

export const infrastructureElementLoader = async ({ params }) => {
  const { id } = params;

  try {
    const infrastructureElement = await infrastructureElementResource.getInfrastructureElementById(
      id
    );
    return infrastructureElement;
  } catch (error) {
    throw Error('Failed to get infrastructure element!');
  }
};

export default InfrastructureElementManagement;
