import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Box,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import { MobileTimePicker } from '@mui/x-date-pickers';
import { Check as CheckIcon, Close as CancelIcon } from '@mui/icons-material';
import moment from 'moment';
import classNames from 'classnames';

import Input from '../Input';
import SelectInput from '../SelectInput';
import './style.scss';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const rowsPerPageOptions = [15, 30, 50, 75, 100];

const tableRowHeight = 64; // tr height in style.scss: 64px (4rem)

const EnhancedTable = ({
  rows,
  headCells,
  headActions,
  actions,
  noResultMessage,
  defaultSort,
  disablePagination,
}) => {
  const { formatMessage } = useIntl();
  const [order, setOrder] = useState(defaultSort?.order || 'asc');
  const [orderBy, setOrderBy] = useState(defaultSort?.orderBy || headCells[0].id);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    disablePagination ? rows.length : rowsPerPageOptions[0]
  );
  const [inputError, setInputError] = useState({});

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  useEffect(() => {
    setPage(0);
    setRowsPerPage(disablePagination ? rows.length : rowsPerPageOptions[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  const handleSort = (_, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (_, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleConfirm = async row => {
    try {
      await row.onConfirm(row);
      setInputError({});
    } catch (error) {
      setInputError(error.inputError);
    }
  };

  const handleCancel = row => {
    setInputError({});
    row.onCancel();
  };

  const handleActionClick = async (action, row, index) => {
    try {
      await action.onClick(row, index);
      setInputError({});
    } catch (error) {
      setInputError(error.inputError);
    }
  };

  const renderCheckBoxValue = value => (value ? <CheckIcon /> : <CancelIcon />);

  return (
    <Box className="table-wrapper">
      <TableContainer className="table-container">
        <Table size="medium">
          <TableHead>
            <TableRow className="table-header-row">
              {headCells.map(headCell => (
                <TableCell
                  key={headCell.id}
                  align="left"
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.sorting ? (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={event => handleSort(event, headCell.id)}
                    >
                      <FormattedMessage id={headCell.translationKey} />
                    </TableSortLabel>
                  ) : (
                    <FormattedMessage id={headCell.translationKey} />
                  )}
                </TableCell>
              ))}

              {headActions ? (
                <TableCell padding="checkbox" className="head-actions-container">
                  {headActions.map((headAction, headActionsMapIndex) => (
                    <Tooltip key={headActionsMapIndex} title={headAction.label}>
                      <IconButton
                        edge="end"
                        onClick={headAction.onClick}
                        className={classNames('head-action-button', headAction.className)}
                      >
                        {headAction.icon}
                      </IconButton>
                    </Tooltip>
                  ))}
                </TableCell>
              ) : (
                <TableCell padding="checkbox" />
              )}
            </TableRow>
          </TableHead>

          <TableBody className="table-body">
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) =>
                row.isEdit ? (
                  <TableRow key={`editable-table-row-${index}`} className="editable-table-row">
                    {headCells.map(headCell => (
                      <TableCell key={headCell.id} className="table-cell">
                        {headCell.type === 'checkbox' && (
                          <Checkbox
                            defaultChecked={row[headCell.id]}
                            onChange={event => {
                              row[headCell.id] = event.target.checked;
                            }}
                            className="checkbox"
                          />
                        )}
                        {headCell.type === 'time' && (
                          <MobileTimePicker
                            ampm={false}
                            defaultValue={row[headCell.id] ? row[headCell.id] : null}
                            onAccept={value => {
                              row[headCell.id] = value;
                            }}
                            className={classNames('time-picker', {
                              error: inputError[headCell.id],
                            })}
                            slotProps={{ textField: { placeholder: '' } }}
                          />
                        )}
                        {(!headCell.type || headCell.type === 'number') && (
                          <Input
                            size="small"
                            defaultValue={row[headCell.id]}
                            error={inputError[headCell.id]}
                            type={headCell.type || 'text'}
                            onChange={event => {
                              row[headCell.id] = event.target.value;
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                          />
                        )}
                      </TableCell>
                    ))}
                    <TableCell>
                      <Box className="actions-container">
                        <CheckIcon onClick={() => handleConfirm(row)} className="action-icon" />
                        <CancelIcon onClick={() => handleCancel(row)} className="action-icon" />
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow key={`table-row-${index}`} className="table-row">
                    {headCells.map(headCell => (
                      <TableCell key={headCell.id} className={`table-cell ${headCell.id}-cell`}>
                        {headCell.type === 'checkbox' && renderCheckBoxValue(row[headCell.id])}
                        {headCell.type === 'time' && moment(row[headCell.id]).format('HH:mm')}
                        {headCell.type === 'date-time' &&
                          moment(row[headCell.id]).format('YYYY.MM.DD HH:mm')}
                        {headCell.type === 'select' && (
                          <SelectInput
                            name={headCell.id}
                            defaultValue={row[headCell.id]}
                            menuItems={headCell.menuItems}
                            error={inputError[index]}
                            onChange={event => {
                              row[headCell.id] = event.target.value;
                            }}
                          />
                        )}
                        {(!headCell.type || headCell.type === 'number') && row[headCell.id]}
                      </TableCell>
                    ))}

                    <TableCell>
                      <Box className="actions-container">
                        {actions.map((action, actionsMapIndex) => (
                          <Box
                            key={actionsMapIndex}
                            onClick={() => handleActionClick(action, row, index)}
                            className={classNames('action-icon', {
                              disabled: action.disabled?.(row),
                            })}
                          >
                            <Tooltip title={action.label}>{action.icon}</Tooltip>
                          </Box>
                        ))}
                      </Box>
                    </TableCell>
                  </TableRow>
                )
              )}
            {/* Avoid a layout jump when reaching the last page with empty rows. */}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: tableRowHeight * emptyRows,
                }}
              >
                {/* headCells length + action buttons column */}
                <TableCell colSpan={headCells.length + 1} />
              </TableRow>
            )}
            {!rows.length && (
              <TableRow>
                <TableCell colSpan={headCells.length + 1} className="no-result-message">
                  {noResultMessage}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!disablePagination && (
        <TablePagination
          className="table-pagination"
          component="div"
          rowsPerPageOptions={rowsPerPageOptions}
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={formatMessage({ id: 'common.table.rows-per-page' })}
        />
      )}
    </Box>
  );
};

export default EnhancedTable;
