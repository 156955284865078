import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import Chart from 'react-apexcharts';
import log from 'loglevel';

import { axisTextOptions } from '../../utils/apexChartsCommon';

import './style.scss';

// eslint-disable-next-line no-bitwise
const rgbToHex = (r, g, b) => `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;

const colorRanges = (minValue, maxValue) => {
  const range = maxValue - minValue;
  const stepSize = range / 256;

  const colorRange = Array.from({ length: 256 }).map((_, i) => ({
    from: minValue + i * stepSize,
    to: minValue + (i + 1) * stepSize + Number.EPSILON,
    name: i,
    color: rgbToHex(i, i / 3, 255 - i),
  }));

  return colorRange;
};

const LOGGER = log.getLogger('SpectrogramGraph');

const SpectrogramGraph = ({ measurement, sensor, data }) => {
  const { formatMessage, locale } = useIntl();

  const [options, series] = useMemo(
    () => {
      const frequencyLabel = formatMessage({ id: 'eda.view.graph.spectrogram.frequency' });
      const hzLabel = formatMessage({ id: 'eda.view.graph.unit.hz' });
      const timeLabel = formatMessage({ id: 'eda.view.graph.spectrogram.time' });
      const secondsLabel = formatMessage({ id: 'eda.view.graph.unit.seconds' });

      let minValue = Number.MAX_SAFE_INTEGER;
      let maxValue = Number.MIN_SAFE_INTEGER;

      const transposedData = Object.keys(data.spectrogram['0']).map(hz =>
        Object.values(data.spectrogram).map(fft => {
          const cell = fft[hz];

          if (cell < minValue) {
            minValue = cell;
          }
          if (cell > maxValue) {
            maxValue = cell;
          }

          return cell || 0.0;
        })
      );

      return [
        {
          chart: {
            animations: {
              enabled: false,
            },
            toolbar: {
              export: {
                png: {
                  filename: `${measurement.name}_${sensor.name}_spectrogram`,
                },
                jpg: {
                  filename: `${measurement.name}_${sensor.name}_spectrogram`,
                },
                csv: {
                  filename: `${measurement.name}_${sensor.name}_spectrogram`,
                },
              },
            },
          },
          xaxis: {
            type: 'numeric',
            title: {
              text: `${timeLabel} (${secondsLabel})`,
              ...axisTextOptions,
            },
            axisTicks: {
              show: true,
            },
            tickAmount: Math.min(data.spectrogram.length, 10),
            min: 0,
            max: data.spectrogram.length - 1,
          },
          yaxis: {
            type: 'numeric',
            title: {
              text: `${frequencyLabel} (${hzLabel})`,
              ...axisTextOptions,
              offsetX: 10,
            },
            axisTicks: {
              show: true,
            },
            tickAmount: (transposedData.length - 2) / 10,
            min: 1,
            max: transposedData.length - 2,
            labels: {
              enabled: false,
            },
          },
          stroke: {
            width: 1,
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
          },
          plotOptions: {
            heatmap: {
              useFillColorAsStroke: true,
              colorScale: {
                ranges: colorRanges(minValue, maxValue),
              },
            },
          },
        },
        transposedData.map((fft, hz) => ({
          name: hz,
          data: fft.map((magnitude, time) => ({ x: time, y: magnitude.toFixed(2) })),
        })),
      ];
    },
    [locale] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return <Chart height="400" type="heatmap" options={options} series={series} />;
};

export default props => {
  // Early-return had to be separated to avoid breaking the rule of hooks
  if (props.data.spectrogram.length < 1) {
    LOGGER.error('No spectrogram data available!');
    return null;
  }

  return <SpectrogramGraph {...props} />;
};
