import { Box, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import TabPanel from '../TabPanel';

import './style.scss';

const TabLayout = ({ tabs, currentTabId }) => {
  const [currentTab, setCurrentTab] = useState(() => {
    const currentTabIndex = tabs.findIndex(tab => tab.id === currentTabId);
    return currentTabIndex === -1 ? 0 : currentTabIndex;
  });

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <>
      <Box className="tab-layout">
        <Tabs value={currentTab} onChange={handleChange}>
          {tabs.map(tab => (
            <Tab key={tab.id} label={tab.label} component={Link} to={tab.path} className="tab" />
          ))}
        </Tabs>
      </Box>
      {tabs.map((tab, index) => (
        <TabPanel key={tab.id} index={index} currentTab={currentTab}>
          <Outlet />
        </TabPanel>
      ))}
    </>
  );
};

export default TabLayout;
