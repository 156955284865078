import { api } from '../rest';

const listMeasurementsForDataStore = async () => {
  const { data } = await api.get('/measurement');
  return data;
};

const deleteMeasurement = id => api.delete(`/measurement/${id}`);

const createMeasurement = measurement => api.post('/measurement', measurement);

const calibrateDevice = async id => {
  const { data } = await api.put(`/measurement/${id}/calibrate`);
  return data;
};

export default {
  listMeasurementsForDataStore,
  deleteMeasurement,
  createMeasurement,
  calibrateDevice,
};
