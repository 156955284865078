import { useState, useEffect } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Settings as SettingsIcon, Refresh as RefreshIcon } from '@mui/icons-material';

import { deviceResource } from '../../../rest';

import { toHexString } from '../../../components/HexSerial';

import PageContainer from '../../../components/PageContainer';
import PageHeader from '../../../components/PageHeader';
import Table from '../../../components/Table';
import Notification from '../../../components/Notification';
import DeviceName from '../DeviceName';
import DeviceMode from './DeviceMode';

const headCells = [
  {
    id: 'displayedName',
    translationKey: 'device.list.name',
    sorting: true,
  },
  {
    id: 'serialNumber',
    translationKey: 'device.list.serial-number',
    sorting: true,
  },
  {
    id: 'gatewaySerialNumber',
    translationKey: 'device.list.gateway-serial-number',
    sorting: true,
  },
  {
    id: 'infrastructureElementName',
    translationKey: 'device.list.infrastructure-element',
    sorting: true,
  },
  {
    id: 'locationName',
    translationKey: 'device.list.location',
    sorting: true,
  },
  {
    id: 'status',
    translationKey: 'device.list.status',
    sorting: true,
  },
  {
    id: 'operationMode',
    translationKey: 'device.list.operation-mode',
    sorting: true,
  },
];

const DeviceList = () => {
  const { formatMessage, locale } = useIntl();
  const loaderData = useLoaderData();
  const navigate = useNavigate();

  const _mapDevicesToTableRows = devices =>
    devices.map(device => {
      const status = device.online ? 'online' : 'offline';
      return {
        id: device.deviceId,
        name: device.name,
        displayedName: <DeviceName name={device.name} formatted />,
        nameForSearch: device.name || formatMessage({ id: 'data-store.list.unnamed-device' }),
        serialNumber: toHexString(device.serialNumber),
        gatewaySerialNumber: toHexString(device.gateway.serial),
        locationName: device.location?.name,
        infrastructureElementName: device.infrastructureElement?.name,
        online: device.online,
        operationModeKey: device.operationMode,
        operationMode: <DeviceMode mode={device.operationMode} />,
        status: <DeviceMode mode={status} />,
        operationModeForSearch: formatMessage({
          id: `device.list.mode.${device.operationMode.toLowerCase()}`,
        }),
        statusForSearch: formatMessage({ id: `device.list.mode.${status.toLowerCase()}` }),
      };
    });

  const [devices, setDevices] = useState(_mapDevicesToTableRows(loaderData));
  const [filteredDevices, setFilteredDevices] = useState(devices);
  const [searchValue, setSearchValue] = useState('');
  const [notification, setNotification] = useState({});

  const handleSearch = translatedDevices => {
    const searchTerm = searchValue.toLowerCase();
    const filteredResults = translatedDevices.filter(
      device =>
        device.nameForSearch.toString().toLowerCase().includes(searchTerm) ||
        device.serialNumber.toString().toLowerCase().includes(searchTerm) ||
        device.gatewaySerialNumber.toString().toLowerCase().includes(searchTerm) ||
        device.operationModeForSearch.toLowerCase().includes(searchTerm) ||
        device.statusForSearch.toLowerCase().includes(searchTerm) ||
        device.location?.name.toLowerCase().includes(searchTerm) ||
        device.infrastructureElement?.name.toLowerCase().includes(searchTerm)
    );

    setFilteredDevices(filteredResults);
  };

  useEffect(() => {
    const translatedDevices = devices.map(device => {
      const status = device.online ? 'online' : 'offline';
      return {
        ...device,
        operationMode: <DeviceMode mode={device.operationModeKey} />,
        status: <DeviceMode mode={status} />,
        nameForSearch: device.name || formatMessage({ id: 'data-store.list.unnamed-device' }),
        operationModeForSearch: formatMessage({
          id: `device.list.mode.${device.operationModeKey.toLowerCase()}`,
        }),
        statusForSearch: formatMessage({ id: `device.list.mode.${status.toLowerCase()}` }),
      };
    });

    setDevices(translatedDevices);
    handleSearch(translatedDevices);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  const handleGoToEditPage = ({ id }) => navigate(`/device-edit/${id}/info`);

  const tableRowActions = [
    {
      icon: <SettingsIcon />,
      onClick: handleGoToEditPage,
      label: formatMessage({ id: 'common.button.settings' }),
    },
  ];

  const handleSearchValueChange = event => setSearchValue(event.target.value);

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleSearch(devices);
    }
  };

  const searchBar = {
    inputProps: {
      name: 'device-search',
      label: formatMessage({ id: 'common.button.search' }),
      value: searchValue,
      onChange: handleSearchValueChange,
      onKeyDown: handleKeyPress,
    },
    adornmentProps: {
      onClick: () => handleSearch(devices),
    },
  };

  const handleRefresh = async () => {
    try {
      const fetchedDevices = await deviceResource.listDevices();

      setSearchValue('');
      const mappedDevices = _mapDevicesToTableRows(fetchedDevices);
      setDevices(mappedDevices);
      setFilteredDevices(mappedDevices);

      setNotification({
        open: true,
        severity: 'success',
        message: formatMessage({ id: 'device.list.refresh.success' }),
      });
    } catch (error) {
      setNotification({
        open: true,
        severity: 'error',
        message: formatMessage({ id: 'device.list.refresh.error' }),
      });
    }
  };

  const headerActions = [
    {
      isIconButton: true,
      icon: <RefreshIcon />,
      label: formatMessage({ id: 'common.button.refresh' }),
      onClick: handleRefresh,
    },
  ];

  return (
    <>
      <PageContainer>
        <PageHeader
          title={formatMessage({ id: 'device.list.title' })}
          searchBar={searchBar}
          actions={headerActions}
        />

        <Table
          rows={filteredDevices}
          headCells={headCells}
          actions={tableRowActions}
          noResultMessage={formatMessage({
            id: `device.list.${devices.length ? 'no-result' : 'empty'}`,
          })}
        />
      </PageContainer>

      <Notification
        open={notification.open}
        severity={notification.severity}
        message={notification.message}
        onClose={() => setNotification(prev => ({ ...prev, open: false }))}
      />
    </>
  );
};

export const devicesLoader = async () => {
  try {
    const devices = await deviceResource.listDevices();
    return devices;
  } catch (error) {
    throw Error('Failed to get devices!');
  }
};

export default DeviceList;
