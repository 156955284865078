import { Box } from '@mui/material';
import { Remove as DividerIcon } from '@mui/icons-material';
import classNames from 'classnames';

import DatePickerInput from '../DatePickerInput';
import './style.scss';

const DateRangePicker = ({
  start,
  end,
  startLabelKey,
  endLabelKey,
  className,
  error,
  handleStartChange,
  handleEndChange,
}) => (
  <Box className={classNames('date-interval-container', className)}>
    <DatePickerInput
      labelKey={startLabelKey}
      value={start}
      error={error}
      onChange={newValue => handleStartChange(newValue)}
    />
    <DividerIcon className="divider" />
    <DatePickerInput
      labelKey={endLabelKey}
      value={end}
      error={error}
      onChange={newValue => handleEndChange(newValue.set({ hour: 23, minute: 59 }))}
    />
  </Box>
);

export default DateRangePicker;
