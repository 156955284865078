import { useState, useMemo, useEffect } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  Delete as DeleteIcon,
  Add as AddIcon,
  Architecture as ArchitectureIcon,
  Refresh as RefreshIcon,
  GraphicEq as GraphicEqIcon,
  FilterList as FilterIcon,
  Download as DownloadIcon,
} from '@mui/icons-material';
import classNames from 'classnames';
import moment from 'moment/moment';

import {
  dataStoreResource,
  deviceResource,
  gatewayResource,
  infrastructureElementResource,
} from '../../../rest';

import Modal from '../../../components/Modal';
import Notification from '../../../components/Notification';
import PageContainer from '../../../components/PageContainer';
import PageHeader from '../../../components/PageHeader';
import Table from '../../../components/Table';
import SelectInput from '../../../components/SelectInput';
import DateRangePicker from '../../../components/DateRangePicker';
import EdaGenerationModal from '../EdaGenerationModal';
import DeviceName from '../../Device/DeviceName';
import GatewayName from '../../Gateway/GatewayName';
import { toHexString } from '../../../components/HexSerial';
import CalibrationModal from '../CalibrationModal';
import CalibrationResultModal from '../CalibrationResultModal';

const headCells = [
  {
    id: 'name',
    translationKey: 'data-store.list.name',
    sorting: true,
  },
  {
    id: 'type',
    translationKey: 'data-store.list.type.title',
    sorting: true,
  },
  {
    id: 'displayedGatewayName',
    translationKey: 'data-store.list.gateway',
    sorting: true,
  },
  {
    id: 'displayedDeviceName',
    translationKey: 'data-store.list.device',
    sorting: true,
  },
  {
    id: 'infrastructureElement',
    translationKey: 'data-store.list.infrastructure-element',
    sorting: true,
  },
  {
    id: 'location',
    translationKey: 'data-store.list.infrastructure-location',
    sorting: true,
  },
  {
    id: 'status',
    translationKey: 'data-store.list.status.title',
  },
  {
    id: 'creationDate',
    translationKey: 'data-store.list.created',
    sorting: true,
    type: 'date-time',
  },
  {
    id: 'modificationDate',
    translationKey: 'data-store.list.updated',
    sorting: true,
    type: 'date-time',
  },
];

const filterDefaultValue = {
  typeKey: '',
  deviceId: '',
  gatewayId: '',
  infrastructureElementId: '',
  locationId: '',
  statusKey: '',
  creationFrom: null,
  creationTo: null,
  modificationFrom: null,
  modificationTo: null,
};

const DataStoreList = () => {
  const loaderData = useLoaderData();
  const { formatMessage, locale } = useIntl();
  const navigate = useNavigate();

  const mapMeasurementsToTableRows = measurements =>
    measurements.map(measurement => ({
      measurementId: measurement.measurementId,
      name: measurement.name,
      type: formatMessage({ id: `data-store.list.type.${measurement.type.toLowerCase()}` }),
      typeKey: measurement.type,
      gatewayName: measurement.gateway.name,
      gatewaySerial: toHexString(measurement.gateway.serial),
      displayedGatewayName: (
        <GatewayName
          name={measurement.gateway.name}
          serialNumber={measurement.gateway.serial}
          formatted
        />
      ),
      gatewayNameForSearch:
        measurement.gatewayName || formatMessage({ id: 'data-store.list.unnamed-gateway' }),
      gatewayId: measurement.gateway.gatewayId,
      deviceName: measurement.device.name,
      displayedDeviceName: (
        <DeviceName
          name={measurement.device.name}
          serialNumber={measurement.device.serialNumber}
          formatted
        />
      ),
      deviceNameForSearch:
        measurement.device.name || formatMessage({ id: 'data-store.list.unnamed-device' }),
      deviceId: measurement.device.deviceId,
      deviceSerial: toHexString(measurement.device.serialNumber),
      infrastructureElement: measurement.infrastructureElement?.name,
      infrastructureElementId: measurement.infrastructureElement?.infrastructureElementId,
      location: measurement.location?.name,
      locationId: measurement.location?.locationId,
      status: formatMessage({ id: `data-store.list.status.${measurement.status.toLowerCase()}` }),
      statusKey: measurement.status,
      creationDate: measurement.creationDate,
      modificationDate: measurement.modificationDate,
      sensors: measurement.sensors,
    }));

  const [measurements, setMeasurements] = useState(
    mapMeasurementsToTableRows(loaderData.measurements)
  );
  const [filteredMeasurements, setFilteredMeasurements] = useState(measurements);

  const [searchValue, setSearchValue] = useState('');
  const [filters, setFilters] = useState(filterDefaultValue);
  const [popupValues, setPopupValues] = useState(filterDefaultValue);

  const [intervalError, setIntervalError] = useState({});

  const [notification, setNotification] = useState({});

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isEDAGenerationModalOpen, setIsEDAGenerationModalOpen] = useState(false);
  const [isCalibrationModalOpen, setIsCalibrationModalOpen] = useState(false);
  const [isCalibrationResultModalOpen, setIsCalibrationResultModalOpen] = useState(false);
  const [calibratedValues, setCalibratedValues] = useState(null);
  const [selectedMeasurement, setSelectedMeasurement] = useState(null);

  const [locationsMenuItems, setLocationsMenuItems] = useState([]);

  const getValidFilters = currentFilters => {
    const validFilters = { ...currentFilters };

    // Delete filters with empty value
    Object.entries(validFilters).forEach(([key, value]) => {
      if (value === '' || value === null) {
        delete validFilters[key];
      }
    });

    return validFilters;
  };

  const isFiltering = useMemo(() => {
    const validFilters = getValidFilters(filters);
    return Object.keys(validFilters).length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const measurementTypeItems = useMemo(
    () => [
      {
        label: formatMessage({ id: 'common.placeholder.not-selected' }),
        value: '',
        className: 'placeholder-menu-item',
      },
      { label: formatMessage({ id: 'data-store.list.type.stream' }), value: 'STREAM' },
      { label: formatMessage({ id: 'data-store.list.type.measurement' }), value: 'MEASUREMENT' },
      { label: formatMessage({ id: 'data-store.list.type.scheduled' }), value: 'SCHEDULED' },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale]
  );

  const statusMenuItems = useMemo(
    () => [
      {
        label: formatMessage({ id: 'common.placeholder.not-selected' }),
        value: '',
        className: 'placeholder-menu-item',
      },
      {
        label: formatMessage({ id: 'data-store.list.status.no_data' }),
        value: 'NO_DATA',
      },
      {
        label: formatMessage({ id: 'data-store.list.status.waiting_for_data' }),
        value: 'WAITING_FOR_DATA',
      },
      {
        label: formatMessage({ id: 'data-store.list.status.done' }),
        value: 'DONE',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale]
  );

  const deviceMenuItems = useMemo(
    () => {
      const items = loaderData.devices.map(device => ({
        label: <DeviceName name={device.name} serialNumber={device.serialNumber} />,
        value: device.deviceId,
      }));

      items.unshift({
        label: formatMessage({ id: 'common.placeholder.not-selected' }),
        value: '',
        className: 'placeholder-menu-item',
      });

      return items;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale]
  );

  const gatewayMenuItems = useMemo(
    () => {
      const items = loaderData.gateways.map(gateway => ({
        label: <GatewayName name={gateway.name} serialNumber={gateway.serial} />,
        value: gateway.gatewayId,
      }));

      items.unshift({
        label: formatMessage({
          id: 'common.placeholder.not-selected',
        }),
        value: '',
        className: 'placeholder-menu-item',
      });

      return items;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale]
  );

  const infrastructureElementMenuItems = useMemo(
    () => {
      const items = loaderData.infrastructureElements.map(infrastructureElement => ({
        label: infrastructureElement.name,
        value: infrastructureElement.infrastructureElementId,
      }));

      items.unshift({
        label: formatMessage({ id: 'common.placeholder.not-selected' }),
        value: '',
        className: 'placeholder-menu-item',
      });

      return items;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale]
  );

  const handleSearchValueChange = ({ target: { value } }) => setSearchValue(value);

  const handleInfrastructureElementChange = async ({ target: { value } }) => {
    setPopupValues(prev => ({ ...prev, infrastructureElementId: value, locationId: '' }));

    if (!value) {
      return;
    }

    try {
      const newLocations = await infrastructureElementResource.getLocationsForInfrastructureElement(
        value
      );

      const menuItems = newLocations.map(location => ({
        label: location.name,
        value: location.locationId,
      }));

      menuItems.unshift({
        label: formatMessage({
          id: 'common.placeholder.not-selected',
        }),
        value: '',
        className: 'placeholder-menu-item',
      });

      setLocationsMenuItems(menuItems);
    } catch (error) {
      setNotification({
        open: true,
        severity: 'error',
        message: formatMessage({
          id: 'data-store.list.filter.get-locations-error',
        }),
      });
    }
  };

  const getDateCondition = (measurement, validFilters, filterKey) => {
    switch (filterKey) {
      case 'creationFrom':
        return moment(measurement.creationDate) >= validFilters.creationFrom;
      case 'creationTo':
        return moment(measurement.creationDate) <= validFilters.creationTo;
      case 'modificationFrom':
        return moment(measurement.modificationDate) >= validFilters.modificationFrom;
      case 'modificationTo':
        return moment(measurement.modificationDate) <= validFilters.modificationTo;
      default:
        return null;
    }
  };

  const handleSearchAndFilter = translatedMeasurements => {
    const searchTerm = searchValue.toLowerCase();
    const searchResults = translatedMeasurements.filter(
      measurement =>
        measurement.name.toLowerCase().includes(searchTerm) ||
        measurement.type.toLowerCase().includes(searchTerm) ||
        measurement.deviceNameForSearch.toLowerCase().includes(searchTerm) ||
        measurement.deviceSerial.toLowerCase().includes(searchTerm) ||
        measurement.gatewayNameForSearch.toLowerCase().includes(searchTerm) ||
        measurement.gatewaySerial.toLowerCase().includes(searchTerm) ||
        measurement.infrastructureElement?.toLowerCase().includes(searchTerm) ||
        measurement.location?.toLowerCase().includes(searchTerm) ||
        measurement.status.toLowerCase().includes(searchTerm)
    );

    setFilters(popupValues);

    const validFilters = getValidFilters(popupValues);

    const isCreationRangeInvalid = validFilters?.creationFrom > validFilters?.creationTo;
    const isModificationRangeInvalid =
      validFilters?.modificationFrom > validFilters?.modificationTo;

    setIntervalError({
      creation: isCreationRangeInvalid,
      modification: isModificationRangeInvalid,
    });

    if (isCreationRangeInvalid || isModificationRangeInvalid) {
      setNotification({
        open: true,
        severity: 'error',
        message: formatMessage({
          id: 'data-store.list.filter.interval-error',
        }),
      });

      return;
    }

    let elements = searchResults;

    const dateFilters = ['creationFrom', 'creationTo', 'modificationFrom', 'modificationTo'];

    if (Object.keys(validFilters).length) {
      elements = searchResults.filter(measurement =>
        Object.entries(validFilters).every(([filterKey, filterValue]) =>
          dateFilters.includes(filterKey)
            ? getDateCondition(measurement, validFilters, filterKey)
            : measurement[filterKey] === filterValue
        )
      );
    }

    setFilteredMeasurements(elements);
    setIsFilterModalOpen(false);
  };

  useEffect(() => {
    const translatedMeasurements = measurements.map(measurement => ({
      ...measurement,
      type: formatMessage({ id: `data-store.list.type.${measurement.typeKey.toLowerCase()}` }),
      status: formatMessage({
        id: `data-store.list.status.${measurement.statusKey.toLowerCase()}`,
      }),
      gatewayNameForSearch:
        measurement.gatewayName || formatMessage({ id: 'data-store.list.unnamed-gateway' }),
      deviceNameForSearch:
        measurement.deviceName || formatMessage({ id: 'data-store.list.unnamed-device' }),
    }));

    setMeasurements(translatedMeasurements);
    handleSearchAndFilter(translatedMeasurements);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  const handleKeyPress = ({ key }) => {
    if (key === 'Enter') {
      handleSearchAndFilter(measurements);
    }
  };

  const handleRefresh = async () => {
    try {
      const fetchedMeasurements = await dataStoreResource.listMeasurementsForDataStore();
      const mappedMeasurements = mapMeasurementsToTableRows(fetchedMeasurements);

      setSearchValue('');

      setMeasurements(mappedMeasurements);
      setFilteredMeasurements(mappedMeasurements);

      setFilters(filterDefaultValue);

      setNotification({
        open: true,
        severity: 'success',
        message: formatMessage({ id: 'data-store.list.refresh.success' }),
      });
    } catch (error) {
      setNotification({
        open: true,
        severity: 'error',
        message: formatMessage({ id: 'data-store.list.refresh.error' }),
      });
    }
  };

  const handleDeleteClicked = measurement => {
    setIsDeleteModalOpen(true);
    setSelectedMeasurement(measurement);
  };

  const handleDeleteModalClose = () => {
    setSelectedMeasurement(null);
    setIsDeleteModalOpen(false);
  };

  const handleDeleteModalSubmit = async () => {
    try {
      await dataStoreResource.deleteMeasurement(selectedMeasurement.measurementId);

      setMeasurements(prev =>
        prev.filter(measurement => measurement.measurementId !== selectedMeasurement.measurementId)
      );
      setFilteredMeasurements(prev =>
        prev.filter(measurement => measurement.measurementId !== selectedMeasurement.measurementId)
      );

      setNotification({
        open: true,
        severity: 'success',
        message: formatMessage({ id: 'data-store.list.delete.success' }),
      });
    } catch (error) {
      setNotification({
        open: true,
        severity: 'error',
        message: formatMessage({ id: 'data-store.list.delete.error' }),
      });
    } finally {
      handleDeleteModalClose();
    }
  };

  const handleGoToCreatePage = () => navigate('/data-store/create');

  const handleClearFilters = () => {
    setPopupValues(filterDefaultValue);
    setIntervalError({});
  };

  const handleCancel = () => {
    setIsFilterModalOpen(false);
    setPopupValues(filters);
  };

  const handleEDAGenerationClicked = measurement => {
    setIsEDAGenerationModalOpen(true);
    setSelectedMeasurement(measurement);
  };

  const handleEdaGenerationCancel = () => {
    setIsEDAGenerationModalOpen(false);
    setSelectedMeasurement(null);
  };

  const handleEdaGenerationError = () => {
    setNotification({
      open: true,
      severity: 'error',
      message: formatMessage({ id: 'data-store.list.eda-generation.error' }),
    });
  };

  const handleMeasurementDownload = row => {
    window.open(`/api/measurement/${row.measurementId}/download`, '_blank', 'noopener noreferrer');
  };

  const handlerCalibrationClicked = measurement => {
    setIsCalibrationModalOpen(true);
    setSelectedMeasurement(measurement);
  };

  const handleCalibrationSuccess = values => {
    setIsCalibrationModalOpen(false);
    setSelectedMeasurement(null);
    setIsCalibrationResultModalOpen(true);
    setCalibratedValues(values);
  };

  const handleCalibrationCancel = () => {
    setIsCalibrationModalOpen(false);
    setSelectedMeasurement(null);
  };

  const handleCalibrationError = () => {
    setNotification({
      open: true,
      severity: 'error',
      message: formatMessage({ id: 'data-store.list.calibration.error' }),
    });
  };

  const handleCalibrationResultClose = () => {
    setIsCalibrationResultModalOpen(false);
    setCalibratedValues(null);
  };

  const tableRowActions = [
    {
      icon: <ArchitectureIcon />,
      disabled: row =>
        row.statusKey !== 'DONE' || (row.typeKey !== 'MEASUREMENT' && row.typeKey !== 'SCHEDULED'),
      onClick: handlerCalibrationClicked,
      label: formatMessage({ id: 'common.button.calibrate' }),
    },
    {
      icon: <GraphicEqIcon />,
      disabled: row => row.statusKey !== 'DONE',
      onClick: handleEDAGenerationClicked,
      label: formatMessage({ id: 'common.button.eda-generation' }),
    },
    {
      icon: <DownloadIcon />,
      disabled: row => row.statusKey !== 'DONE',
      onClick: handleMeasurementDownload,
      label: formatMessage({ id: 'common.button.download' }),
    },
    {
      icon: <DeleteIcon />,
      onClick: handleDeleteClicked,
      label: formatMessage({ id: 'common.button.delete' }),
    },
  ];

  const headerActions = [
    {
      isIconButton: true,
      icon: <RefreshIcon />,
      label: formatMessage({ id: 'common.button.refresh' }),
      onClick: handleRefresh,
    },
    {
      icon: <AddIcon />,
      label: formatMessage({ id: 'common.button.create' }),
      onClick: handleGoToCreatePage,
    },
  ];

  const searchBar = {
    inputProps: {
      name: 'measurement-search',
      label: formatMessage({ id: 'common.button.search' }),
      value: searchValue,
      onChange: handleSearchValueChange,
      onKeyDown: handleKeyPress,
    },
    adornmentProps: {
      onClick: () => handleSearchAndFilter(measurements),
    },
  };

  const filter = {
    icon: (
      <FilterIcon
        className={classNames('filter-icon', {
          active: isFiltering,
        })}
      />
    ),
    label: formatMessage({ id: 'common.button.filter' }),
    onClick: () => setIsFilterModalOpen(true),
  };

  const clearButton = {
    label: 'common.button.clear',
    onClick: handleClearFilters,
  };

  return (
    <>
      <PageContainer>
        <PageHeader
          title={formatMessage({ id: 'data-store.list.title' })}
          searchBar={searchBar}
          filter={filter}
          actions={headerActions}
        />

        <Table
          rows={filteredMeasurements}
          headCells={headCells}
          actions={tableRowActions}
          noResultMessage={formatMessage({
            id: `data-store.list.${measurements.length ? 'no-result' : 'empty'}`,
          })}
          defaultSort={{ orderBy: 'creationDate', order: 'desc' }}
        />
      </PageContainer>

      <Modal
        open={isFilterModalOpen}
        title={formatMessage({ id: 'data-store.list.filter.title' })}
        optionalActionButton={clearButton}
        submitLabelKey="common.button.ok"
        closeLabelKey="common.button.cancel"
        className="filter-modal"
        handleSubmit={() => handleSearchAndFilter(measurements)}
        handleClose={handleCancel}
      >
        {/* Measurement type */}
        <SelectInput
          name="measurementType"
          value={popupValues.typeKey}
          label={formatMessage({ id: 'data-store.list.filter.measurement-type' })}
          menuItems={measurementTypeItems}
          onChange={({ target: { value } }) =>
            setPopupValues(prev => ({ ...prev, typeKey: value }))
          }
        />

        {/* Device */}
        <SelectInput
          name="device"
          value={popupValues.deviceId}
          label={formatMessage({ id: 'data-store.list.filter.device' })}
          menuItems={deviceMenuItems}
          onChange={({ target: { value } }) =>
            setPopupValues(prev => ({ ...prev, deviceId: value }))
          }
        />

        {/* Gateway */}
        <SelectInput
          name="gateway"
          value={popupValues.gatewayId}
          label={formatMessage({ id: 'data-store.list.filter.gateway' })}
          menuItems={gatewayMenuItems}
          onChange={({ target: { value } }) =>
            setPopupValues(prev => ({ ...prev, gatewayId: value }))
          }
        />

        {/* Infrastructure element */}
        <SelectInput
          name="infrastructureElement"
          value={popupValues.infrastructureElementId}
          label={formatMessage({ id: 'data-store.list.filter.infrastructure-element' })}
          menuItems={infrastructureElementMenuItems}
          onChange={handleInfrastructureElementChange}
        />

        {/* Location */}
        <SelectInput
          name="location"
          value={popupValues.locationId}
          label={formatMessage({ id: 'data-store.list.filter.location' })}
          menuItems={locationsMenuItems}
          disabled={!popupValues.infrastructureElementId}
          onChange={({ target: { value } }) =>
            setPopupValues(prev => ({ ...prev, locationId: value }))
          }
        />

        {/* Status */}
        <SelectInput
          name="status"
          value={popupValues.statusKey}
          label={formatMessage({ id: 'data-store.list.filter.status' })}
          menuItems={statusMenuItems}
          onChange={({ target: { value } }) =>
            setPopupValues(prev => ({ ...prev, statusKey: value }))
          }
        />

        {/* Range inputs for creation date */}
        <DateRangePicker
          start={popupValues.creationFrom}
          end={popupValues.creationTo}
          startLabelKey="data-store.list.filter.creation-date"
          endLabelKey="data-store.list.filter.creation-date"
          className="creation"
          error={intervalError.creation}
          handleStartChange={newValue =>
            setPopupValues(prev => ({
              ...prev,
              creationFrom: newValue,
            }))
          }
          handleEndChange={newValue =>
            setPopupValues(prev => ({
              ...prev,
              creationTo: newValue,
            }))
          }
        />

        {/* Range inputs for modification date */}
        <DateRangePicker
          start={popupValues.modificationFrom}
          end={popupValues.modificationTo}
          startLabelKey="data-store.list.filter.modification-date"
          endLabelKey="data-store.list.filter.modification-date"
          error={intervalError.modification}
          handleStartChange={newValue =>
            setPopupValues(prev => ({
              ...prev,
              modificationFrom: newValue,
            }))
          }
          handleEndChange={newValue =>
            setPopupValues(prev => ({
              ...prev,
              modificationTo: newValue,
            }))
          }
        />
      </Modal>

      <Modal
        open={isDeleteModalOpen}
        message={formatMessage({ id: 'data-store.list.delete.title' })}
        handleSubmit={handleDeleteModalSubmit}
        handleClose={handleDeleteModalClose}
      />

      {isCalibrationModalOpen && (
        <CalibrationModal
          measurement={selectedMeasurement}
          onSuccess={handleCalibrationSuccess}
          onCancel={handleCalibrationCancel}
          onError={handleCalibrationError}
        />
      )}

      {isCalibrationResultModalOpen && (
        <CalibrationResultModal
          calibratedValues={calibratedValues}
          onClose={handleCalibrationResultClose}
        />
      )}

      {isEDAGenerationModalOpen && (
        <EdaGenerationModal
          measurement={selectedMeasurement}
          onCancel={handleEdaGenerationCancel}
          onError={handleEdaGenerationError}
        />
      )}

      <Notification
        open={notification.open}
        severity={notification.severity}
        message={notification.message}
        onClose={() => setNotification(prev => ({ ...prev, open: false }))}
      />
    </>
  );
};

export const dataStoreLoader = async () => {
  try {
    const [measurements, devices, gateways, infrastructureElements] = await Promise.all([
      dataStoreResource.listMeasurementsForDataStore(),
      deviceResource.listDevices(),
      gatewayResource.listGateways(),
      infrastructureElementResource.getInfrastructureElements(),
    ]);

    return { measurements, devices, gateways, infrastructureElements };
  } catch (error) {
    throw Error('Failed to get data store informations!');
  }
};

export default DataStoreList;
