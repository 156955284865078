import { api } from '../rest';

const getElementTypes = async () => {
  const { data } = await api.get('/element-type');
  return data;
};

const deleteElementType = id => api.delete(`/element-type/${id}`);

const createElementType = name => api.post('/element-type', name);

const getElementTypeById = async id => {
  const { data } = await api.get(`/element-type/${id}`);
  return data;
};

const updateElementType = (id, name) => api.put(`/element-type/${id}`, name);

export default {
  getElementTypes,
  deleteElementType,
  createElementType,
  getElementTypeById,
  updateElementType,
};
