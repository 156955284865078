import { Box } from '@mui/material';

import './style.scss';

const TabPanel = ({ children, index, currentTab, ...other }) => (
  <div
    role="tabpanel"
    hidden={index !== currentTab}
    id={`nav-tab-${currentTab}`}
    aria-controls={`nav-tabpanel-${currentTab}`}
    {...other}
  >
    {index === currentTab && <Box className="tab-panel">{children}</Box>}
  </div>
);

export default TabPanel;
