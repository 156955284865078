import { FormattedMessage, useIntl } from 'react-intl';

import Modal from '../../../components/Modal';

import { dataStoreResource } from '../../../rest';

const CalibrationModal = ({ measurement, onSuccess, onCancel, onError }) => {
  const { formatMessage } = useIntl();

  const handleCalibrationSubmit = async () => {
    try {
      const values = await dataStoreResource.calibrateDevice(measurement.measurementId);
      onSuccess(values);
    } catch (error) {
      onError();
    }
  };

  return (
    <Modal
      open
      title={formatMessage({ id: 'data-store.list.calibration.title' })}
      submitLabelKey="common.button.ok"
      closeLabelKey="common.button.cancel"
      className="calibration-modal"
      handleSubmit={handleCalibrationSubmit}
      handleClose={onCancel}
    >
      <div className="confirm-text">
        <div>
          <FormattedMessage id="data-store.list.calibration.hint" />
        </div>
        <div>
          <FormattedMessage id="data-store.list.calibration.confirm" />
        </div>
      </div>
    </Modal>
  );
};

export default CalibrationModal;
