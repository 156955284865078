import { MenuItem, TextField } from '@mui/material';
import classNames from 'classnames';

import './style.scss';

const SelectInput = ({ menuItems, ...remaining }) => (
  <TextField select fullWidth margin="normal" {...remaining}>
    {menuItems.map(({ value, label: itemLabel, className, ...remainingProps }) => (
      <MenuItem
        key={value}
        value={value}
        className={classNames('custom-select-item', className)}
        {...remainingProps}
      >
        {itemLabel}
      </MenuItem>
    ))}
  </TextField>
);

export default SelectInput;
