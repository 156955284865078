import { useState, useEffect } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Settings as SettingsIcon, Refresh as RefreshIcon } from '@mui/icons-material';

import { gatewayResource } from '../../../rest';

import { toHexString } from '../../../components/HexSerial';

import PageContainer from '../../../components/PageContainer';
import PageHeader from '../../../components/PageHeader';
import Table from '../../../components/Table';
import Notification from '../../../components/Notification';
import GatewayName from '../GatewayName';

const headCells = [
  {
    id: 'displayName',
    translationKey: 'gateway.list.name',
    sorting: true,
  },
  {
    id: 'serial',
    translationKey: 'gateway.list.serial-number',
    sorting: true,
  },
  {
    id: 'infrastructureElementName',
    translationKey: 'gateway.list.infrastructure-element',
    sorting: true,
  },
  {
    id: 'locationName',
    translationKey: 'gateway.list.location',
    sorting: true,
  },
  {
    id: 'numberOfDevices',
    translationKey: 'gateway.list.number-of-devices',
    sorting: true,
  },
];

const GatewayList = () => {
  const { formatMessage, locale } = useIntl();
  const loaderData = useLoaderData();
  const navigate = useNavigate();

  const _mapGatewaysToTableRows = gateways =>
    gateways.map(gateway => ({
      id: gateway.gatewayId,
      name: gateway.name,
      displayName: <GatewayName name={gateway.name} />,
      nameForSearch: gateway.name || formatMessage({ id: 'data-store.list.unnamed-gateway' }),
      serial: toHexString(gateway.serial),
      infrastructureElementName: gateway.infrastructureElement?.name,
      locationName: gateway.location?.name,
      numberOfDevices: gateway.numberOfDevices,
    }));

  const [gateways, setGateways] = useState(_mapGatewaysToTableRows(loaderData));
  const [filteredGateways, setFilteredGateways] = useState(gateways);
  const [searchValue, setSearchValue] = useState('');
  const [notification, setNotification] = useState({});

  const handleSearch = translatedGateways => {
    const searchTerm = searchValue.toLowerCase();
    const filteredResults = translatedGateways.filter(
      gateway =>
        gateway.nameForSearch.toLowerCase().includes(searchTerm) ||
        gateway.serial.toLowerCase().includes(searchTerm) ||
        gateway.infrastructureElementName?.toLowerCase().includes(searchTerm) ||
        gateway.locationName?.toLowerCase().includes(searchTerm)
    );

    setFilteredGateways(filteredResults);
  };

  useEffect(() => {
    const translatedGateways = gateways.map(gateway => ({
      ...gateway,
      nameForSearch: gateway.name || formatMessage({ id: 'data-store.list.unnamed-gateway' }),
    }));

    setGateways(translatedGateways);
    handleSearch(translatedGateways);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  const handleGoToEditPage = ({ id }) => navigate(`/gateway/edit/${id}/info`);

  const handleSearchValueChange = event => setSearchValue(event.target.value);

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleSearch(gateways);
    }
  };

  const handleRefresh = async () => {
    try {
      const fetchedGateways = await gatewayResource.listGateways();
      const mappedGateways = _mapGatewaysToTableRows(fetchedGateways);

      setSearchValue('');
      setGateways(mappedGateways);
      setFilteredGateways(mappedGateways);

      setNotification({
        open: true,
        severity: 'success',
        message: formatMessage({ id: 'gateway.list.refresh.success' }),
      });
    } catch (error) {
      setNotification({
        open: true,
        severity: 'error',
        message: formatMessage({ id: 'gateway.list.refresh.error' }),
      });
    }
  };

  const tableRowActions = [
    {
      icon: <SettingsIcon />,
      onClick: handleGoToEditPage,
      label: formatMessage({ id: 'common.button.settings' }),
    },
  ];

  const headerActions = [
    {
      isIconButton: true,
      icon: <RefreshIcon />,
      label: formatMessage({ id: 'common.button.refresh' }),
      onClick: handleRefresh,
    },
  ];

  const searchBar = {
    inputProps: {
      name: 'gateway-search',
      label: formatMessage({ id: 'common.button.search' }),
      value: searchValue,
      onChange: handleSearchValueChange,
      onKeyDown: handleKeyPress,
    },
    adornmentProps: {
      onClick: () => handleSearch(gateways),
    },
  };

  return (
    <>
      <PageContainer>
        <PageHeader
          title={formatMessage({ id: 'gateway.list.title' })}
          searchBar={searchBar}
          actions={headerActions}
        />

        <Table
          rows={filteredGateways}
          headCells={headCells}
          actions={tableRowActions}
          noResultMessage={formatMessage({
            id: `gateway.list.${gateways.length ? 'no-result' : 'empty'}`,
          })}
        />
      </PageContainer>

      <Notification
        open={notification.open}
        severity={notification.severity}
        message={notification.message}
        onClose={() => setNotification(prev => ({ ...prev, open: false }))}
      />
    </>
  );
};

export const gatewaysLoader = async () => {
  try {
    const gateways = await gatewayResource.listGateways();
    return gateways;
  } catch (error) {
    throw Error('Failed to get gateways!');
  }
};

export default GatewayList;
