import moment from 'moment/moment';
import { api } from '../rest';

const listDevices = async () => {
  const { data } = await api.get('/device');
  return data;
};

const getDeviceById = async id => {
  const { data } = await api.get(`/device/${id}`);
  return data;
};

const updateDevice = async (id, device) => {
  const { data } = await api.put(`/device/${id}`, device);
  return data;
};

const startStreaming = (id, sensors) => api.put(`/device/${id}/streaming/start`, { sensors });

const stopStreaming = id => api.put(`/device/${id}/streaming/stop`);

const startScheduling = deviceId => api.put(`/device/${deviceId}/scheduling/start`);

const stopScheduling = (deviceId, stateAfter) =>
  api.put(`/device/${deviceId}/scheduling/stop`, {}, { params: { stateAfter } });

const updateSchedulingTimes = async (deviceId, schedulingTimes) => {
  const { data } = await api.put(`/device/${deviceId}/scheduling`, schedulingTimes);
  return data;
};

const getDeviceSamplingRates = async deviceId => {
  const { data } = await api.put(`/device/${deviceId}/send-get-sampling-rates`);
  return data;
};

const setDeviceSensorSamplingRate = (deviceId, samplingRate) =>
  api.put(`/device/${deviceId}/sampling-rate`, samplingRate);

const setDeviceSensorTranspositions = (deviceId, sensorTranspositions) =>
  api.put(`/device/${deviceId}/transposition`, sensorTranspositions);

const updateDateTime = deviceId => api.post(`/device/${deviceId}/update-date-time`);

const getDeviceStatus = async id => {
  const { data } = await api.get(`/device/${id}/status`);
  return data
    ? {
        ...data,
        serverTime: moment(data.serverTime).format('yyyy-MM-DD HH:mm:ss'),
        deviceTime: moment(data.deviceTime).format('yyyy-MM-DD HH:mm:ss'),
      }
    : null;
};

export default {
  listDevices,
  getDeviceById,
  updateDevice,
  startStreaming,
  stopStreaming,
  startScheduling,
  stopScheduling,
  updateSchedulingTimes,
  getDeviceStatus,
  getDeviceSamplingRates,
  setDeviceSensorSamplingRate,
  setDeviceSensorTranspositions,
  updateDateTime,
};
