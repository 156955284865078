import axios from 'axios';

const api = axios.create({ baseURL: '/api' });

const setInterceptors = (showLoading, hideLoading) => {
  api.interceptors.request.use(request => {
    showLoading();
    return request;
  });

  api.interceptors.response.use(
    response => {
      hideLoading();
      return response;
    },
    error => {
      hideLoading();
      return Promise.reject(error);
    }
  );
};

export { api, setInterceptors };
