import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Form, useActionData, useParams, useRouteLoaderData } from 'react-router-dom';
import { Button } from '@mui/material';
import { Refresh as RefreshIcon, Sync as SyncIcon } from '@mui/icons-material';
import log from 'loglevel';

import { deviceResource, gatewayResource, infrastructureElementResource } from '../../../../rest';

import { toHexString } from '../../../../components/HexSerial';

import Input from '../../../../components/Input';
import PageSection from '../../../../components/PageSection';
import SelectInput from '../../../../components/SelectInput';
import Notification from '../../../../components/Notification';
import SubmitButton from '../../../../components/SubmitButton';
import InputErrorMessage from '../../../../components/InputErrorMessage';
import { useDeviceStatus } from '../DeviceStatusContext';

import './style.scss';

const LOGGER = log.getLogger('DeviceInfo');

const DeviceInfo = () => {
  const { formatMessage, locale } = useIntl();
  const { id: currentDeviceId } = useParams();
  const { isOffline } = useDeviceStatus();

  const actionData = useActionData();
  const deviceBaseData = useRouteLoaderData('device-root');

  const [device, setDevice] = useState(deviceBaseData);
  const [locationsByInfrastructureElement, setLocationsByInfrastructureElement] = useState();
  const [notification, setNotification] = useState({});

  const _mapLocations = locations => {
    const mappedLocations = locations.map(location => ({
      value: location.locationId,
      label: location.name,
    }));

    mappedLocations.unshift({
      value: '',
      label: formatMessage({ id: 'common.placeholder.not-selected' }),
      className: 'placeholder-menu-item',
    });

    return mappedLocations;
  };

  useEffect(() => {
    (async () => {
      try {
        // TODO: deviceStatusResponse is null. Check it. -> input fields remain empty
        const [deviceStatusResponse, gatewayResponse] = await Promise.all([
          deviceResource.getDeviceStatus(currentDeviceId),
          gatewayResource.getGatewayById(deviceBaseData.gateway.gatewayId),
        ]);

        const locationsResponse = gatewayResponse.infrastructureElement
          ? await infrastructureElementResource.getLocationsForInfrastructureElement(
              gatewayResponse.infrastructureElement.infrastructureElementId
            )
          : [];

        const mappedDevice = {
          ...deviceBaseData,
          ...deviceStatusResponse,
          name: deviceBaseData.name || '',
          infrastructureElement: gatewayResponse.infrastructureElement?.name || '',
          locationId: deviceBaseData.location?.locationId || '',
        };

        delete mappedDevice.location;

        setDevice(mappedDevice);
        setLocationsByInfrastructureElement(_mapLocations(locationsResponse));
      } catch (error) {
        throw Error('Failed to get device!');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDeviceId]);

  useEffect(() => {
    if (actionData) {
      let severity;
      let message;

      if (actionData.updateDeviceAction?.ok) {
        severity = 'success';
        message = formatMessage({ id: 'device.management.info.administrative.update.success' });
      } else if (actionData.updateDeviceAction?.error?.server) {
        message = formatMessage({ id: 'device.management.info.administrative.update.error' });
        severity = 'error';
      } else {
        return;
      }

      setNotification({
        open: true,
        severity,
        message,
        onClose: () => {
          setNotification(prev => ({ ...prev, open: false }));
          // Need to delete, because if it set, the notification will display in case of language change
          delete actionData.updateDeviceAction;
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionData, locale]);

  useEffect(() => {
    if (!locationsByInfrastructureElement) {
      return;
    }
    const translatedLocations = [...locationsByInfrastructureElement];

    translatedLocations[0].label = formatMessage({ id: 'common.placeholder.not-selected' });

    setLocationsByInfrastructureElement(translatedLocations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  const handleDeviceDataChange = ({ target: { name, value } }) =>
    setDevice({ ...device, [name]: value });

  const handleRefresh = async () => {
    try {
      const fetchedDevice = await deviceResource.getDeviceStatus(device.deviceId);

      setDevice({ ...device, ...fetchedDevice });

      setNotification({
        open: true,
        severity: 'success',
        message: formatMessage({ id: 'device.management.info.technical.refresh.success' }),
      });
    } catch (error) {
      LOGGER.error('Failed to refresh device info!', error);
      setNotification({
        open: true,
        severity: 'error',
        message: formatMessage({ id: 'device.management.info.technical.refresh.error' }),
      });
    }
  };

  const handleUpdateDateTime = async () => {
    try {
      await deviceResource.updateDateTime(device.deviceId);

      setNotification({
        open: true,
        severity: 'success',
        message: formatMessage({ id: 'device.management.info.technical.update-date-time.success' }),
      });
    } catch (error) {
      LOGGER.error('Failed to update date time on device!', error);
      setNotification({
        open: true,
        severity: 'error',
        message: formatMessage({ id: 'device.management.info.technical.update-date-time.error' }),
      });
    }
  };

  const technicalSectionActions = [
    {
      isIconButton: true,
      icon: <RefreshIcon />,
      label: formatMessage({ id: 'common.button.refresh' }),
      onClick: handleRefresh,
      disabled: isOffline,
    },
  ];

  return (
    <>
      {device && locationsByInfrastructureElement && (
        <>
          <PageSection title={formatMessage({ id: 'device.management.info.administrative.title' })}>
            <Form method="put" action={`/device-edit/${device.deviceId}/info`} noValidate>
              <Input
                name="name"
                label={formatMessage({ id: 'device.management.info.administrative.name' })}
                value={device.name}
                onChange={handleDeviceDataChange}
              />
              <Input
                name="serialNumber"
                label={formatMessage({ id: 'device.management.info.administrative.serial-number' })}
                disabled
                value={toHexString(device.serialNumber)}
              />
              <Input
                name="gatewaySerialNumber"
                label={formatMessage({
                  id: 'device.management.info.administrative.gateway-serial-number',
                })}
                disabled
                value={toHexString(device.gateway.serial)}
              />
              <Input
                name="infrastructureElement"
                value={device.infrastructureElement}
                label={formatMessage({
                  id: 'device.management.info.administrative.infrastructure-element',
                })}
                disabled
              />
              <SelectInput
                name="locationId"
                value={device.locationId}
                label={formatMessage({ id: 'device.management.info.administrative.location' })}
                menuItems={locationsByInfrastructureElement}
                onChange={handleDeviceDataChange}
                disabled={!device.infrastructureElement}
                error={actionData?.updateDeviceForm?.error?.location?.required}
              />
              {actionData?.updateDeviceForm?.error?.location?.required && (
                <InputErrorMessage message={formatMessage({ id: 'common.required-field' })} />
              )}
              <SubmitButton />
            </Form>
          </PageSection>

          <PageSection
            title={formatMessage({ id: 'device.management.info.technical.title' })}
            actions={technicalSectionActions}
          >
            <Input
              label={formatMessage({ id: 'device.management.info.technical.hardware-version' })}
              disabled
              value={device.hardwareVersion || ''}
            />
            <Input
              label={formatMessage({ id: 'device.management.info.technical.software-version' })}
              disabled
              value={device.softwareVersion || ''}
            />
            <Input
              label={formatMessage({ id: 'device.management.info.technical.battery-level' })}
              disabled
              value={device.battery || ''}
            />
            <Input
              label={formatMessage({ id: 'device.management.info.technical.board-temperature' })}
              disabled
              value={device.temperature || ''}
            />
            <Input
              label={formatMessage({ id: 'device.management.info.technical.last-update' })}
              disabled
              value={device.serverTime || ''}
            />
            <div className="device-time-wrapper">
              <Input
                label={formatMessage({ id: 'device.management.info.technical.device-time' })}
                disabled
                value={device.deviceTime || ''}
              />
              <Button
                variant="contained"
                type="button"
                startIcon={<SyncIcon />}
                onClick={handleUpdateDateTime}
                disabled={isOffline}
              />
            </div>
          </PageSection>
        </>
      )}
      <Notification
        open={notification.open}
        severity={notification.severity}
        message={notification.message}
        onClose={
          notification.onClose || (() => setNotification(prev => ({ ...prev, open: false })))
        }
      />
    </>
  );
};

export const updateDeviceAction = async ({ request, params }) => {
  const { id } = params;
  const data = await request.formData();

  const infrastructureElementId = data.get('infrastructureElement');
  const locationId = data.get('locationId');

  if (infrastructureElementId && !locationId) {
    return {
      updateDeviceForm: {
        error: {
          location: {
            required: !locationId,
          },
        },
      },
    };
  }

  const submission = {
    locationId,
    name: data.get('name'),
  };

  try {
    await deviceResource.updateDevice(id, submission);

    return { updateDeviceAction: { ok: true } };
  } catch (error) {
    return {
      updateDeviceAction: {
        error: {
          server: true,
        },
      },
    };
  }
};

export default DeviceInfo;
