import { createContext, useContext, useState, useMemo, useEffect } from 'react';
import sensorResource from '../../rest/resources/sensorResource';

const SensorContext = createContext();

export const useSensor = () => useContext(SensorContext);

const SensorProvider = ({ children }) => {
  const [sensors, setSensors] = useState();

  useEffect(() => {
    (async () => {
      try {
        const fetchedSensors = await sensorResource.listSensors();
        const mappedSensors = fetchedSensors.map(sensor => ({
          ...sensor,
          sensorFields: Object.keys(sensor.sensorFields),
        }));
        setSensors(mappedSensors);
      } catch (error) {
        throw Error('Failed to get sensors!');
      }
    })();
  }, []);

  const contextValue = useMemo(() => ({ sensors }), [sensors]);

  return (
    <SensorContext.Provider value={contextValue}>
      {sensors ? children : null}
    </SensorContext.Provider>
  );
};

export default SensorProvider;
