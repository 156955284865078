import { useEffect, useState, useMemo } from 'react';
import { Form, redirect, useLoaderData, useActionData } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { dataStoreResource } from '../../../rest';

import { useSensor } from '../../../context/SensorContext';
import Notification from '../../../components/Notification';
import PageContainer from '../../../components/PageContainer';
import PageHeader from '../../../components/PageHeader';
import PageSection from '../../../components/PageSection';
import InputErrorMessage from '../../../components/InputErrorMessage';
import Input from '../../../components/Input';
import SubmitButton from '../../../components/SubmitButton';
import SelectInput from '../../../components/SelectInput';
import RadioButtonGroup from '../../../components/RadioButtonGroup';
import MultiSelectInput from '../../../components/MultiSelectInput';

import './style.scss';
import DeviceName from '../../Device/DeviceName';

const MeasurementCreate = () => {
  const devices = useLoaderData();
  const actionData = useActionData();
  const { formatMessage, locale } = useIntl();
  const { sensors } = useSensor();

  const radioItems = useMemo(
    () => [
      { label: formatMessage({ id: 'data-store.create.stream' }), value: 'STREAM' },
      { label: formatMessage({ id: 'data-store.create.measurement' }), value: 'MEASUREMENT' },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale]
  );

  const [serverError, setServerError] = useState();
  const [selectedSensors, setSelectedSensors] = useState(sensors.map(({ name }) => name));
  const [measurementType, setMeasurementType] = useState(radioItems[0].value);

  const sensorMenuItems = useMemo(
    () => {
      const menuItems = sensors.map(({ name: sensorName }) => ({
        value: sensorName,
        label: sensorName,
      }));

      menuItems.unshift({
        value: 'all',
        label: formatMessage({ id: 'data-store.create.sensor-select-all' }),
        className: 'placeholder-menu-item',
      });

      return menuItems;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale]
  );

  useEffect(() => {
    if (actionData?.measurementCreateAction?.error.server) {
      setServerError(actionData?.measurementCreateAction?.error.server);
    }
  }, [actionData]);

  const deviceMenuItems = useMemo(() => {
    const items = devices.map(device => ({
      label: <DeviceName name={device.name} serialNumber={device.serialNumber} formatted />,
      value: device.deviceId,
    }));

    return items;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices, locale]);

  const handleMeasurementTypeChange = ({ target: { value } }) => {
    setMeasurementType(value);
  };

  const isChecked = menuItemValue => {
    if (menuItemValue === 'all') {
      return sensors.every(sensor => selectedSensors.includes(sensor.name));
    }

    return selectedSensors.includes(menuItemValue);
  };

  const handleSensorSelectorChange = ({ target: { value } }) => {
    if (value.includes('all')) {
      setSelectedSensors(isChecked('all') ? [] : sensors.map(sensor => sensor.name));
    } else {
      setSelectedSensors(value);
    }
  };

  const handleRenderSelectedSensorValue = selected => selected.join(', ');

  return (
    <>
      <PageContainer>
        <PageHeader
          backButton={{ route: '/data-store' }}
          title={formatMessage({
            id: 'data-store.create.title',
          })}
        />

        <PageSection>
          <Form
            noValidate
            method="post"
            action="/data-store/create"
            className="data-store-measurement-create-form"
          >
            {/* Type */}
            <RadioButtonGroup
              name="measurement-type"
              labelTranslationKey="data-store.create.type"
              radioItems={radioItems}
              value={measurementType}
              onChange={handleMeasurementTypeChange}
            />

            {/* Name */}
            <Input
              className="data-store-measurement-create-form-input"
              name="name"
              type="text"
              label={formatMessage({ id: 'data-store.create.name' })}
              defaultValue=""
              required
              error={actionData?.measurementCreateForm?.error?.name?.required}
            />
            {actionData?.measurementCreateForm?.error?.name?.required && (
              <InputErrorMessage message={formatMessage({ id: 'common.required-field' })} />
            )}

            {/* Device */}
            <SelectInput
              className="data-store-measurement-create-form-input"
              name="device"
              label={formatMessage({ id: 'data-store.create.device' })}
              defaultValue=""
              menuItems={deviceMenuItems}
              required
              error={actionData?.measurementCreateForm?.error?.deviceId?.required}
            />
            {actionData?.measurementCreateForm?.error?.deviceId?.required && (
              <InputErrorMessage message={formatMessage({ id: 'common.required-field' })} />
            )}

            {measurementType === 'STREAM' && (
              <>
                <MultiSelectInput
                  className="data-store-measurement-create-form-input"
                  name="sensors"
                  label={formatMessage({ id: 'device.management.streaming.sensors' })}
                  value={selectedSensors}
                  onChange={handleSensorSelectorChange}
                  renderValue={handleRenderSelectedSensorValue}
                  menuItems={sensorMenuItems.map(item => ({
                    ...item,
                    checked: isChecked(item.value),
                  }))}
                  required
                  error={actionData?.measurementCreateForm?.error?.sensors?.required}
                />
                {actionData?.measurementCreateForm?.error?.sensors?.required && (
                  <InputErrorMessage message={formatMessage({ id: 'common.required-field' })} />
                )}
              </>
            )}

            {/* Record time in sec */}
            <Input
              className="data-store-measurement-create-form-input"
              name="record-time"
              type="number"
              label={formatMessage({ id: 'data-store.create.record-time' })}
              defaultValue=""
              required
              error={actionData?.measurementCreateForm?.error?.recordTime?.required}
            />
            {actionData?.measurementCreateForm?.error?.recordTime?.required && (
              <InputErrorMessage message={formatMessage({ id: 'common.required-field' })} />
            )}

            <SubmitButton />
          </Form>
        </PageSection>
      </PageContainer>

      <Notification
        open={serverError}
        severity="error"
        message={formatMessage({
          id: 'data-store.create.error',
        })}
        onClose={() => setServerError(false)}
      />
    </>
  );
};

export const measurementCreateAction = async ({ request }) => {
  const data = await request.formData();

  const measurementData = {
    name: data.get('name'),
    deviceId: data.get('device'),
    recordTime: data.get('record-time'),
    type: data.get('measurement-type'),
    sensors: data.get('sensors')?.split(','),
  };

  if (
    !measurementData.name ||
    !measurementData.deviceId ||
    !measurementData.recordTime ||
    (measurementData.type === 'STREAM' && !measurementData.sensors)
  ) {
    return {
      measurementCreateForm: {
        error: {
          name: { required: !measurementData.name },
          deviceId: { required: !measurementData.deviceId },
          recordTime: { required: !measurementData.recordTime },
          sensors: { required: measurementData.type === 'STREAM' && !measurementData.sensors },
        },
      },
    };
  }

  try {
    await dataStoreResource.createMeasurement(measurementData);
  } catch (error) {
    return { measurementCreateAction: { error: { server: true } } };
  }

  return redirect('/data-store');
};

export default MeasurementCreate;
