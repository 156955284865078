import { useState, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { axisTitleOptions, baseOptions, tickOptions, tooltipOptions } from './chart.options';
import LineGraphCommon from './LineGraphCommon';

import './style.scss';

const FFTGraph = ({ data: { fft: fftData } }) => {
  const { formatMessage, locale } = useIntl();

  const transformedData = useMemo(
    () =>
      Object.entries(fftData)
        .map(([frequency, magnitude]) => ({ frequency: parseFloat(frequency), magnitude }))
        .sort((a, b) => a.frequency - b.frequency),
    [fftData]
  );

  const defaultRange = useMemo(
    () => ({
      min: transformedData.at(0).frequency,
      max: transformedData.at(-1).frequency,
    }),
    [transformedData]
  );

  const [range, setRange] = useState(defaultRange);

  const [magnitudeLabel, frequencyLabel, hzLabel] = useMemo(
    () => [
      formatMessage({ id: 'eda.view.graph.fft.magnitude' }),
      formatMessage({ id: 'eda.view.graph.fft.frequency' }),
      formatMessage({ id: 'eda.view.graph.unit.hz' }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formatMessage, locale]
  );

  const tooltipHzFormatter = useCallback(
    contexts => contexts.map(context => `${context.parsed.x.toFixed(1)} ${hzLabel}`),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale, hzLabel]
  );

  const tooltipValueFormatter = useCallback(
    context => {
      const label = context.dataset.label || '';
      const value = context.parsed.y;

      return `${label}: ${value.toFixed(2)}`;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale]
  );

  const data = useMemo(
    () => ({
      labels: transformedData.map(({ frequency }) => frequency),
      datasets: [
        {
          label: magnitudeLabel,
          data: transformedData.map(({ magnitude }) => magnitude),
          borderWidth: 2.5,
          borderColor: 'rgb(0, 143, 251)',
          backgroundColor: 'rgb(0, 143, 251)',
          pointRadius: 0,
          pointHitRadius: 16,
          spanGaps: true,
        },
      ],
    }),
    [transformedData, magnitudeLabel]
  );

  const options = useMemo(
    () => ({
      ...baseOptions,
      scales: {
        x: {
          type: 'linear',
          title: {
            ...axisTitleOptions,
            text: `${frequencyLabel} (${hzLabel})`,
          },
          ticks: {
            ...tickOptions,
            callback: value => value.toFixed(1),
          },
          min: range.min,
          max: range.max,
        },
        y: {
          title: {
            ...axisTitleOptions,
            text: magnitudeLabel,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          ...tooltipOptions,
          callbacks: {
            title: tooltipHzFormatter,
            label: tooltipValueFormatter,
          },
        },
      },
    }),
    [frequencyLabel, magnitudeLabel, hzLabel, tooltipHzFormatter, tooltipValueFormatter, range]
  );

  const handleUpdateRange = newRange => {
    setRange(newRange);
  };

  const handleResetRange = () => {
    setRange(defaultRange);
  };

  return (
    <LineGraphCommon
      className="fft-graph"
      data={data}
      options={options}
      onUpdateRange={handleUpdateRange}
      onResetRange={handleResetRange}
    />
  );
};

export default FFTGraph;
