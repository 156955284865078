export const titleTextOptions = {
  offsetY: 24,
  style: {
    fontSize: '1.25rem',
    fontWeight: '500',
  },
};

export const axisTextOptions = {
  style: {
    fontSize: '1.25rem',
    fontWeight: '500',
  },
};
