import { useIntl } from 'react-intl';
import { useOutlet } from 'react-router-dom';

import PageContainer from '../../../../components/PageContainer';
import PageHeader from '../../../../components/PageHeader';

const InfrastructureElementCreateWrapper = () => {
  const outlet = useOutlet();
  const { formatMessage } = useIntl();

  return (
    <PageContainer>
      <PageHeader
        backButton={{ route: '/infrastructure-element' }}
        title={formatMessage({
          id: 'infrastructure-element.management.create',
        })}
      />

      {outlet}
    </PageContainer>
  );
};

export default InfrastructureElementCreateWrapper;
