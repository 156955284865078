import { Alert, Snackbar } from '@mui/material';

const Notification = ({ open, severity, message, onClose }) => (
  <Snackbar
    open={open}
    autoHideDuration={5000}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    onClose={onClose}
  >
    <Alert severity={severity} variant="filled" onClose={onClose}>
      {message}
    </Alert>
  </Snackbar>
);

export default Notification;
