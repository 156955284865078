import { useState } from 'react';
import { useLoaderData, useNavigate, useOutlet } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  IconButton,
  Toolbar,
  Typography,
  AppBar,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer,
  Box,
  Container,
  Avatar,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  AccountCircleOutlined as AccountIcon,
  PersonOutline as ProfilePictureIcon,
  LogoutOutlined as LogoutIcon,
  Explicit as ElementTypeIcon,
  DeveloperBoard as DeviceIcon,
  GraphicEq as EDAIcon,
  LocationCity as InfrastructureElementIcon,
  Speed as DataStoreIcon,
  Router as GatewayIcon,
  NotificationsActive as AlertIcon,
} from '@mui/icons-material';

import classNames from 'classnames';

import { authenticationResource } from '../../../rest';

import { useLocale } from '../../../context/LocaleContext';
import SensorProvider from '../../../context/SensorContext';
import { FlagIconHU, FlagIconGB } from '../../../components/Flag';

import './style.scss';

const languages = {
  hu: FlagIconHU,
  en: FlagIconGB,
};

const RootLayout = () => {
  const outlet = useOutlet();
  const navigate = useNavigate();
  const user = useLoaderData();

  const { formatMessage } = useIntl();

  const { locale, changeLocale } = useLocale();
  const CurrentLanguageIcon = languages[locale];

  const [open, setOpen] = useState();

  const [profileMenuAnchorElement, setProfileMenuAnchorElement] = useState(null);
  const profileMenuOpen = Boolean(profileMenuAnchorElement);
  const [languageMenuAnchorElement, setLanguageMenuAnchorElement] = useState(null);
  const languageMenuOpen = Boolean(languageMenuAnchorElement);

  const handleOpenProfileMenu = event => setProfileMenuAnchorElement(event.currentTarget);

  const handleCloseProfileMenu = () => setProfileMenuAnchorElement(null);

  const handleOpenLanguageMenu = event => setLanguageMenuAnchorElement(event.currentTarget);

  const handleCloseLanguageMenu = () => setLanguageMenuAnchorElement(null);

  const handleChangeLanguage = newLocale => {
    changeLocale(newLocale);
    handleCloseLanguageMenu();
  };

  const handleNavigateToProfilePage = () => {
    handleCloseProfileMenu();
    navigate('profile');
  };

  const handleLogOut = async () => {
    handleCloseProfileMenu();
    await authenticationResource.logout();
    navigate('login');
  };

  const toggleDrawer = () => setOpen(!open);

  return (
    <SensorProvider>
      <AppBar position="absolute" open={open} className={classNames('header', { open })}>
        <Toolbar>
          <IconButton
            color="inherit"
            className={classNames('sidebar-button', { open })}
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h6">{formatMessage({ id: 'header.project' })}</Typography>

          <Box className="placeholder" />

          <Box className="menu-wrapper">
            <IconButton className="language-menu-button" onClick={handleOpenLanguageMenu}>
              <CurrentLanguageIcon className="current-language-icon" />
              <Typography className="current-language-abbr" variant="button">
                {formatMessage({ id: `header.language.${locale}.abbr` })}
              </Typography>
            </IconButton>

            <Menu
              className="language-menu"
              anchorEl={languageMenuAnchorElement}
              open={languageMenuOpen}
              onClose={handleCloseLanguageMenu}
            >
              {Object.keys(languages).map((key, index) => {
                const LanguageIcon = languages[key];
                return (
                  <MenuItem onClick={() => handleChangeLanguage(key)} key={index}>
                    <ListItemIcon>
                      <LanguageIcon fontSize="small" />
                    </ListItemIcon>
                    {formatMessage({ id: `header.language.${key}.label` })}
                  </MenuItem>
                );
              })}
            </Menu>

            <IconButton className="profile-menu-button" onClick={handleOpenProfileMenu}>
              <Avatar className="profile-picture" src={user.profilePicture}>
                {/* Fallback icon */}
                <ProfilePictureIcon color="primary" />
              </Avatar>
            </IconButton>

            <Menu
              className="profile-menu"
              anchorEl={profileMenuAnchorElement}
              open={profileMenuOpen}
              onClose={handleCloseProfileMenu}
            >
              <Box className="profile-menu-header">
                <Typography className="username" variant="body1">
                  {user.name}
                </Typography>
                <Typography variant="body2">{user.email}</Typography>
              </Box>
              <Divider className="profile-menu-item-divider" />
              <MenuItem onClick={handleNavigateToProfilePage}>
                <ListItemIcon>
                  <AccountIcon fontSize="small" />
                </ListItemIcon>
                {formatMessage({ id: 'header.profile.my-profile' })}
              </MenuItem>
              <MenuItem onClick={handleLogOut}>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                {formatMessage({ id: 'header.profile.log-out' })}
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open} className={classNames({ closed: !open })}>
        <Toolbar>
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          <ListItemButton onClick={() => navigate('alert')}>
            <ListItemIcon>
              <Tooltip title={formatMessage({ id: 'sidebar.alert' })}>
                <AlertIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary={formatMessage({ id: 'sidebar.alert' })} />
          </ListItemButton>
          <ListItemButton onClick={() => navigate('gateway')}>
            <ListItemIcon>
              <Tooltip title={formatMessage({ id: 'sidebar.gateways' })}>
                <GatewayIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary={formatMessage({ id: 'sidebar.gateways' })} />
          </ListItemButton>
          <ListItemButton onClick={() => navigate('device')}>
            <ListItemIcon>
              <Tooltip title={formatMessage({ id: 'sidebar.devices' })}>
                <DeviceIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary={formatMessage({ id: 'sidebar.devices' })} />
          </ListItemButton>
          <ListItemButton onClick={() => navigate('element-type')}>
            <ListItemIcon>
              <Tooltip title={formatMessage({ id: 'sidebar.element-types' })}>
                <ElementTypeIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary={formatMessage({ id: 'sidebar.element-types' })} />
          </ListItemButton>
          <ListItemButton onClick={() => navigate('infrastructure-element')}>
            <ListItemIcon>
              <Tooltip title={formatMessage({ id: 'sidebar.infrastructure-elements' })}>
                <InfrastructureElementIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary={formatMessage({ id: 'sidebar.infrastructure-elements' })} />
          </ListItemButton>
          <ListItemButton onClick={() => navigate('data-store')}>
            <ListItemIcon>
              <Tooltip title={formatMessage({ id: 'sidebar.data-store' })}>
                <DataStoreIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary={formatMessage({ id: 'sidebar.data-store' })} />
          </ListItemButton>
          <ListItemButton onClick={() => navigate('eda')}>
            <ListItemIcon>
              <Tooltip title={formatMessage({ id: 'sidebar.eda' })}>
                <EDAIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary={formatMessage({ id: 'sidebar.eda' })} />
          </ListItemButton>
        </List>
      </Drawer>

      <Box component="main">
        <Toolbar />
        <Container maxWidth="xl">{outlet}</Container>
      </Box>
    </SensorProvider>
  );
};

export default RootLayout;
