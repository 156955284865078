import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from 'react-router-dom';

import { authenticationResource } from '../../rest';

import Login from '../Login';
import RootLayout from '../Layout/RootLayout';
import ElementTypeList, { elementTypesLoader } from '../ElementType/List';
import Profile, { updateProfileAndChangePasswordAction } from '../Profile';
import InfrastructureElementCreateWrapper from '../InfrastructureElement/Management/CreateWrapper';
import ElementTypeManagement, {
  elementTypeLoader,
  elementTypeManagementAction,
} from '../ElementType/Management';
import EDAList, { edaLoader as edaListLoader } from '../EDA/List';
import EDAView, { edaLoader as edaViewLoader } from '../EDA/View';
import DeviceList, { devicesLoader } from '../Device/List';
import DeviceManagement, { deviceLoader } from '../Device/Management';
import DeviceInfo, { updateDeviceAction } from '../Device/Management/Info';
import DeviceStreaming from '../Device/Management/Streaming';
import InfrastructureElementList, {
  infrastructureElementsLoader,
} from '../InfrastructureElement/List';
import InfrastructureElementManagement, {
  infrastructureElementLoader,
} from '../InfrastructureElement/Management';
import InfrastructureElementLocation, {
  locationsLoader,
} from '../InfrastructureElement/Management/Location';
import InfrastructureElementDetails, {
  infrastructureElementManagementElementAction,
} from '../InfrastructureElement/Management/Element';
import DeviceCalibration, { updateCalibrationAction } from '../Device/Management/Calibration';
import DeviceScheduling, { updateSchedulingTimesAction } from '../Device/Management/Scheduling';
import DeviceSampling from '../Device/Management/Sampling';
import DataStoreList, { dataStoreLoader } from '../DataStore/List';
import GatewayList, { gatewaysLoader } from '../Gateway/List';
import GatewayManagement, { gatewayLoader } from '../Gateway/Management';
import GatewayInfo, { gatewayInfoLoader } from '../Gateway/Management/Info';
import GatewayDevices, { gatewayDeviceLoader } from '../Gateway/Management/Devices';
import GatewaySampling from '../Gateway/Management/Sampling';
import MeasurementCreate, { measurementCreateAction } from '../DataStore/MeasurementCreate';
import AlertList, { alertsLoader } from '../Alert';

const userLoader = async (throwError = true) => {
  try {
    const fetchedUser = await authenticationResource.getCurrentUser();
    return fetchedUser;
  } catch (error) {
    if (throwError) {
      throw Error('Failed to get user!');
    }

    return null;
  }
};

const AppRouter = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        <Route
          id="private-root"
          path="/"
          element={<RootLayout />}
          loader={userLoader}
          // Revalidate in case of profile data change, because header should be updated!
          shouldRevalidate={({ formData }) => formData?.get('action-type') === 'update-profile'}
          errorElement={<Navigate to="login" />}
        >
          <Route path="/" element={<Navigate to="/alert" replace />} />
          {/* TODO: error element */}

          {/* Alert */}
          <Route
            path="alert"
            element={<AlertList />}
            loader={alertsLoader}
            errorElement={<div>Hello</div>}
          />

          {/* Element type */}
          <Route
            id="element-type-root"
            path="element-type"
            element={<ElementTypeList />}
            loader={elementTypesLoader}
            errorElement={<div>Hello</div>}
          />
          <Route
            path="element-type/create"
            element={<ElementTypeManagement />}
            action={elementTypeManagementAction}
            errorElement={<div>Hello</div>}
          />
          <Route
            path="element-type/edit/:id"
            element={<ElementTypeManagement />}
            loader={elementTypeLoader}
            shouldRevalidate={() => false}
            action={elementTypeManagementAction}
            errorElement={<div>Hello</div>}
          />

          {/* Gateway */}
          <Route
            path="gateway"
            element={<GatewayList />}
            loader={gatewaysLoader}
            errorElement={<div>Hello</div>}
          />
          <Route
            id="gateway-root"
            path="gateway/edit/:id"
            element={<GatewayManagement />}
            loader={gatewayLoader}
            shouldRevalidate={() => false}
            errorElement={<div>Hello</div>}
          >
            <Route
              path="info"
              element={<GatewayInfo />}
              loader={gatewayInfoLoader}
              shouldRevalidate={() => false}
              errorElement={<div>Hello</div>}
            />
            <Route
              path="devices"
              element={<GatewayDevices />}
              loader={gatewayDeviceLoader}
              errorElement={<div>Hello</div>}
            />
            <Route path="sampling" element={<GatewaySampling />} />
          </Route>

          {/* Device */}
          <Route
            path="device"
            element={<DeviceList />}
            loader={devicesLoader}
            errorElement={<div>Hello</div>}
          />
          <Route
            id="device-root"
            path="device-edit/:id"
            element={<DeviceManagement />}
            loader={deviceLoader}
            errorElement={<div>Hello</div>}
          >
            <Route
              path="info"
              element={<DeviceInfo />}
              errorElement={<div>Hello</div>}
              action={updateDeviceAction}
            />
            <Route
              path="calibration"
              element={<DeviceCalibration />}
              errorElement={<div>Hello</div>}
              action={updateCalibrationAction}
            />
            <Route path="sampling" element={<DeviceSampling />} errorElement={<div>Hello</div>} />
            <Route
              path="scheduling"
              element={<DeviceScheduling />}
              errorElement={<div>Hello</div>}
              action={updateSchedulingTimesAction}
            />
            <Route path="streaming" element={<DeviceStreaming />} errorElement={<div>Hello</div>} />
          </Route>

          {/* EDA */}
          <Route
            path="eda/:id/view"
            element={<EDAView />}
            loader={edaViewLoader}
            errorElement={<div>Hello</div>}
          />
          <Route
            path="eda"
            element={<EDAList />}
            loader={edaListLoader}
            errorElement={<div>Hello</div>}
          />

          {/* Profile */}
          <Route
            path="profile"
            element={<Profile />}
            action={updateProfileAndChangePasswordAction}
          />

          {/* Infrastructure element */}
          <Route
            path="infrastructure-element"
            element={<InfrastructureElementList />}
            loader={infrastructureElementsLoader}
            errorElement={<div>Hello</div>}
          />
          <Route
            path="infrastructure-element/create"
            element={<InfrastructureElementCreateWrapper />}
            loader={elementTypesLoader}
            action={infrastructureElementManagementElementAction}
            errorElement={<div>Hello</div>}
          >
            <Route
              index
              element={<InfrastructureElementDetails />}
              loader={elementTypesLoader}
              action={infrastructureElementManagementElementAction}
              errorElement={<div>Hello</div>}
            />
          </Route>
          <Route
            id="infrastructure-element-edit-root"
            path="infrastructure-element/edit/:id"
            element={<InfrastructureElementManagement />}
            loader={infrastructureElementLoader}
            errorElement={<div>Hello</div>}
          >
            <Route
              path="element"
              element={<InfrastructureElementDetails />}
              loader={elementTypesLoader}
              shouldRevalidate={() => false}
              action={infrastructureElementManagementElementAction}
              errorElement={<div>Hello</div>}
            />

            <Route
              path="location"
              element={<InfrastructureElementLocation />}
              loader={locationsLoader}
              errorElement={<div>Hello</div>}
            />
          </Route>

          {/* Data store */}
          <Route
            path="data-store"
            element={<DataStoreList />}
            loader={dataStoreLoader}
            errorElement={<div>Hello</div>}
          />
          <Route
            path="data-store/create"
            element={<MeasurementCreate />}
            loader={devicesLoader}
            shouldRevalidate={() => false}
            action={measurementCreateAction}
            errorElement={<div>Hello</div>}
          />
        </Route>

        <Route path="login" element={<Login />} loader={() => userLoader(false)} />
      </Route>
    )
  );

  return <RouterProvider router={router} />;
};

export default AppRouter;
