import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

import { setInterceptors } from '../../rest/rest';

import './style.scss';

const LoadingContext = createContext();

export const useLoading = () => useContext(LoadingContext);

const LoadingProvider = ({ children }) => {
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(0);
  const loadingRef = useRef(0);

  const showLoading = useCallback(() => {
    loadingRef.current += 1;
    setLoading(loadingRef.current);
  }, []);

  const hideLoading = useCallback(() => {
    if (loadingRef.current > 0) {
      loadingRef.current -= 1;
      setLoading(loadingRef.current);
    }
  }, []);

  useEffect(() => {
    setInterceptors(showLoading, hideLoading);
    setInitialized(true);
  }, [showLoading, hideLoading]);

  const contextValue = useMemo(
    () => ({ loading, showLoading, hideLoading }),
    [loading, showLoading, hideLoading]
  );

  return (
    <LoadingContext.Provider value={contextValue}>
      <Backdrop className="loading" open={loading !== 0}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {initialized && children}
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;
