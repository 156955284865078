import { Container } from '@mui/material';
import classNames from 'classnames';

import './style.scss';

const PageContainer = ({ children, className }) => (
  <Container maxWidth="xl" className={classNames('page-container', className)}>
    {children}
  </Container>
);

export default PageContainer;
