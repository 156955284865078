import { useState, useMemo, useEffect, useRef } from 'react';
import { useRouteLoaderData } from 'react-router-dom';
import { useIntl } from 'react-intl';
import moment from 'moment';
import Chart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import { Box, Button } from '@mui/material';

import { deviceResource } from '../../../../rest';

import { axisTextOptions, titleTextOptions } from '../../../../utils/apexChartsCommon';

import { useSensor } from '../../../../context/SensorContext';
import { useLoading } from '../../../../context/LoadingContext';
import { useDeviceStatus } from '../DeviceStatusContext';
import PageSection from '../../../../components/PageSection';
import Notification from '../../../../components/Notification';
import MultiSelectInput from '../../../../components/MultiSelectInput';

import './style.scss';

const X_AXIS_RANGE = 10000;

const operationModes = {
  IDLE: { streaming: false, stoppable: false },
  WAITING_FOR_RESPONSE: { streaming: false, stoppable: false },
  ALERT: { streaming: true, stoppable: false },
  STREAMING: { streaming: true, stoppable: true },
  RECORD_STREAMING: { streaming: true, stoppable: false },
  RECORD_MEASURING: { streaming: false, stoppable: false },
  TRANSFERRING: { streaming: false, stoppable: false },
  SCHEDULED_TO_LISTEN: { streaming: false, stoppable: false },
  SCHEDULED_TO_STREAM: { streaming: false, stoppable: false },
};

const DeviceStreaming = () => {
  const device = useRouteLoaderData('device-root');
  const { formatMessage, locale } = useIntl();

  const { showLoading, hideLoading } = useLoading();
  const { sensors } = useSensor();
  const {
    isOffline,
    operationMode,
    streamingSensors: contextStreamingSensors,
    addSubscription,
    removeSubscription,
    addMessageHandlers,
    removeMessageHandlers,
  } = useDeviceStatus();

  const initSensorData = () =>
    sensors.reduce((result, { name }) => ({ ...result, [name]: {} }), {});

  const streamingData = useRef(initSensorData());

  const [selectedSensors, setSelectedSensors] = useState([]);
  const [notification, setNotification] = useState({});

  const chartSettings = useMemo(
    () =>
      selectedSensors.reduce((result, sensor) => {
        const title = formatMessage({
          id: `device.management.streaming.graph.${sensor.name}.title`,
        });
        const xaxis = formatMessage({
          id: `device.management.streaming.graph.${sensor.name}.xaxis`,
        });
        const yaxis = formatMessage({
          id: `device.management.streaming.graph.${sensor.name}.yaxis`,
        });

        const option = {
          title: {
            text: title,
            ...titleTextOptions,
          },
          chart: {
            id: sensor.name,
            type: 'line',
            animations: {
              enabled: false,
              easing: 'linear',
              dynamicAnimation: {
                speed: 500,
              },
            },
            toolbar: { show: false },
            zoom: { enabled: false },
          },
          stroke: {
            curve: 'smooth',
            width: 3,
          },
          yaxis: {
            title: {
              text: yaxis,
              ...axisTextOptions,
            },
            type: 'numeric',
            decimalsInFloat: 2,
          },
          xaxis: {
            title: {
              text: xaxis,
              ...axisTextOptions,
            },
            type: 'datetime',
            range: X_AXIS_RANGE,
            labels: {
              show: true,
              formatter: (_, timestamp) => moment(timestamp).format('HH:mm:ss'),
            },
          },
          legend: {
            position: 'top',
            horizontalAlign: 'right',
            offsetY: -25,
            offsetX: -5,
          },
        };

        const series = sensor.sensorFields.map(dimension => ({
          name: formatMessage({
            id: `device.management.streaming.graph.${sensor.name}.${dimension}`,
          }),
          data: [],
        }));

        return { ...result, [sensor.name]: { option, series } };
      }, {}),
    [locale, selectedSensors] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const updateParams = (_operationMode, chartSensors) => {
    if (operationModes[operationMode].streaming && !operationModes[_operationMode].streaming) {
      return selectedSensors;
    }

    const mappedSensors = sensors.filter(sensor => chartSensors.includes(sensor.name));
    setSelectedSensors(mappedSensors);

    return mappedSensors;
  };

  const isStreaming = useMemo(() => operationModes[operationMode].streaming, [operationMode]);

  const handleStateChangeMessage = msg => {
    updateParams(msg.operationMode, msg.sensors);
  };

  const handleStartStreamingMessage = msg => {
    // init sensors and chart data
    const mappedSensors = updateParams(msg.operationMode, msg.sensors);
    streamingData.current = initSensorData();

    // reset chart
    mappedSensors.forEach(sensor => {
      ApexCharts.exec(sensor.name, 'updateOptions', {
        chart: { toolbar: { show: false }, zoom: { enabled: false } },
        xaxis: { range: X_AXIS_RANGE },
      });
    });

    hideLoading();
  };

  const handleStopStreamingMessage = () => {
    // reset chart
    selectedSensors.forEach(sensor => {
      ApexCharts.exec(sensor.name, 'updateOptions', {
        chart: { toolbar: { show: true }, zoom: { enabled: true } },
        xaxis: { range: undefined },
      });
    });
  };

  const _addNewData = (sensor, dimension, data) => {
    const newData = streamingData.current[sensor][dimension] || [];

    // Check if there is a data with the same timestamp
    const index = newData.findIndex(item => item.x === data.x);
    if (index !== -1) {
      // Override the value for current timestamp if exists.
      newData[index].y = data.y;
    } else {
      newData.push(data);
    }

    streamingData.current[sensor][dimension] = newData;

    return newData;
  };

  const handleDataStreamingMessage = msg => {
    msg.data.forEach(streamingDataMsg => {
      const timestamp = new Date(streamingDataMsg.timestamp).getTime();

      Object.entries(streamingDataMsg.data)
        // Skip if the sensor is not selected
        .filter(([sensor]) => selectedSensors.some(currentSensor => currentSensor.name === sensor))
        .forEach(([sensor, sensorStreamingValue]) => {
          let graphData = [];

          if (typeof sensorStreamingValue.value === 'object') {
            // handle multidimensional data
            graphData = Object.entries(sensorStreamingValue.value).map(([dimension, value]) => {
              const newData = _addNewData(sensor, dimension, {
                x: timestamp,
                y: value,
              });

              return { data: newData.slice() };
            });
          } else {
            // handle one dimensional data
            const newData = _addNewData(sensor, 'value', {
              x: timestamp,
              y: sensorStreamingValue.value,
            });

            graphData = [{ data: newData.slice() }];
          }

          ApexCharts.exec(sensor, 'updateSeries', graphData);
        });
    });
  };

  useEffect(() => {
    addSubscription(`/topic/${device.deviceId}/streaming`);

    if (isStreaming) {
      updateParams(operationMode, contextStreamingSensors);
      streamingData.current = initSensorData();
    }

    return () => {
      removeSubscription(`/topic/${device.deviceId}/streaming`);
      removeMessageHandlers([
        'STATE_CHANGE',
        'START_STREAMING',
        'STOP_STREAMING',
        'DATA_STREAMING',
      ]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    addMessageHandlers([
      {
        type: 'STATE_CHANGE',
        callback: handleStateChangeMessage,
      },
      {
        type: 'START_STREAMING',
        callback: handleStartStreamingMessage,
      },
      {
        type: 'STOP_STREAMING',
        callback: handleStopStreamingMessage,
      },
      {
        type: 'DATA_STREAMING',
        callback: handleDataStreamingMessage,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    handleDataStreamingMessage,
    handleStartStreamingMessage,
    handleStateChangeMessage,
    handleStopStreamingMessage,
  ]);

  const handleSensorSelectorChange = ({ target: { value } }) => setSelectedSensors(value);

  const handleRenderSelectedSensorValue = selected =>
    selected.map(selectedSensor => selectedSensor.name).join(', ');

  const handleStartStreaming = async () => {
    try {
      showLoading();
      await deviceResource.startStreaming(
        device.deviceId,
        selectedSensors.map(sensor => sensor.name)
      );
    } catch (error) {
      hideLoading();
      setNotification({
        open: true,
        severity: 'error',
        message: formatMessage({
          id: 'device.management.streaming.error.start',
        }),
      });
    }
  };

  const handleStopStreaming = async () => {
    try {
      await deviceResource.stopStreaming(device.deviceId);
    } catch (error) {
      setNotification({
        open: true,
        severity: 'error',
        message: formatMessage({
          id: 'device.management.streaming.error.stop',
        }),
      });
    }
  };

  return (
    <>
      <PageSection>
        <Box className="sensor-container">
          <MultiSelectInput
            label={formatMessage({ id: 'device.management.streaming.sensors' })}
            value={selectedSensors}
            onChange={handleSensorSelectorChange}
            renderValue={handleRenderSelectedSensorValue}
            menuItems={sensors.map(sensor => ({
              value: sensor,
              label: sensor.name,
              checked: selectedSensors.findIndex(selected => selected.name === sensor.name) > -1,
            }))}
            disabled={isStreaming || isOffline}
          />

          <Box className="streaming-control-container">
            <Button
              className="streaming-control"
              variant="contained"
              onClick={handleStartStreaming}
              disabled={selectedSensors.length === 0 || isStreaming || isOffline}
            >
              {formatMessage({ id: 'device.management.streaming.start' })}
            </Button>

            <Button
              className="streaming-control"
              variant="contained"
              onClick={handleStopStreaming}
              disabled={!isStreaming || !operationModes[operationMode].stoppable || isOffline}
            >
              {formatMessage({ id: 'device.management.streaming.stop' })}
            </Button>
          </Box>
        </Box>

        <Box className="chart-container">
          {selectedSensors.map(({ name: sensorName }) => (
            <Chart
              key={sensorName}
              options={chartSettings[sensorName].option}
              series={chartSettings[sensorName].series}
              height="400"
            />
          ))}
        </Box>
      </PageSection>

      <Notification
        open={notification.open}
        severity={notification.severity}
        message={notification.message}
        onClose={() => setNotification(prev => ({ ...prev, open: false }))}
      />
    </>
  );
};

export default DeviceStreaming;
