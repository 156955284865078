import { useIntl } from 'react-intl';
import { useLoaderData, useLocation } from 'react-router-dom';

import { deviceResource } from '../../../rest';

import PageContainer from '../../../components/PageContainer';
import PageHeader from '../../../components/PageHeader';
import TabLayout from '../../../components/Tab/Layout';
import DeviceName from '../DeviceName';
import DeviceStatusProvider from './DeviceStatusContext';
import DeviceStatusInfo from './DeviceStatusInfo';

import './style.scss';

const DeviceManagement = () => {
  const { formatMessage } = useIntl();
  const device = useLoaderData();
  const location = useLocation();

  const tabs = [
    {
      id: 'info',
      label: formatMessage({ id: 'device.management.tabs.info' }),
      path: 'info',
    },
    {
      id: 'calibration',
      label: formatMessage({ id: 'device.management.tabs.calibration' }),
      path: 'calibration',
    },
    {
      id: 'sampling',
      label: formatMessage({ id: 'device.management.tabs.sampling' }),
      path: 'sampling',
    },
    {
      id: 'scheduling',
      label: formatMessage({ id: 'device.management.tabs.scheduling' }),
      path: 'scheduling',
    },
    {
      id: 'streaming',
      label: formatMessage({ id: 'device.management.tabs.streaming' }),
      path: 'streaming',
    },
  ];

  return (
    <DeviceStatusProvider device={device}>
      <PageContainer>
        <PageHeader
          title={<DeviceName name={device.name} serialNumber={device.serialNumber} formatted />}
          backButton={{ route: location.state?.navigateBackTo || '/device' }}
        >
          <DeviceStatusInfo />
        </PageHeader>

        <TabLayout tabs={tabs} currentTabId={location.pathname.split('/').pop()} />
      </PageContainer>
    </DeviceStatusProvider>
  );
};

export const deviceLoader = async ({ params }) => {
  const { id } = params;
  try {
    const device = await deviceResource.getDeviceById(id);
    return device;
  } catch (error) {
    throw Error('Failed to get device!');
  }
};

export default DeviceManagement;
