import { useEffect, useMemo, useState } from 'react';
import { Form, redirect, useActionData, useLoaderData, useRouteLoaderData } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Grid } from '@mui/material';

import { infrastructureElementResource } from '../../../../rest';

import Input from '../../../../components/Input';
import InputErrorMessage from '../../../../components/InputErrorMessage';
import PageSection from '../../../../components/PageSection';
import SelectInput from '../../../../components/SelectInput';
import Notification from '../../../../components/Notification';
import SubmitButton from '../../../../components/SubmitButton';

const InfrastructureElementDetails = () => {
  const infrastructureElement = useRouteLoaderData('infrastructure-element-edit-root');
  const elementTypes = useLoaderData();
  const actionData = useActionData();
  const { formatMessage } = useIntl();

  const [notification, setNotification] = useState({});

  useEffect(() => {
    if (actionData) {
      let severity;
      let message;

      if (actionData.infrastructureElementManagementAction?.ok) {
        severity = 'success';
        message = formatMessage({ id: 'infrastructure-element.management.element.edit.success' });
      } else if (actionData.infrastructureElementManagementAction?.error?.server) {
        severity = 'error';
        message = formatMessage({ id: 'infrastructure-element.management.element.edit.error' });
      } else {
        // Important, because there can be error, when we do not want to display notification (required field for example)
        return;
      }

      setNotification({
        open: true,
        severity,
        message,
        onClose: () => {
          setNotification(prev => ({ ...prev, open: false }));
          // Need to delete, because if it set, the notification will display in case of tab change
          delete actionData.infrastructureElementManagementAction;
        },
      });
    }
  }, [actionData, formatMessage]);

  const elementTypesMenuItems = useMemo(() => {
    const items = elementTypes.map(element => ({
      value: element.elementTypeId,
      label: element.name,
    }));

    items.unshift({
      value: '',
      label: formatMessage({
        id: 'common.placeholder.not-selected',
      }),
      className: 'placeholder-menu-item',
    });

    return items;
  }, [elementTypes, formatMessage]);

  return (
    <PageSection>
      <Form
        noValidate
        method={infrastructureElement ? 'put' : 'post'}
        action={
          infrastructureElement
            ? `/infrastructure-element/edit/${infrastructureElement.infrastructureElementId}/element`
            : '/infrastructure-element/create'
        }
      >
        {/* Name */}
        <Input
          required
          name="infrastructure-element-name"
          type="text"
          label={formatMessage({ id: 'infrastructure-element.management.element.name' })}
          defaultValue={infrastructureElement?.name || ''}
          error={actionData?.infrastructureElementManagementForm?.error?.name?.required}
        />
        {actionData?.infrastructureElementManagementForm?.error?.name?.required && (
          <InputErrorMessage message={formatMessage({ id: 'common.required-field' })} />
        )}

        {/* Element type */}
        <SelectInput
          required
          name="infrastructure-element-type"
          defaultValue={infrastructureElement?.elementType.elementTypeId || ''}
          label={formatMessage({
            id: 'infrastructure-element.management.element.element-type',
          })}
          menuItems={elementTypesMenuItems}
          error={actionData?.infrastructureElementManagementForm?.error?.elementType?.required}
          className="element-type-select"
        />
        {actionData?.infrastructureElementManagementForm?.error?.elementType?.required && (
          <InputErrorMessage message={formatMessage({ id: 'common.required-field' })} />
        )}

        <Grid container spacing={2}>
          <Grid item xs={6}>
            {/* Latitude */}
            <Input
              required
              name="infrastructure-element-latitude"
              type="number"
              label={formatMessage({ id: 'infrastructure-element.management.element.latitude' })}
              defaultValue={infrastructureElement?.latitude || ''}
              error={actionData?.infrastructureElementManagementForm?.error?.latitude?.required}
            />
            {actionData?.infrastructureElementManagementForm?.error?.latitude?.required && (
              <InputErrorMessage message={formatMessage({ id: 'common.required-field' })} />
            )}
          </Grid>

          <Grid item xs={6}>
            {/* Longitude */}
            <Input
              required
              name="infrastructure-element-longitude"
              type="number"
              label={formatMessage({ id: 'infrastructure-element.management.element.longitude' })}
              defaultValue={infrastructureElement?.longitude || ''}
              error={actionData?.infrastructureElementManagementForm?.error?.longitude?.required}
            />
            {actionData?.infrastructureElementManagementForm?.error?.longitude?.required && (
              <InputErrorMessage message={formatMessage({ id: 'common.required-field' })} />
            )}
          </Grid>
        </Grid>

        <SubmitButton />
      </Form>

      <Notification
        open={notification.open}
        severity={notification.severity}
        message={notification.message}
        onClose={notification.onClose}
      />
    </PageSection>
  );
};

export const infrastructureElementManagementElementAction = async ({ params, request }) => {
  const { id } = params;
  const data = await request.formData();

  const infrastructureElementData = {
    name: data.get('infrastructure-element-name'),
    elementType: data.get('infrastructure-element-type'),
    latitude: data.get('infrastructure-element-latitude'),
    longitude: data.get('infrastructure-element-longitude'),
  };

  if (
    !infrastructureElementData.name ||
    !infrastructureElementData.elementType ||
    !infrastructureElementData.latitude ||
    !infrastructureElementData.longitude
  ) {
    return {
      infrastructureElementManagementForm: {
        error: {
          name: { required: !infrastructureElementData.name },
          elementType: { required: !infrastructureElementData.elementType },
          latitude: { required: !infrastructureElementData.latitude },
          longitude: { required: !infrastructureElementData.longitude },
        },
      },
    };
  }

  const promise = id
    ? infrastructureElementResource.updateInfrastructureElement(id, infrastructureElementData)
    : infrastructureElementResource.createInfrastructureElement(infrastructureElementData);

  try {
    await promise;
  } catch (error) {
    return { infrastructureElementManagementAction: { error: { server: true } } };
  }

  return id
    ? {
        infrastructureElementManagementAction: { ok: true },
      }
    : redirect('/infrastructure-element');
};

export default InfrastructureElementDetails;
