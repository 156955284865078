import { useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import 'chartjs-adapter-moment';

import ZoomOverlay from './ZoomOverlay';

ChartJS.register(LinearScale, TimeScale, PointElement, LineElement, Title, Tooltip, Legend);

const LineGraphCommon = ({ className, data, options, onUpdateRange, onResetRange }) => {
  const chart = useRef();

  const handleZoom = (start, end) => {
    const scale = chart.current.scales.x.max - chart.current.scales.x.min;

    onUpdateRange({
      min: chart.current.scales.x.min + start * scale,
      max: chart.current.scales.x.min + end * scale,
    });
  };

  const handleResetZoom = () => {
    onResetRange();
    chart.current.resize();
  };

  useEffect(() => {
    if (chart.current) {
      chart.current.resize();
    }
    // eslint-disable-next-line
  }, [chart?.current]);

  return (
    <div className={className}>
      <Line ref={chart} data={data} options={options} />
      <ZoomOverlay
        chart={chart.current}
        top={chart.current?.chartArea.top}
        left={chart.current?.chartArea.left}
        height={chart.current?.chartArea.height}
        onZoom={handleZoom}
        onResetZoom={handleResetZoom}
      />
    </div>
  );
};

export default LineGraphCommon;
