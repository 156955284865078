import { SvgIcon } from '@mui/material';

// https://github.com/lipis/flag-icons/blob/main/flags/4x3/hu.svg
const FlagIconHU = props => (
  <SvgIcon {...props} viewBox="0 0 640 480">
    <g fillRule="evenodd">
      <path fill="#fff" d="M640 480H0V0h640z" />
      <path fill="#388d00" d="M640 480H0V320h640z" />
      <path fill="#d43516" d="M640 160.1H0V.1h640z" />
    </g>
  </SvgIcon>
);

export default FlagIconHU;
