import { useState, useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLoaderData } from 'react-router-dom';

import { gatewayResource, infrastructureElementResource } from '../../../../rest';

import Input from '../../../../components/Input';
import PageSection from '../../../../components/PageSection';
import SelectInput from '../../../../components/SelectInput';
import Notification from '../../../../components/Notification';
import SubmitButton from '../../../../components/SubmitButton';
import InputErrorMessage from '../../../../components/InputErrorMessage';
import Modal from '../../../../components/Modal';
import { toHexString } from '../../../../components/HexSerial';

const GatewayInfo = () => {
  const { formatMessage, locale } = useIntl();
  const loaderData = useLoaderData();

  const mapLocations = locations => {
    const mappedLocations = locations.map(location => ({
      label: location.name,
      value: location.locationId,
    }));

    mappedLocations.unshift({
      label: formatMessage({ id: 'common.placeholder.not-selected' }),
      value: '',
      className: 'placeholder-menu-item',
    });

    return mappedLocations;
  };

  const [gatewayName, setGatewayName] = useState(loaderData.gateway.name || '');
  const [infrastructureElementId, setInfrastructureElementId] = useState(
    loaderData.gateway.infrastructureElement?.infrastructureElementId || ''
  );
  const [locationId, setLocationId] = useState(loaderData.gateway.location?.locationId || '');
  const [locations, setLocations] = useState(mapLocations(loaderData.locations));

  const [notification, setNotification] = useState({});
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [locationError, setLocationError] = useState(false);

  const infrastructureElementMenuItems = useMemo(() => {
    const items = loaderData.infrastructureElements.map(element => ({
      label: element.name,
      value: element.infrastructureElementId,
    }));

    items.unshift({
      label: formatMessage({ id: 'common.placeholder.not-selected' }),
      value: '',
      className: 'placeholder-menu-item',
    });

    return items;
  }, [loaderData.infrastructureElements, formatMessage]);

  useEffect(() => {
    const translatedLocations = [...locations];

    translatedLocations[0].label = formatMessage({ id: 'common.placeholder.not-selected' });

    setLocations(translatedLocations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  const handleGatewayNameChange = event => setGatewayName(event.target.value);

  const handleInfrastructureElementChange = async ({ target: { value } }) => {
    setInfrastructureElementId(value);
    setLocationId('');

    if (!value) {
      return;
    }

    try {
      const newLocations = await infrastructureElementResource.getLocationsForInfrastructureElement(
        value
      );
      setLocations(mapLocations(newLocations));
    } catch (error) {
      setNotification({
        open: true,
        severity: 'error',
        message: formatMessage({
          id: 'gateway.management.info.get-locations-error',
        }),
      });
    }
  };

  const handleLocationChange = event => setLocationId(event.target.value);

  const handleWarningModalClose = () => setIsWarningModalOpen(false);

  const handleUpdate = async () => {
    try {
      await gatewayResource.updateGateway(loaderData.gateway.gatewayId, {
        name: gatewayName,
        locationId: locationId || null,
      });

      const newInfrastructureElement = loaderData.infrastructureElements.find(
        infrastructureElement =>
          infrastructureElement.infrastructureElementId === infrastructureElementId
      );

      loaderData.gateway.infrastructureElement = {
        name: newInfrastructureElement?.name,
        infrastructureElementId: newInfrastructureElement?.infrastructureElementId,
      };

      setNotification({
        open: true,
        severity: 'success',
        message: formatMessage({ id: 'gateway.management.info.edit.success' }),
      });
    } catch (error) {
      setNotification({
        open: true,
        severity: 'error',
        message: formatMessage({
          id: 'gateway.management.info.edit.error',
        }),
      });
    } finally {
      setLocationError(false);
      handleWarningModalClose();
    }
  };

  const handleSubmit = () => {
    if (infrastructureElementId && !locationId) {
      setLocationError(true);
      return;
    }

    const oldInfrastructureElementId =
      loaderData.gateway.infrastructureElement?.infrastructureElementId || '';
    const newInfrastructureElementId = infrastructureElementId;

    if (oldInfrastructureElementId !== newInfrastructureElementId) {
      setIsWarningModalOpen(true);
    } else {
      handleUpdate();
    }
  };

  return (
    <>
      <PageSection>
        <Input
          name="name"
          label={formatMessage({ id: 'gateway.management.info.name' })}
          value={gatewayName}
          onChange={handleGatewayNameChange}
        />
        <Input
          disabled
          name="serial"
          label={formatMessage({ id: 'gateway.management.info.serial-number' })}
          value={toHexString(loaderData.gateway.serial)}
        />
        <SelectInput
          name="infrastructureElement"
          value={infrastructureElementId}
          label={formatMessage({
            id: 'gateway.management.info.infrastructure-element',
          })}
          menuItems={infrastructureElementMenuItems}
          onChange={handleInfrastructureElementChange}
        />
        <SelectInput
          name="location"
          value={locationId}
          label={formatMessage({ id: 'gateway.management.info.location' })}
          menuItems={locations}
          onChange={handleLocationChange}
          disabled={!infrastructureElementId}
          error={locationError}
        />
        {locationError && (
          <InputErrorMessage message={formatMessage({ id: 'common.required-field' })} />
        )}
        <SubmitButton onClick={handleSubmit} />
      </PageSection>

      <Modal
        open={isWarningModalOpen}
        message={formatMessage({ id: 'gateway.management.info.warning' })}
        handleSubmit={handleUpdate}
        handleClose={handleWarningModalClose}
      />

      <Notification
        open={notification.open}
        severity={notification.severity}
        message={notification.message}
        onClose={() => setNotification(prev => ({ ...prev, open: false }))}
      />
    </>
  );
};

export const gatewayInfoLoader = async ({ params: { id } }) => {
  try {
    const gateway = await gatewayResource.getGatewayById(id);
    const infrastructureElements = await infrastructureElementResource.getInfrastructureElements();
    let locations = [];

    if (gateway?.infrastructureElement) {
      locations = await infrastructureElementResource.getLocationsForInfrastructureElement(
        gateway.infrastructureElement.infrastructureElementId
      );
    }

    return { gateway, infrastructureElements, locations };
  } catch (error) {
    throw Error('Failed to get gateway info!');
  }
};

export default GatewayInfo;
