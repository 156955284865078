export const baseOptions = {
  responsive: false,
  animation: false,
  maintainAspectRatio: false,
  normalized: true,
  interaction: {
    mode: 'index',
  },
};

export const axisTitleOptions = {
  display: true,
  font: {
    size: 20,
    weight: '500',
  },
};

export const tickOptions = {
  minRotation: 45,
  maxRotation: 45,
};

export const tooltipOptions = {
  bodySpacing: 4,
  boxPadding: 4,
};
