import { useMemo, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from '@mui/material';
import { ArrowForwardIos as AccordionIcon, Download as DownloadIcon } from '@mui/icons-material';

import { edaResource } from '../../../rest';

import PageContainer from '../../../components/PageContainer';
import PageHeader from '../../../components/PageHeader';

import FFTGraph from '../../../components/EDAGraphs/FFTGraph';
import SpectrogramGraph from '../../../components/EDAGraphs/SpectrogramGraph';
import TimeSeriesGraph from '../../../components/EDAGraphs/TimeSeriesGraph';

import { useSensor } from '../../../context/SensorContext';

import { formatDateOptions } from '../formatDateOptions';
import DeviceName from '../../Device/DeviceName';

import './style.scss';

const methodGraphComponents = {
  fft: FFTGraph,
  spectrogram: SpectrogramGraph,
  time_series: TimeSeriesGraph,
};

const EDAView = () => {
  const { formatMessage, formatDate, locale } = useIntl();
  const { sensors } = useSensor();
  const loaderData = useLoaderData();

  const [eda] = useState(loaderData);

  const [graphs, setGraphs] = useState(
    eda.results.map(result => ({
      open: false,
      type: result.requestMethod.toLowerCase(),
      sensor: result.requestSensor.sensor,
      data: result,
    }))
  );

  const handleDownloadGraphCsv = (event, graph) => {
    event.preventDefault();
    event.stopPropagation();

    window.open(
      `/api/measurement/${eda.measurement.measurementId}/download?sensor=${graph.sensor}`,
      '_blank',
      'noopener noreferrer'
    );
  };

  const handleDownloadFFTCsv = (event, graph) => {
    event.preventDefault();
    event.stopPropagation();

    window.open(
      `/api/eda/${eda.id}/fft/download?sensor=${graph.sensor}`,
      '_blank',
      'noopener noreferrer'
    );
  };

  const toggleGraph = index => {
    setGraphs(prev => {
      const newGraphs = [...prev];
      newGraphs[index] = { ...prev[index], open: !prev[index].open };
      return newGraphs;
    });
  };

  const renderGraph = graph => {
    const Graph = methodGraphComponents[graph.type];
    const sensor = sensors.find(s => s.name === graph.data.requestSensor.sensor);

    return Graph ? (
      <Graph measurement={eda.measurement} sensor={sensor} data={graph.data.result} />
    ) : null;
  };

  const edaMetadata = useMemo(() => {
    const metadata = [
      {
        value: eda.measurement.device?.location?.infrastructureElement?.name,
        label: formatMessage({ id: 'eda.view.infrastructure-element' }),
      },
      {
        value: (
          <DeviceName
            name={eda.measurement.device.name}
            serialNumber={eda.measurement.device.serialNumber}
            formatted
          />
        ),
        label: formatMessage({ id: 'eda.view.device' }),
      },
      {
        value: eda.measurement.name,
        label: formatMessage({ id: 'eda.view.measurement' }),
      },
    ];

    return metadata.filter(data => data.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eda, locale]);

  return (
    <PageContainer className="eda-view-page">
      <PageHeader
        title={formatMessage(
          { id: 'eda.view.title' },
          { creationTime: formatDate(eda.creationTime, formatDateOptions) }
        )}
      >
        <Box className="page-header-subtitles">
          {edaMetadata.map((data, index) => (
            <Typography className="page-title" variant="h6" key={index}>
              {data.label}: {data.value}
            </Typography>
          ))}
        </Box>
      </PageHeader>
      {graphs.map((graph, index) => (
        <Accordion key={index} expanded={graphs[index].open} onChange={() => toggleGraph(index)}>
          <AccordionSummary className="graph-title" expandIcon={<AccordionIcon />}>
            {graph.type === 'time_series' && (
              <Button
                className="download-sensor-csv"
                variant="contained"
                type="button"
                startIcon={<DownloadIcon />}
                onClick={event => handleDownloadGraphCsv(event, graph)}
              />
            )}
            {graph.type === 'fft' && (
              <Button
                className="download-sensor-csv"
                variant="contained"
                type="button"
                startIcon={<DownloadIcon />}
                onClick={event => handleDownloadFFTCsv(event, graph)}
              />
            )}
            {formatMessage({ id: `eda.view.graph.${graph.type}.title` }, { sensor: graph.sensor })}
          </AccordionSummary>
          <AccordionDetails>{renderGraph(graph)}</AccordionDetails>
        </Accordion>
      ))}
    </PageContainer>
  );
};

export const edaLoader = async ({ params: { id } }) => {
  try {
    return await edaResource.getEDAById(id); // TODO: get advanced device info
  } catch (error) {
    throw Error(`Failed to get EDA (id: ${id})!`);
  }
};

export default EDAView;
