import LocaleProvider from '../../context/LocaleContext';
import LoadingProvider from '../../context/LoadingContext';
import AppRouter from '../AppRouter';

import './style.scss';

const App = () => (
  <LoadingProvider>
    <LocaleProvider>
      <AppRouter />
    </LocaleProvider>
  </LoadingProvider>
);

export default App;
