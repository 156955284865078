import { useIntl } from 'react-intl';

import { toHexString } from '../../components/HexSerial';

const DeviceName = ({ name, serialNumber, formatted }) => {
  const { formatMessage } = useIntl();

  if (name && serialNumber) {
    return formatMessage(
      { id: 'data-store.list.named-device' },
      {
        deviceName: name,
        deviceSerialNumber: toHexString(serialNumber),
      }
    );
  }

  if (name) {
    return name;
  }

  if (serialNumber) {
    return formatted
      ? formatMessage(
          { id: 'data-store.list.unnamed-device-with-serial-formatted' },
          {
            deviceSerialNumber: toHexString(serialNumber),
            // eslint-disable-next-line react/no-unstable-nested-components
            i: (...chunks) => <i>{chunks}</i>,
          }
        )
      : formatMessage(
          { id: 'data-store.list.unnamed-device-with-serial' },
          {
            deviceSerialNumber: toHexString(serialNumber),
          }
        );
  }

  return formatted
    ? formatMessage(
        { id: 'data-store.list.unnamed-device-formatted' },
        {
          // eslint-disable-next-line react/no-unstable-nested-components
          i: (...chunks) => <i>{chunks}</i>,
        }
      )
    : formatMessage({ id: 'data-store.list.unnamed-device' });
};

export default DeviceName;
