import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Form, useActionData, useRouteLoaderData } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import classnames from 'classnames';
import log from 'loglevel';

import Input from '../../../../components/Input';
import PageSection from '../../../../components/PageSection';
import Notification from '../../../../components/Notification';
import SubmitButton from '../../../../components/SubmitButton';
import InputErrorMessage from '../../../../components/InputErrorMessage';

import { useDeviceStatus } from '../DeviceStatusContext';
import { deviceResource } from '../../../../rest';

import './style.scss';

const LOGGER = log.getLogger('DeviceScheduling');

const DeviceScheduling = () => {
  const device = useRouteLoaderData('device-root');
  const { locale, formatMessage } = useIntl();

  const actionData = useActionData();
  const deviceBaseData = useRouteLoaderData('device-root');
  const { isOffline, scheduledState, addSubscription, removeSubscription } = useDeviceStatus();

  const [schedulingParams, setSchedulingParams] = useState(deviceBaseData.schedulingParams);
  const [notification, setNotification] = useState({});

  const handleUpdateSchedulingParam = ({ target: { name, value } }) => {
    setSchedulingParams(prev => ({ ...prev, [name]: value }));
  };

  const handleStartSchedule = async () => {
    try {
      await deviceResource.startScheduling(deviceBaseData.deviceId);
    } catch (error) {
      LOGGER.error('Failed to start schedule!', error);
      setNotification({
        open: true,
        severity: 'error',
        message: formatMessage({ id: 'device.management.scheduling.state.start.error' }),
        onClose: () => setNotification(prev => ({ ...prev, open: false })),
      });
    }
  };

  const handleStopSchedule = async stateAfter => {
    try {
      await deviceResource.stopScheduling(deviceBaseData.deviceId, stateAfter);
    } catch (error) {
      LOGGER.error('Failed to stop schedule!', error);
      setNotification({
        open: true,
        severity: 'error',
        message: formatMessage({ id: 'device.management.scheduling.state.stop.error' }),
        onClose: () => setNotification(prev => ({ ...prev, open: false })),
      });
    }
  };

  useEffect(() => {
    addSubscription(`/topic/${device.deviceId}/scheduling`);

    return () => {
      removeSubscription(`/topic/${device.deviceId}/scheduling`);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (actionData) {
      let severity;
      let message;

      if (actionData.updateSchedulingTimesAction?.ok) {
        severity = 'success';
        message = formatMessage({ id: 'device.management.scheduling.update.success' });
      } else if (actionData.updateSchedulingTimesAction?.error?.server) {
        message = formatMessage({ id: 'device.management.scheduling.update.error' });
        severity = 'error';
      } else {
        return;
      }

      setNotification({
        open: true,
        severity,
        message,
        onClose: () => {
          setNotification(prev => ({ ...prev, open: false }));
          // Need to delete, because if it set, the notification will display in case of language change
          delete actionData.updateSchedulingTimesAction;
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionData, locale]);

  return (
    <>
      <PageSection title={formatMessage({ id: 'device.management.scheduling.title' })}>
        <Form method="put" action={`/device-edit/${deviceBaseData.deviceId}/scheduling`} noValidate>
          <Input
            type="number"
            min="0"
            max="1800" // 30 minutes
            name="measurementDuration"
            label={formatMessage({ id: 'device.management.scheduling.measurement-duration' })}
            value={schedulingParams.measurementDuration}
            onChange={handleUpdateSchedulingParam}
            error={actionData?.updateDeviceForm?.error?.measurementDuration?.required}
            disabled={isOffline}
          />
          {actionData?.updateSchedulingTimesForm?.error?.measurementDuration?.required && (
            <InputErrorMessage message={formatMessage({ id: 'common.required-field' })} />
          )}
          <Input
            type="number"
            min="0"
            max="1800" // 30 minutes
            name="awakeDuration"
            label={formatMessage({ id: 'device.management.scheduling.awake-duration' })}
            value={schedulingParams.awakeDuration}
            onChange={handleUpdateSchedulingParam}
            error={actionData?.updateDeviceForm?.error?.awakeDuration?.required}
            disabled={isOffline}
          />
          {actionData?.updateSchedulingTimesForm?.error?.awakeDuration?.required && (
            <InputErrorMessage message={formatMessage({ id: 'common.required-field' })} />
          )}
          <SubmitButton disabled={isOffline} />
        </Form>
      </PageSection>

      <PageSection title={formatMessage({ id: 'device.management.scheduling.state.title' })}>
        <Box className="scheduling-control-container">
          <div className="scheduling-status">
            <span className={classnames('dot', scheduledState)}></span>
            {formatMessage({ id: `device.management.scheduling.state.${scheduledState}` })}
          </div>

          <Button
            variant="contained"
            onClick={handleStartSchedule}
            disabled={scheduledState !== 'NOT_SCHEDULED' || isOffline}
          >
            {formatMessage({ id: 'device.management.scheduling.state.start.button' })}
          </Button>
          <Button
            variant="contained"
            onClick={() => handleStopSchedule('TO_LISTEN')}
            disabled={scheduledState !== 'SCHEDULED' || isOffline}
          >
            {formatMessage({ id: 'device.management.scheduling.state.stop.button' })}
          </Button>
          <Button
            variant="contained"
            onClick={() => handleStopSchedule('TO_STREAM')}
            disabled={scheduledState !== 'SCHEDULED' || isOffline}
          >
            {formatMessage({ id: 'device.management.scheduling.state.stop.button-to-stream' })}
          </Button>
        </Box>
      </PageSection>

      <Notification
        open={notification.open}
        severity={notification.severity}
        message={notification.message}
        onClose={() => setNotification(prev => ({ ...prev, open: false }))}
      />
    </>
  );
};

export const updateSchedulingTimesAction = async ({ request, params }) => {
  const { id } = params;
  const data = await request.formData();

  const measurementDuration = data.get('measurementDuration');
  const awakeDuration = data.get('awakeDuration');

  if (!measurementDuration || !awakeDuration) {
    return {
      updateSchedulingTimesForm: {
        error: {
          measurementDuration: {
            required: !measurementDuration,
          },
          awakeDuration: {
            required: !awakeDuration,
          },
        },
      },
    };
  }

  const submission = {
    measurementDuration,
    awakeDuration,
  };

  try {
    await deviceResource.updateSchedulingTimes(id, submission);

    return { updateSchedulingTimesAction: { ok: true } };
  } catch (error) {
    return {
      updateSchedulingTimesAction: {
        error: {
          server: true,
        },
      },
    };
  }
};

export default DeviceScheduling;
