import { api } from '../rest';

const listGateways = async () => {
  const { data } = await api.get('/gateway');
  return data;
};

const getGatewayById = async id => {
  const { data } = await api.get(`/gateway/${id}`);
  return data;
};

const getDevicesByGateway = async id => {
  const { data } = await api.get(`/gateway/${id}/devices`);
  return data;
};

const updateGateway = (id, gateway) => api.put(`/gateway/${id}`, gateway);

const setGatewaySensorSamplingRate = (gatewayId, samplingRate) =>
  api.put(`/gateway/${gatewayId}/sampling-rate`, samplingRate);

export default {
  listGateways,
  getGatewayById,
  getDevicesByGateway,
  updateGateway,
  setGatewaySensorSamplingRate,
};
