import { useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  Settings as SettingsIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Refresh as RefreshIcon,
} from '@mui/icons-material';

import { elementTypeResource } from '../../../rest';

import Modal from '../../../components/Modal';
import Notification from '../../../components/Notification';
import PageContainer from '../../../components/PageContainer';
import PageHeader from '../../../components/PageHeader';
import Table from '../../../components/Table';

const headCells = [
  {
    id: 'name',
    translationKey: 'element-type.list.name',
    sorting: true,
  },
];

const mapElementTypesToTableRows = elementTypes =>
  elementTypes.map(elementType => ({
    id: elementType.elementTypeId,
    name: elementType.name,
  }));

const ElementTypeList = () => {
  const loaderData = useLoaderData();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const [elementTypes, setElementTypes] = useState(mapElementTypesToTableRows(loaderData));
  const [filteredElementTypes, setFilteredElementTypes] = useState(elementTypes);
  const [searchValue, setSearchValue] = useState('');
  const [notification, setNotification] = useState({});

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedElementTypeId, setSelectedElementTypeId] = useState(null);

  const handleSearchValueChange = event => setSearchValue(event.target.value);

  const handleSearch = () => {
    const searchTerm = searchValue.toLowerCase();
    const filteredTypes = elementTypes.filter(elementType =>
      elementType.name.toLowerCase().includes(searchTerm)
    );

    setFilteredElementTypes(filteredTypes);
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleRefresh = async () => {
    try {
      const fetchedElementTypes = await elementTypeResource.getElementTypes();
      const mappedElementTypes = mapElementTypesToTableRows(fetchedElementTypes);

      setSearchValue('');
      setElementTypes(mappedElementTypes);
      setFilteredElementTypes(mappedElementTypes);

      setNotification({
        open: true,
        severity: 'success',
        message: formatMessage({ id: 'element-type.list.refresh.success' }),
      });
    } catch (error) {
      setNotification({
        open: true,
        severity: 'error',
        message: formatMessage({ id: 'element-type.list.refresh.error' }),
      });
    }
  };

  const handleDeleteClicked = ({ id }) => {
    setIsDeleteModalOpen(true);
    setSelectedElementTypeId(id);
  };

  const handleDeleteModalClose = () => {
    setSelectedElementTypeId(null);
    setIsDeleteModalOpen(false);
  };

  const handleDeleteModalSubmit = async () => {
    try {
      await elementTypeResource.deleteElementType(selectedElementTypeId);
      const updatedElementTypes = elementTypes.filter(
        elementType => elementType.id !== selectedElementTypeId
      );
      const updatedFilteredElementTypes = filteredElementTypes.filter(
        elementType => elementType.id !== selectedElementTypeId
      );

      setElementTypes(updatedElementTypes);
      setFilteredElementTypes(updatedFilteredElementTypes);

      setNotification({
        open: true,
        severity: 'success',
        message: formatMessage({ id: 'element-type.list.delete.success' }),
      });
    } catch (error) {
      setNotification({
        open: true,
        severity: 'error',
        message: formatMessage({
          id:
            error.response.status === 409
              ? 'element-type.list.delete.error.conflict'
              : 'element-type.list.delete.error.internal-server-error',
        }),
      });
    } finally {
      handleDeleteModalClose();
    }
  };

  const handleGoToCreatePage = () => navigate(`/element-type/create`);

  const handleGoToEditPage = ({ id }) => navigate(`/element-type/edit/${id}`);

  const tableRowActions = [
    {
      icon: <SettingsIcon />,
      onClick: handleGoToEditPage,
      label: formatMessage({ id: 'common.button.settings' }),
    },
    {
      icon: <DeleteIcon />,
      onClick: handleDeleteClicked,
      label: formatMessage({ id: 'common.button.delete' }),
    },
  ];

  const headerActions = [
    {
      isIconButton: true,
      icon: <RefreshIcon />,
      label: formatMessage({ id: 'common.button.refresh' }),
      onClick: handleRefresh,
    },
    {
      icon: <AddIcon />,
      label: formatMessage({ id: 'common.button.create' }),
      onClick: handleGoToCreatePage,
    },
  ];

  const searchBar = {
    inputProps: {
      name: 'element-type-search',
      label: formatMessage({ id: 'common.button.search' }),
      value: searchValue,
      onChange: handleSearchValueChange,
      onKeyDown: handleKeyPress,
    },
    adornmentProps: {
      onClick: handleSearch,
    },
  };

  return (
    <>
      <PageContainer>
        <PageHeader
          title={formatMessage({ id: 'element-type.list.title' })}
          searchBar={searchBar}
          actions={headerActions}
        />

        <Table
          rows={filteredElementTypes}
          headCells={headCells}
          actions={tableRowActions}
          noResultMessage={formatMessage({
            id: `element-type.list.${elementTypes.length ? 'no-result' : 'empty'}`,
          })}
        />
      </PageContainer>

      <Modal
        open={isDeleteModalOpen}
        message={formatMessage({ id: 'element-type.list.delete.title' })}
        handleSubmit={handleDeleteModalSubmit}
        handleClose={handleDeleteModalClose}
      />
      <Notification
        open={notification.open}
        severity={notification.severity}
        message={notification.message}
        onClose={() => setNotification(prev => ({ ...prev, open: false }))}
      />
    </>
  );
};

export const elementTypesLoader = async () => {
  try {
    const elementTypes = await elementTypeResource.getElementTypes();
    return elementTypes;
  } catch (error) {
    throw Error('Failed to get element types!');
  }
};

export default ElementTypeList;
