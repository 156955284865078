import { useState, useRef, useEffect, useCallback } from 'react';

const ZoomOverlay = ({ chart, top, left, height, onZoom, onResetZoom }) => {
  const [drag, setDrag] = useState(null);
  const overlay = useRef();

  const handleMouseDown = event => {
    setDrag({
      start: event.clientX,
      offset: overlay.current.getBoundingClientRect().x,
    });
  };

  const handleMouseUp = useCallback(() => {
    const { start, current, offset } = drag || {};
    setDrag(null);

    if (current != null) {
      const lowerBound = start < current ? start : current;
      const upperBound = start < current ? current : start;
      const range = upperBound - lowerBound;
      if (range < 1) {
        return;
      }

      const bounds = overlay.current.getBoundingClientRect();
      // Convert zoom values to 0..1 range make calculations easier on parent component
      onZoom((lowerBound - offset) / bounds.width, (upperBound - offset) / bounds.width);
    }
  }, [overlay, drag, setDrag, onZoom]);

  const handleDoubleClick = () => {
    setDrag(null);
    onResetZoom();
  };

  const propagateToChart = ({ nativeEvent: original, clientX: current }) => {
    if (drag) {
      setDrag(prev => ({ ...prev, current }));
      return;
    }

    // Absolutely positioned elements capture all events from underlying elements, so we need to
    // manually propagate them to the chart's canvas for the tooltip overlays to work
    const copied = new MouseEvent(original.type, original);
    chart?.canvas?.dispatchEvent(copied);
  };

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseUp]);

  const values = drag
    ? {
        lowerBound: drag.start < drag.current ? drag.start : drag.current,
        upperBound: drag.start < drag.current ? drag.current : drag.start,
      }
    : null;

  const indicator = drag
    ? {
        visibility: values.upperBound - values.lowerBound > 1 ? 'visible' : 'hidden',
        left: values.lowerBound - drag.offset,
        width: values.upperBound - values.lowerBound - 1,
      }
    : {
        visibility: 'hidden',
        left: 0,
        width: 0,
      };

  return (
    <div
      ref={overlay}
      className="overlay"
      onDoubleClick={handleDoubleClick}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={propagateToChart}
      style={{
        top: `${top || 0}px`,
        left: `${left || 0}px`,
        height: `${height || 0}px`,
      }}
    >
      <div
        className="indicator"
        style={{
          visibility: indicator.visibility,
          left: `${indicator.left}px`,
          width: `${indicator.width}px`,
        }}
      ></div>
    </div>
  );
};

ZoomOverlay.defaultProps = {
  top: 0,
  left: 0,
  width: 0,
  height: 0,
};

export default ZoomOverlay;
