import { FormattedMessage, useIntl } from 'react-intl';

import Modal from '../../../components/Modal';

import './style.scss';

const CalibrationResultModal = ({ calibratedValues, onClose }) => {
  const { formatMessage } = useIntl();

  return (
    <Modal
      open
      title={formatMessage({ id: 'data-store.list.calibration-result.title' })}
      submitLabelKey="common.button.ok"
      className="calibration-modal"
      noCancelButton
      handleSubmit={onClose}
      handleClose={onClose}
    >
      <div className="type">
        <FormattedMessage id="data-store.list.calibration-result.transposition" />
      </div>
      <div className="transposition-values">
        {Object.entries(calibratedValues.sensorTranspositions).map(([sensor, sensorFields]) => (
          <div key={sensor} className="sensor-transposition">
            <div className="sensor-type">{sensor}</div>
            {Object.entries(sensorFields).map(([field, value]) => (
              <div key={`${sensor}_${field}`} className="field">
                {field}: <span className="value">{parseFloat(value).toFixed(2)}</span>
              </div>
            ))}
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default CalibrationResultModal;
