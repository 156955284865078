import { useState, useEffect } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Visibility as EyeIcon, Refresh as RefreshIcon } from '@mui/icons-material';

import { edaResource } from '../../../rest';

import PageContainer from '../../../components/PageContainer';
import PageHeader from '../../../components/PageHeader';
import Table from '../../../components/Table';
import Notification from '../../../components/Notification';

import { formatDateOptions } from '../formatDateOptions';
import DeviceName from '../../Device/DeviceName';

const headCells = [
  {
    id: 'measurement',
    translationKey: 'eda.list.measurement',
    sorting: true,
  },
  {
    id: 'displayedDeviceName',
    translationKey: 'eda.list.device',
    sorting: true,
  },
  {
    id: 'infrastructureElement',
    translationKey: 'eda.list.infrastructure-element',
    sorting: true,
  },
  {
    id: 'creationTime',
    translationKey: 'eda.list.creationTime',
    sorting: true,
  },
  {
    id: 'startTime',
    translationKey: 'eda.list.startTime',
    sorting: true,
  },
  {
    id: 'stopTime',
    translationKey: 'eda.list.stopTime',
    sorting: true,
  },
];

const EDAList = () => {
  const { formatMessage, formatDate, locale } = useIntl();
  const loaderData = useLoaderData();
  const navigate = useNavigate();

  const _mapEDAsToTableRows = edas =>
    edas.results.map(eda => ({
      id: eda.id,
      creationTimeRaw: eda.creationTime,
      startTimeRaw: eda.startTime,
      stopTimeRaw: eda.stopTime,
      creationTime: formatDate(eda.creationTime, formatDateOptions),
      startTime: formatDate(eda.startTime, formatDateOptions),
      stopTime: formatDate(eda.stopTime, formatDateOptions),
      measurement: eda.measurement.name,
      device: eda.measurement.device.name,
      displayedDeviceName: (
        <DeviceName
          name={eda.measurement.device.name}
          serialNumber={eda.measurement.device.serialNumber}
          formatted
        />
      ),
      infrastructureElement: eda.measurement.device?.location?.infrastructureElement?.name,
    }));

  const [edas, setEDAs] = useState(_mapEDAsToTableRows(loaderData));
  const [notification, setNotification] = useState({});

  useEffect(() => {
    const translatedEDAs = edas.map(eda => ({
      ...eda,
      creationTime: formatDate(eda.creationTimeRaw, formatDateOptions),
      startTime: formatDate(eda.startTimeRaw, formatDateOptions),
      stopTime: formatDate(eda.stopTimeRaw, formatDateOptions),
    }));

    setEDAs(translatedEDAs);
  }, [locale]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleGoToViewPage = ({ id }) => navigate(`/eda/${id}/view`);

  const tableRowActions = [
    {
      icon: <EyeIcon />,
      onClick: handleGoToViewPage,
      label: formatMessage({ id: 'common.button.view' }),
    },
  ];

  const handleRefresh = async () => {
    try {
      const fetchedEDAs = await edaResource.listEDAs();

      const mappedEDAs = _mapEDAsToTableRows(fetchedEDAs);
      setEDAs(mappedEDAs);

      setNotification({
        open: true,
        severity: 'success',
        message: formatMessage({ id: 'eda.list.refresh.success' }),
      });
    } catch (error) {
      setNotification({
        open: true,
        severity: 'error',
        message: formatMessage({ id: 'eda.list.refresh.error' }),
      });
    }
  };

  const headerActions = [
    {
      isIconButton: true,
      icon: <RefreshIcon />,
      label: formatMessage({ id: 'common.button.refresh' }),
      onClick: handleRefresh,
    },
  ];

  return (
    <>
      <PageContainer>
        <PageHeader title={formatMessage({ id: 'eda.list.title' })} actions={headerActions} />
        <Table
          rows={edas}
          headCells={headCells}
          actions={tableRowActions}
          defaultSort={{ orderBy: 'creationTime', order: 'desc' }}
          noResultMessage={formatMessage({ id: 'eda.list.empty' })}
        />
      </PageContainer>
      <Notification
        open={notification.open}
        severity={notification.severity}
        message={notification.message}
        onClose={() => setNotification(prev => ({ ...prev, open: false }))}
      />
    </>
  );
};

export const edaLoader = async () => {
  try {
    return await edaResource.listEDAs();
  } catch (error) {
    throw Error('Failed to load EDAs!');
  }
};

export default EDAList;
