import { useState, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { axisTitleOptions, baseOptions, tickOptions, tooltipOptions } from './chart.options';
import LineGraphCommon from './LineGraphCommon';

import './style.scss';

const colors = {
  temperature: 'rgb(0, 143, 251)',
  pressure: 'rgb(0, 143, 251)',
  x: 'rgb(0, 143, 251)',
  y: 'rgb(0, 227, 150)',
  z: 'rgb(254, 176, 25)',
};

const millisecTimedSensors = ['IIS2DH', 'IIS3DWB'];
const multidimensionalSensors = ['IIS2DH', 'IIS3DWB', 'IIS2MDC'];

const TimeSeriesGraph = ({ sensor, data: timeSeriesData }) => {
  const { formatMessage, locale } = useIntl();

  const defaultRange = useMemo(() => {
    const firstSeries = Object.values(timeSeriesData.series)[0];
    const start = new Date(firstSeries.at(0).timestamp);
    const end = new Date(firstSeries.at(-1).timestamp);

    return { min: start.getTime(), max: end.getTime() };
  }, [timeSeriesData]);

  const [range, setRange] = useState(defaultRange);

  const { timeLabel, secondsLabel, measurementTypeLabel, measurementUnitLabel } = useMemo(
    () => ({
      timeLabel: formatMessage({ id: 'eda.view.graph.time_series.time' }),
      secondsLabel: formatMessage({ id: 'eda.view.graph.unit.seconds' }),
      measurementTypeLabel: formatMessage({ id: `eda.view.graph.type.${sensor.measurementType}` }),
      measurementUnitLabel: formatMessage({ id: `eda.view.graph.unit.${sensor.measurementUnit}` }),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formatMessage, locale, sensor]
  );

  const labelFormatter = useCallback(
    context => {
      let field = context.dataset.label || '';
      const value = context.parsed.y;

      if (!multidimensionalSensors.includes(sensor.name)) {
        field = measurementTypeLabel;
      }

      return `${field}: ${value} ${measurementUnitLabel}`;
    },
    [measurementTypeLabel, measurementUnitLabel, sensor]
  );

  const fieldLabels = Object.keys(timeSeriesData.series).reduce((acc, key) => {
    const fieldName = key.split('.')[1];
    if (multidimensionalSensors.includes(sensor.name)) {
      acc[key] = fieldName.slice(4); // slice 'acc_' and 'mag_' prefixes
    } else {
      acc[key] = fieldName;
    }
    return acc;
  }, {});

  const fieldColors = Object.keys(timeSeriesData.series).reduce((acc, key) => {
    const fieldName = key.split('.')[1];
    if (multidimensionalSensors.includes(sensor.name)) {
      acc[key] = colors[fieldName.slice(4)]; // slice 'acc_' and 'mag_' prefixes
    } else {
      acc[key] = colors[fieldName];
    }
    return acc;
  }, {});

  const data = useMemo(
    () => ({
      labels: Object.values(timeSeriesData.series)[0].map(point => new Date(point.timestamp)),
      datasets: Object.entries(timeSeriesData.series).map(([field, series]) => ({
        label: fieldLabels[field],
        data: series.map(point => point.value.toFixed(2)),
        borderWidth: 2.5,
        borderColor: fieldColors[field],
        backgroundColor: fieldColors[field],
        pointRadius: 0,
        pointHitRadius: 16,
        spanGaps: true,
      })),
    }),
    [timeSeriesData, fieldLabels, fieldColors]
  );

  const options = useMemo(
    () => ({
      ...baseOptions,
      scales: {
        x: {
          title: {
            ...axisTitleOptions,
            text: `${timeLabel} (${secondsLabel})`,
          },
          type: 'time',
          time: {
            unit: 'second',
            tooltipFormat: millisecTimedSensors.includes(sensor.name) ? 'HH:mm:ss.SSS' : 'HH:mm:ss',
            displayFormats: {
              second: 'HH:mm:ss',
            },
          },
          ticks: {
            ...tickOptions,
          },
          min: range.min,
          max: range.max,
        },
        y: {
          title: {
            ...axisTitleOptions,
            text: `${measurementTypeLabel} (${measurementUnitLabel})`,
          },
        },
      },
      plugins: {
        legend: {
          display: multidimensionalSensors.includes(sensor.name),
        },
        tooltip: {
          ...tooltipOptions,
          callbacks: {
            label: labelFormatter,
          },
        },
      },
    }),
    [
      labelFormatter,
      timeLabel,
      secondsLabel,
      measurementTypeLabel,
      measurementUnitLabel,
      sensor,
      range,
    ]
  );

  const handleUpdateRange = newRange => {
    setRange(newRange);
  };

  const handleResetRange = () => {
    setRange(defaultRange);
  };

  return (
    <LineGraphCommon
      className="time-series-graph"
      data={data}
      options={options}
      onUpdateRange={handleUpdateRange}
      onResetRange={handleResetRange}
    />
  );
};

export default TimeSeriesGraph;
